@import url('https://fonts.cdnfonts.com/css/avenir');


:root {
  /* ----------------------------------
   @font family declaration
  ------------------------------------ */
  --tj-ff-body: 'Avenir', sans-serif;
  --tj-ff-heading: 'Avenir', sans-serif;
  --tj-ff-fontawesome: "Font Awesome 6 Pro";
  /* ----------------------------------
   @color declaration
  ------------------------------------ */
  --tj-common-white: #ffffff;
  --tj-common-black: #000000;
  --tj-common-black-2: #24292d;
  --tj-heading-primary: #102039;
  --tj-heading-secondary: #93C94F;
  --tj-heading-secondary_2: #57667d;
  --tj-grey-1: #c5c5c5;
  --tj-grey-2: rgba(221, 221, 221, 0.58);
  --tj-grey-3: rgba(248, 248, 248, 0.1);
  --tj-grey-4: rgba(219, 219, 219, 0.4);
  --tj-grey-5: #c1c1c1;
  --tj-grey-6: #ebebeb;
  --tj-grey-7: #fafafa;
  --tj-grey-8: #f8f8f8;
  --tj-grey-9: #bbbbbb;
  --tj-text-body: #000;
  --tj-text-1: #000;
  --tj-text-2: #070707;
  --tj-text-3: #333333;
  --tj-text-4: #d0d0d0;
  --tj-theme-primary: #93C94F;
  --tj-theme-secondary: #102039;
  --tj-theme-1: #de2021;
  --tj-theme-2: #fff1f1;
  --tj-theme-3: rgba(16, 32, 57, 0.7333333333);
  --tj-theme-4: rgba(16, 32, 57, 0.4509803922);
  --tj-theme-5: #eb5758;
  --tj-theme-6: #233152;
  --tj-theme-7: #ffd700;
  --tj-theme-8: #2e3745;
  --tj-theme-9: #f8f8f8;
  --tj-theme-10: #0e1013;
  --tj-border-1: #ededed;
  --tj-border-2: #e9e9e9;
  --tj-border-3: #999999;
  --tj-border-4: #e6e6e6;
  --tj-border-5: #535353;
  --tj-border-6: #888888;
  --tj-border-7: rgba(221, 221, 221, 0.15);
  --tj-border-8: #e7e7e7;
  --tj-border-9: rgba(221, 221, 221, 0.45);
  --tj-border-10: #c8c8c8;
  --tj-border-11: rgba(169, 169, 169, 0.1);
  --tj-border-12: rgba(217, 217, 217, 0.5);
  --tj-border-13: #dddddd;
  --tj-border-14: #d9d9d9;
  --tj-border-15: rgba(219, 219, 219, 0.25);
  --tj-border-16: rgba(234, 24, 38, 0.55);
  --tj-shadow-1: rgba(0, 0, 0, 0.1);
  --tj-shadow-2: rgba(100, 100, 111, 0.2);
  --tj-shadow-3: rgba(35, 35, 35, 0.15);
  --tj-shadow-4: rgba(0, 0, 0, 0.5);
  --tj-shadow-5: rgba(0, 0, 0, 0.1);
  --tj-shadow-6: rgba(16, 32, 57, 0.15);
  --tj-shadow-7: rgba(234, 24, 38, 0.1);
  --tj-placeholder-1: #b1b1b1;
  /* ----------------------------------
   @font weight declaration
  ------------------------------------ */
  --tj-fw-normal: normal;
  --tj-fw-elight: 200;
  --tj-fw-light: 300;
  --tj-fw-regular: 400;
  --tj-fw-medium: 500;
  --tj-fw-sbold: 400;
  --tj-fw-bold: 400;
  --tj-fw-ebold: 800;
  --tj-fw-blacks: 900;
  /* ----------------------------------
    @font size declaration
  ------------------------------------ */
  --tj-fz-body: 16px;
  --tj-fz-p: 18px;
  --tj-fz-h1: 75px;
  --tj-fz-h2: 45px;
  --tj-fz-h3: 30px;
  --tj-fz-h4: 24px;
  --tj-fz-h5: 22px;
  --tj-fz-h6: 18px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

/*---------------------------------
    typography css start 
---------------------------------*/
body {
  font-family: var(--tj-ff-body);
  font-size: var(--tj-fz-body);
  font-weight: var(--tj-fw-normal);
  color: var(--tj-text-body);
  line-height: 1.5;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--tj-ff-heading);
  color: var(--tj-heading-primary);
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 15px;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
  line-height: inherit;
}

h1:hover a,
h2:hover a,
h3:hover a,
h4:hover a,
h5:hover a,
h6:hover a {
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
}

h1 {
  font-size: var(--tj-fz-h1);
  line-height: 1.3;
}

h2 {
  font-size: var(--tj-fz-h2);
}

h3 {
  font-size: var(--tj-fz-h3);
}

h4 {
  font-size: var(--tj-fz-h4);
}

h5 {
  font-size: var(--tj-fz-h5);
}

h6 {
  font-size: var(--tj-fz-h6);
}

p {
  line-height: 1.5;
  font-family: var(--tj-ff-body);
  font-size: var(--tj-fz-body);
  font-weight: var(--tj-fw-regular);
  color: var(--tj-text-body);
  margin-bottom: 15px;
}

a,
.btn,
button,
span,
p,
i,
input,
select,
textarea,
li,
img,
svg path,
*::after,
*::before,
.transition-3,
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

img {
  max-width: 100%;
}

button {
  border: 0px;
}

a:hover,
a:focus,
a:active,
button:hover,
button:focus,
button:active {
  text-decoration: none;
  outline: none;
  border: 0;
}

ul {
  margin-bottom: 0;
  padding: 0;
  list-style-type: none;
}

ul>li {
  list-style: none;
  display: inline-block;
}

hr {
  margin-top: 0;
  margin-bottom: 0;
}

input:focus {
  outline: none;
}

input::-moz-focus-inner {
  border: 0;
  display: none;
}

.form-control {
  border-radius: 2px;
}

input::-webkit-focus-inner {
  border: 0;
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px var(--tj-common-white) inset !important;
}

::placeholder {
  color: var(--tj-grey-9);
  opacity: 1;
}

:-ms-input-placeholder {
  color: var(--tj-grey-5);
}

::-ms-input-placeholder {
  color: var(--tj-grey-5);
}

table {
  border-collapse: collapse;
}

form {
  position: relative;
}

button {
  background: transparent;
  border: none;
}

.form-control:focus {
  outline: none;
  border-color: var(--tj-shadow-5);
  box-shadow: 0 0 0;
}

#scrollUp {
  background-color: var(--tj-theme-secondary);
  border: 1px solid var(--tj-common-white);
  border-radius: 50%;
  bottom: 20px;
  color: var(--tj-common-white);
  font-size: 22px;
  height: 40px;
  line-height: 40px;
  right: 20px;
  text-align: center;
  width: 40px;
  box-shadow: 0px 5px 15px 0px rgba(1, 29, 80, 0.1);
  font-weight: var(--tj-fw-regular);
  transition: all 0.4s ease-in-out;
}

#scrollUp:hover {
  background-color: var(--tj-theme-primary);
}

@keyframes sticky {
  0% {
    opacity: 0;
    margin-top: -120px;
  }

  50% {
    opacity: 0;
    margin-top: -60px;
  }

  100% {
    opacity: 1;
    margin-top: 0;
  }
}

.scroll-up-btn a {
  display: inline-block;
  padding: 16px 20px;
  border-radius: 10px;
  line-height: 1;
  background-color: var(--tj-heading-secondary);
}

.scroll-up-btn a:hover {
  color: var(--tj-common-white);
}

.animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}

/* ----------------------------------
	04. spacing css
 ------------------------------------ */
.section-padding {
  padding-top: 70px;
  padding-bottom: 40px;
  position: relative;
}

.section-bottom {
  padding-bottom: 90px;
  position: relative;
}

.section-top {
  padding-top: 120px;
  position: relative;
}

.mx-auto {
  margin: 0 auto;
}

.bg-cover {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: center;
}

.bg-center {
  background-position: center;
}

.bg-contain {
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  background-position: left;
}

.bg-right {
  background-repeat: no-repeat;
  background-position: right;
}

.bg-left {
  background-repeat: no-repeat;
  background-position: left;
}

.bg-bottom {
  background-repeat: no-repeat;
  background-position: bottom;
}

.bg-top {
  background-repeat: no-repeat;
  background-position: top;
}

.bg-100 {
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.fix {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

label.error {
  color: red;
}

/* ----------------------------------
  02. Animation CSS
 ------------------------------------ */
@keyframes left-to-right {
  0% {
    transform: translateX(0px);
  }

  50% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes right-to-left {
  0% {
    transform: translateX(0px);
  }

  50% {
    transform: translateX(-10px);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes playVideo {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
  }

  40% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }

  80% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes pluse-icon {
  0% {
    transform: translateX(0px);
  }

  50% {
    transform: translateX(20px);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes pluse {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes top-bottom {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(6px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes circle {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes playVideo-2 {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
  }

  40% {
    box-shadow: 0 0 0 20px rgba(69, 191, 118, 0.199);
  }

  80% {
    box-shadow: 0 0 0 20px rgba(69, 191, 118, 0.039);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(69, 191, 118, 0.097);
  }
}

@keyframes criss-cross-left {
  0% {
    left: -20px;
  }

  40% {
    left: 50%;
    border-radius: 500px;
  }

  60% {
    left: 50%;
    width: 50%;
    height: 50%;
    border-radius: 500px;
  }

  100% {
    left: 50%;
    width: 100%;
    height: 120%;
    border-radius: 0px;
  }
}

@keyframes criss-cross-right {
  0% {
    right: -20px;
  }

  40% {
    right: 50%;
    border-radius: 500px;
  }

  60% {
    right: 50%;
    width: 50%;
    height: 50%;
    border-radius: 500px;
  }

  100% {
    border-radius: 0px;
    right: 50%;
    width: 100%;
    height: 120%;
  }
}

@keyframes bounce {
  0% {
    transform: scale(1, 1);
  }

  60% {
    transform: scale(1.3, 1.3);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes sticky {
  0% {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

/*------------------------
 03. Preloader CSS
------------------------*/
#loading {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 99999;
  margin-top: 0;
  top: 0;
}

#loading::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--tj-theme-secondary);
  z-index: -1;
  opacity: 1;
}

#loading #loading-center {
  width: 100%;
  height: 100%;
  position: relative;
}

#loading #loading-center #loading-center-absolute {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 200px;
  width: 200px;
  margin-top: -100px;
  margin-left: -100px;
  transform: rotate(-135deg);
}

#loading #loading-center #loading-center-absolute .object {
  border-radius: 50% 50% 50% 50%;
  position: absolute;
  border-top: 5px solid var(--tj-theme-primary);
  border-bottom: 5px solid transparent;
  border-left: 5px solid var(--tj-theme-primary);
  border-right: 5px solid transparent;
  animation: animate 2s infinite;
}

#loading #loading-center #loading-center-absolute #object_one {
  left: 75px;
  top: 75px;
  width: 50px;
  height: 50px;
}

#loading #loading-center #loading-center-absolute #object_two {
  left: 65px;
  top: 65px;
  width: 70px;
  height: 70px;
  animation-delay: 0.2s;
}

#loading #loading-center #loading-center-absolute #object_three {
  left: 55px;
  top: 55px;
  width: 90px;
  height: 90px;
  animation-delay: 0.4s;
}

#loading #loading-center #loading-center-absolute #object_four {
  left: 45px;
  top: 45px;
  width: 110px;
  height: 110px;
  animation-delay: 0.6s;
}

#loading #loading-center .closeLoader {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 1;
}

@-webkit-keyframes animate {
  50% {
    transform: rotate(360deg) scale(0.8);
  }
}

@keyframes animate {
  50% {
    transform: rotate(360deg) scale(0.8);
  }
}

/* ---------------------------------------
  04. Button CSS
 ----------------------------------------- */
/* ------------------------------------------------------------------
   Template Default Button, Input Styles - Value Change with Your's Need
-------------------------------------------------------------------- */
.btnd,
.video-btn,
.tj-btn-primary {
  line-height: 100%;
  font-weight: var(--tj-fw-sbold);
  font-family: var(--tj-ff-heading);
  display: inline-block;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  text-transform: uppercase;
  font-size: 18px;
}

.btn {
  border: 0;
  background: transparent;
  border-radius: 0;
  line-height: 1;
  padding: 0;
  margin: 0;
  font-size: inherit;
}

.btn:focus {
  box-shadow: none;
}

.tj-btn-primary {
  padding: 17px 30px;
  background: var(--tj-theme-primary);
  text-transform: capitalize;
  color: var(--tj-common-white);
}

.tj-btn-primary.style-1,
.tj-btn-primary.style-5,
.tj-btn-primary.style-3,
.tj-btn-primary.style-2 {
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.tj-btn-primary.style-1::after,
.tj-btn-primary.style-5::after,
.tj-btn-primary.style-3::after,
.tj-btn-primary.style-2::after {
  content: "";
  position: absolute;
  height: 100%;
  left: 60%;
  top: 60%;
  width: 150%;
  transition: all 0.5s ease;
  z-index: -1;
  background: var(--tj-theme-secondary);
  transform: rotate(-25deg);
}

.tj-btn-primary.style-1:hover,
.tj-btn-primary.style-5:hover,
.tj-btn-primary.style-3:hover,
.tj-btn-primary.style-2:hover {
  background-color: transparent;
}

.tj-btn-primary.style-1:hover::after,
.tj-btn-primary.style-5:hover::after,
.tj-btn-primary.style-3:hover::after,
.tj-btn-primary.style-2:hover::after {
  left: -30%;
  top: -70%;
  height: 470%;
}

.tj-btn-primary.style-2 {
  color: var(--tj-theme-primary);
  background-color: var(--tj-common-white);
}

.tj-btn-primary.style-2::after {
  background: var(--tj-theme-secondary);
}

.tj-btn-primary.style-2:hover {
  background-color: transparent;
  color: var(--tj-common-white);
}

.tj-btn-primary.style-3::after {
  background: var(--tj-theme-primary);
}

.tj-btn-primary.style-3:hover {
  background-color: var(--tj-common-white);
}

.tj-btn-primary.style-5 {
  background: var(--tj-theme-secondary);
}

.tj-btn-primary.style-5::after {
  background: var(--tj-theme-primary);
}

.tj-btn-primary.style-5:hover {
  background-color: var(--tj-common-white);
}

.tj-btn-primary i {
  margin-left: 5px;
}

.tj-btn-primary:hover {
  background: var(--tj-theme-secondary);
}

.tj-submit {
  color: var(--tj-common-white);
  text-align: center;
  font-size: 18px;
  font-family: var(--tj-ff-heading);
  font-weight: var(--tj-fw-sbold);
  line-height: 26px;
  background-color: var(--tj-theme-primary);
}

.video-btn i {
  margin-right: 10px;
}

.video-play-btn .play-video {
  border-radius: 50%;
  width: 90px;
  height: 90px;
  background: var(--tj-theme-primary);
  color: var(--tj-common-white);
  line-height: 90px;
  transition: all 0.3s ease-in-out;
  text-align: center;
  animation: playVideo infinite 3.5s;
  display: inline-block;
  margin: 0px auto;
  font-size: 20px;
}

.video-play-btn .play-video:hover {
  color: var(--tj-theme-secondary);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

/*---------------------------------------
    05. Background CSS
-----------------------------------------*/
.tj-primary {
  color: var(--tj-heading-primary);
}

.tj-secondary {
  color: var(--tj-heading-secondary);
}

.tj-secondary-2 {
  color: var(--tj-heading-secondary_2);
}

/* ----------------------------------
	06. Carousel CSS
 ------------------------------------ */
.hero-wrapper {
  position: relative;
  background-color: #000;
}

.hero-wrapper.hero-1 .hero-slider-nav {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
}

.hero-wrapper.hero-1 .hero-slider-nav .swiper-slide {
  width: 100px;
  height: auto;
  display: block;
  margin: 24px 0;
  padding: 0;
  opacity: 1;
  background: transparent;
  position: relative;
  right: -30px;
  transition: all 0.4s ease;
  cursor: pointer;
}

.hero-wrapper.hero-1 .hero-slider-nav .swiper-slide img {
  max-width: 130px;
  height: auto;
  border-radius: 50px 0 0 50px;
}

.hero-wrapper.hero-1 .hero-slider-nav .swiper-slide::after {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: var(--tj-heading-secondary);
  transition: all 0.4s ease;
  border-radius: 50px 0 0 50px;
  opacity: 0;
  z-index: -1;
}

.hero-wrapper.hero-1 .hero-slider-nav .swiper-slide::before {
  position: absolute;
  left: 0;
  top: 0;
  line-height: 70px;
  font-size: 19px;
  content: "\f178";
  color: var(--tj-common-white);
  font-family: var(--tj-ff-fontawesome);
  transition: all 0.5s ease;
  transform: rotate(180deg);
  opacity: 0;
}

.hero-wrapper.hero-1 .hero-slider-nav .swiper-slide:hover,
.hero-wrapper.hero-1 .hero-slider-nav .swiper-slide.active {
  right: 0;
}

.hero-wrapper.hero-1 .hero-slider-nav .swiper-slide:hover::after,
.hero-wrapper.hero-1 .hero-slider-nav .swiper-slide.active::after {
  right: 40px;
  opacity: 1;
}

.hero-wrapper.hero-1 .hero-slider-nav .swiper-slide:hover::before,
.hero-wrapper.hero-1 .hero-slider-nav .swiper-slide.active::before {
  opacity: 1;
  left: -25px;
}

.hero-wrapper.hero-1 .hero-slider-nav.active {
  right: 0;
}

.hero-wrapper.hero-1 .hero-slider-nav.active::after {
  right: 40px;
  opacity: 1;
}

.hero-wrapper.hero-1 .hero-slider-nav.active::before {
  opacity: 1;
  left: -25px;
}

.hero-wrapper.hero-1 .swiper-pagination {
  display: none;
  flex-direction: column;
  gap: 5px;
  position: absolute;
  top: 45%;
  right: 15px;
  transform: translate(-50%);
}

.hero-wrapper.hero-1 .swiper-pagination .swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  background: var(--tj-common-white);
  transform: scale(1);
  border-radius: 300px;
}

.hero-wrapper.hero-1 .swiper-pagination .swiper-pagination-bullet.active {
  transform: scale(1.2);
  background: var(--tj-heading-secondary);
}

.hero-wrapper.hero-4 .owl-nav .owl-prev {
  display: inline;
  position: absolute;
  top: calc(40% + 40px);
  color: var(--tj-common-white);
  left: 5%;
}

.hero-wrapper.hero-4 .owl-nav .owl-prev i {
  font-size: 40px;
  color: var(--tj-common-white);
}

.hero-wrapper.hero-4 .owl-nav .owl-next {
  display: inline;
  position: absolute;
  top: calc(40% + 40px);
  color: var(--tj-common-white);
  right: 5%;
}

.hero-wrapper.hero-4 .owl-nav .owl-next i {
  font-size: 40px;
  color: var(--tj-common-white);
}

.hero-wrapper .hero_slider_thumb {
  position: absolute;
  top: 35%;
  right: 0px;
  display: none;
}

.hero-wrapper .swiper__inner {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 20px;
  top: 30%;
  right: -15px;
  transform: translate(-50%);
}

.hero-wrapper .swiper__inner .swiper-slide {
  display: block;
  padding: 0;
  right: 0px;
  padding-left: 40px;
  width: 150px !important;
  opacity: 1;
  background: transparent;
  position: relative;
  transition: all 0.4s ease;
  cursor: pointer;
}

.hero-wrapper .swiper__inner .swiper-slide img {
  max-width: 130px;
  height: auto;
  border-radius: 50px 0 0 50px;
}

.hero-wrapper .swiper__inner .swiper-slide::after {
  position: absolute;
  right: 0;
  top: 0;
  width: 0%;
  height: 100%;
  content: "";
  background: var(--tj-heading-secondary);
  transition: all 0.4s ease;
  border-radius: 50px 0 0 50px;
  opacity: 0;
  z-index: -1;
}

.hero-wrapper .swiper__inner .swiper-slide::before {
  position: absolute;
  left: 0;
  top: 0;
  line-height: 70px;
  font-size: 19px;
  content: "\f178";
  color: var(--tj-common-white);
  font-family: var(--tj-ff-fontawesome);
  transition: all 0.5s ease;
  transform: rotate(180deg);
  opacity: 0;
  left: 30px;
}

.hero-wrapper .swiper__inner .swiper-slide:hover,
.hero-wrapper .swiper__inner .swiper-slide.swiper-slide-thumb-active {
  right: 15px;
}

.hero-wrapper .swiper__inner .swiper-slide:hover::after,
.hero-wrapper .swiper__inner .swiper-slide.swiper-slide-thumb-active::after {
  right: 0px;
  opacity: 1;
  width: 100%;
}

.hero-wrapper .swiper__inner .swiper-slide:hover::before,
.hero-wrapper .swiper__inner .swiper-slide.swiper-slide-thumb-active::before {
  opacity: 1;
  left: 15px;
}

.tj-slider.owl-carousel .owl-nav {
  margin-top: 30px;
  display: flex;
  justify-content: start;
  gap: 10px;
  align-items: center;
}

.tj-slider.owl-carousel .owl-nav .owl-prev,
.tj-slider.owl-carousel .owl-nav .owl-next {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 500px;
  background-color: var(--tj-theme-secondary);
  color: var(--tj-common-white);
  transition: all 0.4s ease-in-out;
}

.tj-slider.owl-carousel .owl-nav .owl-prev.active,
.tj-slider.owl-carousel .owl-nav .owl-prev:hover,
.tj-slider.owl-carousel .owl-nav .owl-next.active,
.tj-slider.owl-carousel .owl-nav .owl-next:hover {
  background-color: var(--tj-theme-primary);
}

.client_say_nav {
  display: none;
}

.working_success-wrapper .owl-dots,
.client_say .owl-dots {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.working_success-wrapper .owl-dots .owl-dot,
.client_say .owl-dots .owl-dot {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  background: #ffc8c8;
  transform: scale(1);
  border-radius: 300px;
}

.working_success-wrapper .owl-dots .owl-dot.active,
.client_say .owl-dots .owl-dot.active {
  transform: scale(1.2);
  background: var(--tj-heading-secondary);
}

.tj-single-feature .owl-dots {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.tj-single-feature .owl-dots .owl-dot {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  background: var(--tj-common-white);
  transform: scale(1);
  border-radius: 300px;
}

.tj-single-feature .owl-dots .owl-dot.active {
  transform: scale(1.2);
  background: var(--tj-heading-secondary);
}

.client__slider.owl-carousel .owl-nav {
  margin-top: 10px;
  position: absolute;
  bottom: -65%;
  left: -73%;
  display: flex;
  justify-content: start;
  gap: 10px;
  align-items: center;
}

.client__slider.owl-carousel .owl-nav .owl-prev,
.client__slider.owl-carousel .owl-nav .owl-next {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 500px;
  background-color: var(--tj-theme-secondary);
  color: var(--tj-common-white);
  transition: all 0.4s ease-in-out;
}

.client__slider.owl-carousel .owl-nav .owl-prev.active,
.client__slider.owl-carousel .owl-nav .owl-prev:hover,
.client__slider.owl-carousel .owl-nav .owl-next.active,
.client__slider.owl-carousel .owl-nav .owl-next:hover {
  background-color: var(--tj-theme-primary);
}

/* ----------------------------------
	07. Section Title CSS
 ------------------------------------ */
.section__heading {
  margin-bottom: 30px;
}

.section__heading.style-2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

.section__heading.style-2 .section_title {
  max-width: 560px;
  width: 100%;
  color: var(--tj-common-white);
  margin-bottom: 0;
}

.section__heading.style-2 .sec_dec {
  margin-left: auto;
  margin-right: 0;
  max-width: 530px;
}

.section__heading.style-2 .sec_dec p {
  color: var(--tj-common-white);
}

.section__heading .section_title {
  font-weight: 400;
  font-size: 35px;
  color: var(--tj-heading-primary);
  margin-bottom: 20px;
}

.section__heading .sec_dec {
  margin-left: auto;
  margin-right: auto;
  max-width: 553px;
  width: 100%;
}

.section__heading .sec_dec p:last-child {
  margin-bottom: 0;
}

.section__heading .section_sub__title {
  color: var(--tj-heading-secondary);
  margin-bottom: 15px;
  font-family: var(--tj-ff-body);
  font-weight: 600;
}

/* ----------------------------------
	HERO TITLE STYLEING
 ------------------------------------ */
.hero-content .hero_sub_title {
  font-size: 24px;
  font-weight: var(--tj-fw-medium);
  margin-bottom: 0px;
}

@media (max-width: 575px) {
  .hero-content .hero_sub_title {
    margin-bottom: 15px;
  }
}

.hero-content .hero-title {
  margin: 15px 0;
  line-height: 1.2;
  font-size: 45px;
}

/* ----------------------------------
	All SECTION TITLE STYLEING
 ------------------------------------ */
.page-title {
  font-weight: var(--tj-fw-sbold);
  margin-bottom: 20px;
}

/* ----------------------------------
	08. Header CSS
 ------------------------------------ */
header {
  position: relative;
  z-index: 99;
  /* ----top header 2------ */
  /* ----top header 3------ */
  /* ----top header 4------ */
  /* ----top header------ */
  /*--------Header Action btn--------*/
}

header .logo {
  padding: 10px 0;
}

header .logo a {
  display: block;
  max-width: 220px;
}

header .logo a img {
  width: 100%;
}

header.header-1 {
  margin-bottom: -82px;
}

header.header-1.header-sticky {
  margin-bottom: 0;
}

header.header-1.header-sticky .main__header {
  position: absolute;
  width: 100%;
  top: 0;
  visibility: hidden;
  opacity: 0;
}

header.header-1.header-sticky.sticky .main__header {
  visibility: visible;
  opacity: 1;
}

header.header-1.sticky .header-contact-widget {
  transform: translate3d(0, -100%, 0);
}

header.header-1.sticky .main__header {
  top: 0px;
  position: fixed;
  width: 100%;
  background-color: var(--tj-common-white);
  animation: sticky 1s both;
  z-index: 777;
  box-shadow: var(--tj-shadow-2) 0px 7px 29px 0px;
}

header.header-2 {
  position: absolute;
  width: 100%;
  z-index: 33;
}

header.header-2 .header2 {
  width: 100%;
  height: 100%;
}

header.header-2 .logo {
  padding: 10px 10px 10px 0px;
}

header.header-2 .logo.logo-2 {
  padding: 48px 25px;
  position: relative;
  background-color: var(--tj-theme-primary);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

header.header-2 .logo.logo-2::before {
  position: absolute;
  clear: both;
  content: "";
  z-index: 1;
  display: block;
  width: 50px;
  height: 100%;
  top: 0;
  right: -49px;
  background-color: var(--tj-theme-primary);
  clip-path: polygon(0 0, 0% 100%, 100% 0);
}

header.header-2 .header_searce {
  padding-right: 30px;
}

header.header-2 .header-contact {
  background: transparent;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

header.header-2 .header-contact .head-contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 60px;
}

header.header-2 .header-contact .head-contact .info_item {
  text-align: start;
  position: relative;
  padding: 0px 50px 0px 50px;
  margin: 10px 30px 10px 0px;
  border-right: 1px solid var(--tj-border-8);
}

header.header-2 .header-contact .head-contact .info_item:last-child {
  border: none;
}

header.header-2 .header-contact .head-contact .info_item:last-child h6,
header.header-2 .header-contact .head-contact .info_item:last-child p {
  color: var(--tj-heading-primary);
}

header.header-2 .header-contact .head-contact .info_item h6 {
  color: var(--tj-common-white);
  margin-bottom: 5px;
}

header.header-2 .header-contact .head-contact .info_item p {
  color: var(--tj-common-white);
  font-size: 18px;
  font-family: var(--tj-ff-heading);
  padding-bottom: 0px;
  margin-bottom: 0;
}

header.header-2 .header-contact .head-contact .info_item i {
  font-size: 35px;
  position: absolute;
  left: 0%;
  top: 5px;
  color: var(--tj-heading-secondary);
}

header.header-2 .header-contact .tj-btn-primary {
  padding: 32px 50px 31px 60px;
  position: relative;
}

header.header-2 .header-contact .tj-btn-primary.style-4 {
  clip-path: polygon(15% 0, 100% 0, 100% 100%, 0% 100%);
}

header.header-2 .main-menu ul>li {
  margin-right: 20px;
}

header.header-2 .main-menu ul>li>a {
  padding: 20px 25px;
}

header.header-2 .main-menu ul>li ul>li>a {
  padding: 15px 25px;
}

header.header-2 .shear-link {
  display: flex;
  align-items: center;
  gap: 10px;
}

header.header-2 .shear-link a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 500px;
  background-color: var(--tj-theme-secondary);
  color: var(--tj-common-white);
  font-size: 16px;
}

header.header-2 .shear-link a:hover {
  background: var(--tj-heading-secondary);
}

header.header-3.sticky .main__header,
header.header-5.sticky .main__header {
  top: 0px;
  position: fixed;
  width: 100%;
  background-color: #fff;
  animation: sticky 1s both;
  z-index: 377;
  box-shadow: var(--tj-shadow-2) 0px 7px 29px 0px;
}

header.header-3,
header.header-5 {
  position: relative;
}

header.header-3 .header__top,
header.header-5 .header__top {
  background-color: #090807;
  border-bottom: 1px solid #999;
}

header.header-3 .header__top .header__top_widget,
header.header-5 .header__top .header__top_widget {
  padding: 0;
  overflow: hidden;
}

header.header-3 .header__top .header__top_widget .top-left p,
header.header-5 .header__top .header__top_widget .top-left p {
  padding-bottom: 0px;
  margin-bottom: 0px;
}

header.header-3 .header__top .header__top_widget .top-left p a,
header.header-5 .header__top .header__top_widget .top-left p a {
  font-family: var(--tj-ff-body);
  font-style: normal;
  font-weight: var(--tj-fw-medium);
  font-size: 16px;
  line-height: 26px;
  color: #fff;
}

.header__top_widget .top-left p span {
  display: inline-block;
  padding-right: 15px;
}

.header__top_widget .top-left p span i {
  font-size: 18px;
  top: 3px;
  position: relative;
}

header.header-3 .header__top .header__top_widget .top-right,
header.header-5 .header__top .header__top_widget .top-right {
  display: flex;
  justify-content: center;
  align-items: center;
}

header.header-3 .header__top .header__top_widget .top-right a,
header.header-5 .header__top .header__top_widget .top-right a {
  margin-right: 25px;
  color: var(--tj-common-white);
}

header.header-3 .header__top .header__top_widget .top-right a i,
header.header-5 .header__top .header__top_widget .top-right a i {
  font-size: 17px;
}

header.header-3 .header__top .header__top_widget .top-right a:hover,
header.header-5 .header__top .header__top_widget .top-right a:hover {
  color: var(--tj-heading-secondary);
}

header.header-3 .header__top .header__top_widget .top-right .tj-btn-primary,
header.header-5 .header__top .header__top_widget .top-right .tj-btn-primary {
  margin-right: 0;
}

header.header-3 .header__top .header__top_widget .top-right .tj-btn-primary:hover,
header.header-5 .header__top .header__top_widget .top-right .tj-btn-primary:hover {
  color: var(--tj-common-white);
}

header.header-3 .main__header,
header.header-5 .main__header {
  padding: 0px 0px;
  background-color: #fff;
}

header.header-3 .main__header .main-menu,
header.header-5 .main__header .main-menu {
  justify-content: end;
  display: flex;
  align-items: center;
}

header.header-3 .header-contact .action-call,
header.header-5 .header-contact .action-call {
  padding: 0px 35px 0px 0px;
  display: flex;
  width: 210px;
  max-width: 100%;
  align-items: center;
  gap: 10px;
}

header.header-3 .header-contact .action-call .icon,
header.header-5 .header-contact .action-call .icon {
  width: 45px;
  height: 45px;
  border-radius: 400px;
  color: var(--tj-common-white);
  display: flex;
  position: relative;
  overflow: hidden;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  background-color: var(--tj-theme-primary);
  border: 1px solid var(--tj-heading-secondary);
}

header.header-3 .header-contact .action-call .icon i,
header.header-5 .header-contact .action-call .icon i {
  font-size: 18px;
  line-height: 0;
}

header.header-3 .header-contact .action-call .icon::after,
header.header-5 .header-contact .action-call .icon::after {
  z-index: -1;
  border-radius: 500px;
  content: "";
  clear: both;
  width: 0px;
  height: 0px;
  position: absolute;
  top: 50%;
  left: 50%;
  background: var(--tj-heading-primary);
  transition: all 0.4s ease-in;
}

header.header-3 .header-contact .action-call .content h6,
header.header-5 .header-contact .action-call .content h6 {
  font-family: var(--tj-ff-body);
  text-align: start;
  font-style: normal;
  font-weight: var(--tj-fw-sbold);
  font-size: 16px;
  line-height: 26px;
  color: var(--tj-heading-primary);
  margin-bottom: 0;
}

header.header-3 .header-contact .action-call .content span,
header.header-5 .header-contact .action-call .content span {
  font-family: var(--tj-ff-body);
  font-style: normal;
  text-align: start;
  font-weight: var(--tj-fw-regular);
  font-size: 16px;
  line-height: 26px;
  color: var(--tj-heading-primary);
}

header.header-3 .header-contact .action-call:hover .icon,
header.header-5 .header-contact .action-call:hover .icon {
  border: 1px solid var(--tj-heading-primary);
  background: transparent;
  color: var(--tj-common-white);
}

header.header-3 .header-contact .action-call:hover .icon::after,
header.header-5 .header-contact .action-call:hover .icon::after {
  top: -30%;
  left: -30%;
  width: 100px;
  height: 100px;
}

header.header-3 .header-contact .search-bar,
header.header-5 .header-contact .search-bar {
  border-left: 1px solid var(--tj-border-8);
  padding: 0px 20px;
}

header.header-3 .header-contact .search-bar i,
header.header-5 .header-contact .search-bar i {
  font-size: 22px;
}

header.header-4 {
  margin-top: 30px;
  position: absolute;
  width: 100%;
}

header.header-4 .main-menu {
  height: 100%;
  position: relative;
  padding: 6px 0px 5px 0px;
  justify-content: end;
  display: flex;
  align-items: center;
}

header.header-4 .main-menu::after {
  width: 100%;
  height: 100%;
  content: "";
  clear: both;
  display: block;
  position: absolute;
  background-color: var(--tj-common-white);
  top: 0%;
  left: 0%;
  z-index: -1;
}

header.header-4 .logo {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 16px 15px 15px 35px;
}

header.header-4 .logo::after {
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 88% 100%, 0% 100%);
  height: 100%;
  content: "";
  clear: both;
  display: block;
  position: absolute;
  background-color: var(--tj-common-white);
  top: 0%;
  left: 0%;
  z-index: -1;
}

header.header-4 .logo::before {
  width: 50px;
  clip-path: polygon(75% 0, 100% 0, 100% 100%, 0% 100%);
  height: 100%;
  content: "";
  clear: both;
  display: block;
  position: absolute;
  background-color: var(--tj-common-white);
  top: 0%;
  right: -10%;
  z-index: -1;
}

header.header-4 .tj-btn-primary,
header.header-4 #hamburger {
  display: none;
}

header.header-4 .header-contact .search-bar {
  border-right: 1px solid var(--tj-border-8);
  padding: 0px 20px;
  color: var(--tj-heading-primary) !important;
  display: block;
}

header.header-4 .header-contact .search-bar i {
  font-size: 22px;
}

header.header-4 .header-contact .search-bar:hover {
  color: var(--tj-heading-secondary);
}

header.header-4 .header-contact .offset-canvas i {
  font-size: 30px;
  color: var(--tj-heading-secondary);
  padding: 0px 30px 0px 20px;
  cursor: pointer;
}

header .header__top {
  background-color: var(--tj-theme-primary);
}

header .header__top .header__top_widget {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0px;
}

header .header__top .header__top_widget .top-left p {
  color: var(--tj-common-white);
  margin-bottom: 0px;
}

header .header__top .header__top_widget .top-right a {
  color: var(--tj-common-white);
}

header .header__top .header__top_widget .top-right a:not(:last-child) {
  margin-right: 25px;
}

header .header__top .header__top_widget .top-right a i {
  font-size: 16px;
}

header .header__top .header__top_widget .top-right a:hover {
  color: var(--tj-heading-primary);
}

header .header-contact-widget .header-contact {
  display: flex;
  align-items: center;
  background: var(--tj-heading-primary);
}

header .header-contact-widget .header-contact {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 0 100px;
}

header .header-contact-widget .header-contact .info_item {
  position: relative;
  border-right: 1px solid var(--tj-grey-4);
  margin-right: 65px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0px 60px 0px 50px;
}

header .header-contact-widget .header-contact .info_item i {
  font-size: 35px;
  line-height: 35px;
  color: var(--tj-heading-secondary);
  position: absolute;
  left: 0;
  top: 8px;
}

header .header-contact-widget .header-contact .info_item h6 {
  font-weight: var(--tj-fw-medium);
  color: var(--tj-common-white);
  margin: 0 0 1px;
}

header .header-contact-widget .header-contact .info_item p {
  color: var(--tj-common-white);
  font-family: var(--tj-ff-headeing);
  font-size: 15px;
  margin-bottom: 0;
}

header .header-contact-widget .header-contact .info_item:nth-child(3) {
  border: none;
}

header .header-contact-widget .header-contact .info_item:hover i {
  animation: top-bottom 0.4s ease-in-out;
}

header .header-contact-widget .header-contact .tj-btn-primary {
  margin-left: auto;
  font-size: 18px;
  padding: 32px 48px;
  position: relative;
  overflow: hidden;
  font-family: var(--tj-ff-heading);
  text-align: center;
  text-transform: capitalize;
  background: var(--tj-heading-secondary);
  color: var(--tj-common-white);
  z-index: 2;
  transition: all 0.4s ease;
}

header .header-contact-widget .header-contact .tj-btn-primary::after {
  content: "";
  position: absolute;
  height: 100%;
  left: 60%;
  top: 60%;
  width: 150%;
  transition: all 0.5s ease;
  z-index: -1;
  background: var(--tj-heading-secondary);
  transform: rotate(-25deg);
}

header .header-contact-widget .header-contact .tj-btn-primary:hover {
  background-color: var(--tj-common-white);
}

header .header-contact-widget .header-contact .tj-btn-primary:hover::after {
  left: -30%;
  top: -70%;
  height: 450%;
}

#hamburger,
#hamburger2 {
  width: 50px;
  height: 50px;
  border: 1px solid var(--tj-heading-primary);
  line-height: 50px;
  text-align: center;
  font-size: 35px;
  color: var(--tj-heading-primary);
  cursor: pointer;
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {

  #hamburger,
  #hamburger2 {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 30px;
  }
}

.search-btn {
  cursor: pointer;
  color: var(--tj-heading-primary);
}

.search-btn:hover {
  color: var(--tj-heading-secondary);
}

/* ---------------------------------
   Offset Menu Style 
------------------------------------*/
.offset_canvas {
  position: fixed;
  z-index: 9999;
  top: 0%;
  right: -500px;
  width: 500px;
  height: 100vh;
  overflow: auto;
  background: var(--tj-common-white);
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.offset_canvas .logo-canvas a {
  max-width: 170px;
  display: block;
  margin-bottom: 30px;
}

.offset_canvas .about__info {
  margin-bottom: 30px;
}

.offset_canvas .tj-search-box {
  margin-bottom: 20px;
}

.offset_canvas .action-call {
  margin-top: 30px;
}

.offset_canvas.show {
  right: 0px;
}

.offset_canvas .search-bar input {
  border: 1px solid var(--tj-heading-secondary);
}

.offset_canvas .info_item h6 {
  margin-bottom: 5px;
}

.offset_canvas .close-offset {
  font-size: 40px;
  line-height: 1;
  position: absolute;
  top: 40px;
  right: 55px;
  background: transparent;
  transition: all 0.3s ease-in-out 0s;
}

.offset_canvas .close-offset i {
  transition: none;
}

.offset_canvas .close-offset:hover {
  transform: rotate(90deg);
  color: var(--tj-theme-primary);
}

.offset_canvas .offset_canvas-about {
  padding: 100px 30px 50px 30px;
}

.offset_canvas p {
  font-size: 17px;
  line-height: 30px;
}

.offset_canvas .contact-info-of {
  margin-bottom: 30px;
}

.offset_canvas .social_item-of {
  margin-bottom: 30px;
}

.offset_canvas .contact__title {
  font-size: 30px;
  line-height: 45px;
  margin-bottom: 30px;
  padding-bottom: 15px;
  position: relative;
}

.offset_canvas .contact__title::after {
  width: 70px;
  height: 3px;
  content: "";
  clear: both;
  position: absolute;
  bottom: 2px;
  left: 0px;
  background: var(--tj-heading-secondary);
  border-radius: 3px;
}

.offset_canvas .contact-info-of .info_item {
  margin: 0px 0px 20px 0px;
  position: relative;
  display: block;
  padding-left: 60px;
}

.offset_canvas .contact-info-of .info_item i {
  font-size: 35px;
  line-height: 35px;
  color: var(--tj-heading-primary);
  position: absolute;
  left: 0;
  top: 8px;
}

.offset_canvas .contact-info-of .info_item h5 {
  font-weight: var(--tj-fw-medium);
  font-size: 18px;
  line-height: 24px;
  color: var(--tj-heading-primary);
  margin: 0 0 1px;
}

.offset_canvas .contact-info-of .info_item p {
  color: var(--tj-heading-primary);
  font-size: 15px;
  margin: 0;
  padding-bottom: 0;
}

.offset_canvas .contact-info-of .info_item:hover h5,
.offset_canvas .contact-info-of .info_item:hover p,
.offset_canvas .contact-info-of .info_item:hover i {
  color: var(--tj-heading-primary);
}

.offset_canvas .social_item-of {
  margin-bottom: 30px;
}

.offset_canvas .social_item-of .social_item {
  display: flex;
  gap: 7px;
  align-items: center;
}

.offset_canvas .social_item-of .social_item a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  border-radius: 500px;
  font-size: 22px;
  border: 1px solid var(--tj-heading-primary);
  color: var(--tj-heading-primary);
}

.offset_canvas .social_item-of .social_item a:hover {
  border: 1px solid var(--tj-heading-secondary);
  color: var(--tj-common-white);
  background: var(--tj-heading-secondary);
}

.offset_canvas iframe {
  width: 100%;
  height: 200px;
}

.hamburger-area {
  position: fixed;
  right: -490px;
  top: 0;
  width: 450px;
  height: 100%;
  box-shadow: -5px 0 20px -5px rgba(0, 0, 0, 0.5);
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-duration: 1s;
  z-index: 9999;
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  overflow-y: scroll;
}

.hamburger-area::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.hamburger-area.opened {
  right: 0px;
}

@media (max-width: 575px) {
  .hamburger-area {
    width: 290px;
  }
}

.hamburger_bg {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--tj-theme-secondary);
  z-index: -1;
  backdrop-filter: blur(37px);
}

.hamburger_wrapper {
  position: relative;
  padding: 45px 45px 90px 45px;
  overflow: auto;
  height: 100%;
}

@media (max-width: 575px) {
  .hamburger_wrapper {
    padding: 20px;
  }
}

.hamburger_top {
  margin-bottom: 40px;
}

.hamburger_logo {
  max-width: 185px;
}

.hamburger_logo a {
  display: block;
}

@media (max-width: 575px) {
  .hamburger_logo {
    max-width: 150px;
  }
}

.hamburger_close_btn {
  display: inline-block;
  font-size: 35px;
  color: var(--tj-common-white);
}

@media (max-width: 575px) {
  .hamburger_close_btn {
    font-size: 25px;
  }
}

.hamburger_close_btn:hover {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  color: var(--tj-theme-primary);
}

.hamburger_search {
  position: relative;
  margin-bottom: 40px;
}

.hamburger_search input {
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding: 0 25px;
  background: transparent;
  border: none;
  outline: none;
  border-bottom: 1px solid var(--tj-border-3);
  font-size: 18px;
  color: var(--tj-common-white);
}

.hamburger_search button {
  position: absolute;
  top: 50%;
  inset-inline-start: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 18px;
  color: var(--tj-common-white);
}

.hamburger_menu {
  overflow: hidden;
}

.hamburger_menu .mean-container .mean-bar {
  background: transparent;
  box-sizing: border-box;
}

.hamburger_menu .mean-container .mean-bar * {
  box-sizing: border-box;
}

.hamburger_menu .mean-nav {
  background: transparent;
  margin-top: 0;
  overflow: hidden;
}

.hamburger_menu .mean-nav>ul {
  display: block !important;
}

.hamburger_menu .mean-nav>ul>li:first-child>a {
  border-top: none;
}

.hamburger_menu .mean-nav ul li {
  position: relative;
}

.hamburger_menu .mean-nav ul li a {
  color: var(--tj-common-white);
  width: 100%;
  padding: 15px 0 12px 0;
  border-top: none;
  border-bottom: 1px solid var(--tj-border-5);
  font-family: var(--tj-ff-heading);
  font-weight: var(--tj-fw-bold);
  font-size: 15px;
  line-height: 1;
  letter-spacing: 1px;
}

.hamburger_menu .mean-nav ul li a:hover {
  color: var(--tj-theme-primary);
}

.hamburger_menu .mean-nav ul li a.mean-expand {
  padding: 0;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  position: absolute;
  right: 0;
  top: 5px;
  transition: all 0.3s ease-in-out 0s;
}

.hamburger_menu .mean-nav ul li a.mean-expand:hover {
  color: var(--tj-theme-primary);
}

.hamburger_menu .mean-nav ul li a.mean-expand.mean-clicked {
  color: var(--tj-theme-primary);
}

.hamburger_menu .mean-nav ul li a.mean-expand.mean-clicked i {
  transform: rotate(90deg);
}

.hamburger_menu .mean-nav ul li .sub-menu li {
  padding-left: 25px;
  position: relative;
}

.hamburger_menu .mean-nav ul li .sub-menu li a {
  width: 100%;
  padding: 15px 0 12px 0;
  text-transform: capitalize;
}

.hamburger_menu .mean-nav ul li .sub-menu li a.mean-expand {
  padding: 0;
  width: 30px;
}

.hamburger_menu .mean-nav ul li.dropdown-opened>a {
  color: var(--tj-theme-primary);
}

.hamburger_bottom {
  margin-top: 40px;
  padding-top: 30px;
  border-top: 1px solid var(--tj-border-3);
}

.hamburger_bottom .contact_info {
  margin-bottom: 30px;
}

.hamburger_bottom .contact_info .title {
  margin-bottom: 20px;
  color: var(--tj-common-white);
}

.hamburger_bottom .contact_info .info_list {
  list-style: none;
  padding: 0;
}

.hamburger_bottom .contact_info .info_list li {
  display: block;
}

.hamburger_bottom .contact_info .info_list li .item_inner .icon a {
  display: inline-block;
  width: 50px;
  height: 50px;
  background-color: var(--tj-theme-primary);
  color: var(--tj-common-white);
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  font-size: 20px;
}

.hamburger_bottom .contact_info .info_list li .item_inner .icon a:hover {
  background-color: var(--tj-common-white);
}

.hamburger_bottom .contact_info .info_list li .item_inner .text a {
  display: inline-block;
  font-family: var(--tj-ff-heading);
  font-weight: 500;
  color: var(--tj-common-white);
}

.hamburger_bottom .contact_info .info_list li .item_inner .text a:hover {
  color: var(--tj-theme-primary);
}

.hamburger_bottom .contact_info .info_list li:not(:last-child) {
  margin-bottom: 20px;
}

.hamburger_bottom .socials {
  padding-top: 30px;
  border-top: 1px solid var(--tj-border-3);
}

.hamburger_bottom .socials ul {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
}

.hamburger_bottom .socials ul li a {
  display: block;
  width: 50px;
  height: 50px;
  background-color: var(--tj-grey-3);
  border-radius: 50%;
  text-align: center;
  line-height: 50px;
  color: var(--tj-common-white);
  font-size: 20px;
}

.hamburger_bottom .socials ul li a:hover {
  background-color: var(--tj-theme-primary);
}

@media (max-width: 575px) {
  .hamburger_bottom .socials ul li a {
    width: 35px;
    height: 35px;
    line-height: 37px;
    font-size: 16px;
  }
}

.hamburger_bottom .socials ul li:not(:last-child) {
  margin-right: 20px;
}

.body-overlay {
  background-color: var(--tj-theme-primary);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-out 0s;
  backdrop-filter: blur(10px);
}

.body-overlay.opened {
  opacity: 0.8;
  visibility: visible;
}

/* !END: hamburger CSS */
.search_popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--tj-theme-secondary);
  z-index: 100;
  padding-top: 70px;
  padding-bottom: 100px;
  opacity: 0;
  backdrop-filter: blur(10px);
  -webkit-transform: translateY(calc(-100% - 80px));
  -moz-transform: translateY(calc(-100% - 80px));
  -ms-transform: translateY(calc(-100% - 80px));
  -o-transform: translateY(calc(-100% - 80px));
  transform: translateY(calc(-100% - 80px));
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transition-delay: 0.5s;
}

.search_popup.search-opened {
  opacity: 1;
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
  transform: translateY(0%);
  transition-delay: 0s;
  z-index: 999;
}

.search_popup.search-opened .search_form .search_input {
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
  opacity: 1;
  transition-delay: 0.3s;
}

.search_popup.search-opened .search_form .search_input::after {
  width: 100%;
  transition-delay: 0.5s;
}

@media (max-width: 575px) {
  .search_popup {
    padding-top: 50px;
    padding-bottom: 70px;
  }
}

.search_wrapper .search_top {
  margin-bottom: 80px;
}

.search_wrapper .search_top .search_logo {
  max-width: 200px;
}

.search_wrapper .search_top .search_logo a {
  display: block;
}

@media (max-width: 575px) {
  .search_wrapper .search_top .search_logo {
    max-width: 150px;
  }
}

.search_wrapper .search_top .search_close .search_close_btn {
  font-size: 25px;
  color: rgba(255, 255, 255, 0.3);
}

.search_wrapper .search_top .search_close .search_close_btn svg {
  width: 30px;
  height: 30px;
}

.search_wrapper .search_top .search_close .search_close_btn:hover {
  color: var(--tj-common-white);
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .search_wrapper .search_top {
    margin-bottom: 50px;
  }
}

@media (max-width: 575px) {
  .search_wrapper .search_top {
    margin-bottom: 40px;
  }
}

.search_wrapper .search_form .search_input {
  position: relative;
  height: 80px;
  -webkit-transform: translateY(-40px);
  -moz-transform: translateY(-40px);
  -ms-transform: translateY(-40px);
  -o-transform: translateY(-40px);
  transform: translateY(-40px);
  transition: all 0.3s ease-in-out;
  transition-delay: 0.5s;
  opacity: 0;
}

.search_wrapper .search_form .search_input::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 0%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
  transition-delay: 0.3s;
  transition: all 0.3s ease-in-out;
}

.search_wrapper .search_form .search_input input {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 0;
  outline: 0;
  font-size: 24px;
  color: var(--tj-common-white);
  border-bottom: 1px solid transparent;
  padding: 0;
  padding-right: 30px;
}

.search_wrapper .search_form .search_input input::placeholder {
  font-size: 24px;
}

.search_wrapper .search_form .search_input input~.search-focus-border {
  position: absolute;
  bottom: 0;
  left: auto;
  right: 0;
  width: 0;
  height: 1px;
  background-color: var(--tj-common-white);
  transition: all 0.5s;
}

.search_wrapper .search_form .search_input input:focus~.search-focus-border {
  width: 100%;
  left: 0;
  right: auto;
  transition: all 0.5s;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .search_wrapper .search_form .search_input input {
    font-size: 20px;
  }

  .search_wrapper .search_form .search_input input::placeholder {
    font-size: 20px;
  }
}

@media (max-width: 575px) {
  .search_wrapper .search_form .search_input input {
    font-size: 18px;
  }

  .search_wrapper .search_form .search_input input::placeholder {
    font-size: 18px;
  }
}

.search_wrapper .search_form .search_input button {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 18px;
  color: var(--tj-common-white);
}

.search-popup-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--tj-theme-primary);
  z-index: 99;
  opacity: 0.7;
  cursor: zoom-out;
  -webkit-transform: translateY(calc(-100% - 80px));
  -moz-transform: translateY(calc(-100% - 80px));
  -ms-transform: translateY(calc(-100% - 80px));
  -o-transform: translateY(calc(-100% - 80px));
  transform: translateY(calc(-100% - 80px));
  transition: all 0.5s ease-in-out 0s;
  transition-delay: 0.3s;
}

.search-popup-overlay.search-popup-overlay-open {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

/* !END: Search Popup CSS */
/* ------------------------------------------------------------------
	09. Main Menu CSS
-------------------------------------------------------------------- */
.main-menu ul {
  line-height: 1;
}

.main-menu ul>li {
  display: inline-block;
  position: relative;
  line-height: 1;
  position: relative;
  margin-right: 30px;
}

.main-menu ul>li>a {
  text-transform: capitalize;
  display: block;
  font-family: var(--tj-ff-heading);
  font-style: normal;
  font-weight: var(--tj-fw-sbold);
  font-size: 18px;
  line-height: 28px;
  color: #000;
  padding: 25px 12px;
  overflow: hidden;
}

.main-menu ul>li>a.search-btn {
  font-size: 22px;
  cursor: pointer;
  color: var(--tj-heading-primary);
}

.main-menu ul>li>a.active {
  color: var(--tj-heading-secondary);
}

.main-menu ul>li.has-dropdown a {
  padding-right: 25px;
}

.main-menu ul>li.has-dropdown a::after {
  content: "\f107";
  font-family: var(--tj-ff-fontawesome);
  font-weight: var(--tj-fw-bold);
  clear: both;
  position: absolute;
  right: 5%;
}

.main-menu ul>li:hover>a {
  color: var(--tj-heading-secondary);
}

.main-menu ul>li:hover>ul {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.main-menu ul>li>ul {
  position: absolute;
  top: 100%;
  width: 250px;
  background-color: var(--tj-common-white);
  left: 0;
  z-index: 999;
  box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
  visibility: hidden;
  opacity: 0;
  transform: translateY(40px);
  transition: all 0.5s ease-in-out;
  padding: 10px 0px;
}

.main-menu ul>li>ul li {
  display: block;
  margin-right: 0;
}

.main-menu ul>li>ul li>a {
  text-align: left;
  display: block;
  font-size: 15px;
  color: var(--tj-heading-primary);
  padding: 10px 15px;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.main-menu ul>li>ul li>a:hover {
  color: var(--tj-heading-secondary);
  padding-left: 30px !important;
}

.main-menu ul>li>ul li>a::after {
  display: none;
}

.main-menu ul>li>ul li.has-dropdown {
  margin-right: 0;
}

.main-menu ul>li>ul li.has-dropdown>a {
  padding-right: 25px;
}

.main-menu ul>li>ul li.has-dropdown>a::after {
  display: block;
  content: "\f105";
  font-family: var(--tj-ff-fontawesome);
  font-weight: var(--tj-fw-bold);
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.main-menu ul>li>ul ul {
  left: 100%;
  top: 0;
  margin-left: 5px;
}

.header_searce {
  color: var(--tj-heading-primary);
  font-size: 22px;
  cursor: pointer;
}

/* ----------------------------------
	10. Hero CSS
 ------------------------------------ */
.hero-wrapper.hero-1 {
  height: 30rem;
  overflow: hidden;
}

.hero-wrapper.hero-1 .single-hero-slider {
  padding: 240px 0px 150px 0px;
  z-index: 1;
  position: relative;
}

.hero-wrapper.hero-1 .single-hero-slider:before {
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  background: rgba(16, 32, 57, 0.5);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero-wrapper.hero-1 .single-hero-slider {
    padding: 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero-wrapper.hero-1 .single-hero-slider .hero-content .hero_sub_title {
    margin-bottom: 10px;
  }

  .hero-wrapper.hero-1 .single-hero-slider .hero-content .hero-title {
    font-size: 45px;
    margin-bottom: 20px;
  }
}

.hero-wrapper.hero-1 .line-left {
  position: absolute;
  top: 0%;
  left: 20%;
  height: 100%;
  z-index: -1;
}

.hero-wrapper.hero-1 .line-left::after {
  content: "";
  clear: both;
  display: flex;
  transform: skewX(-30deg);
  width: 380px;
  height: 100%;
  flex-shrink: 0;
  position: absolute;
  top: 0%;
  left: 20%;
  background: rgba(16, 32, 57, 0.3);
  z-index: 2;
}

.hero-wrapper.hero-1 .line-right {
  position: absolute;
  top: 0%;
  left: 20%;
  height: 100%;
  z-index: -1;
}

.hero-wrapper.hero-1 .line-right::before {
  content: "";
  clear: both;
  display: flex;
  transform: skewX(23.995deg);
  width: 380px;
  height: 100%;
  flex-shrink: 0;
  position: absolute;
  top: 0%;
  left: 20%;
  background: rgba(16, 32, 57, 0.3);
  z-index: 2;
}

.hero-wrapper.hero-1 .swiper-slide-active .hero-content .hero_sub_title,
.hero-wrapper.hero-1 .swiper-slide-active .hero-content .hero-title,
.hero-wrapper.hero-1 .swiper-slide-active .hero-content .client {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.hero-wrapper.hero-1 .swiper-slide-active .hero-content .hero_sub_title {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
}

.hero-wrapper.hero-1 .swiper-slide-active .hero-content .hero-title {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

.hero-wrapper.hero-1 .swiper-slide-active .hero-content .client {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
}

.hero-wrapper.hero-1 .swiper-slide-active .line-left {
  animation-fill-mode: both;
  animation-name: fadeInLeftBig;
  animation-delay: 0.7s;
  animation-duration: 0.7s;
}

.hero-wrapper.hero-1 .swiper-slide-active .line-right {
  animation-fill-mode: both;
  animation-name: fadeInRightBig;
  animation-delay: 0.7s;
  animation-duration: 0.7s;
}

.hero-wrapper.hero-2 {
  position: relative;
}

.hero-wrapper.hero-2 .hero-left {
  padding: 290px 20px 250px 20px;
  width: 700px;
  max-width: 100%;
  margin-left: auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero-wrapper.hero-2 .hero-left {
    padding: 230px 20px 200px 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero-wrapper.hero-2 .hero-left .hero-content .hero-sub {
    margin-bottom: 10px;
  }

  .hero-wrapper.hero-2 .hero-left .hero-content .hero-title {
    font-size: 65px;
  }

  .hero-wrapper.hero-2 .hero-left .hero-content .tpslider__info {
    margin-top: 30px;
  }
}

.hero-wrapper.hero-2 .hero-right {
  width: 830px;
  max-width: 100%;
  height: 100%;
  margin-left: auto;
}

.hero-wrapper.hero-2::after {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 60%;
  height: 100%;
  z-index: -1;
  content: "";
  clear: both;
  background: var(--tj-heading-primary);
}

.hero-wrapper.hero-2 .tjaction_call {
  display: flex;
  align-items: center;
  gap: 10px;
}

.hero-wrapper.hero-2 .tjaction_call .icon a {
  width: 60px;
  height: 60px;
  border-radius: 500px;
  background: var(--tj-common-white);
  font-size: 18px;
  color: var(--tj-heading-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1px;
}

.hero-wrapper.hero-2 .tjaction_call .icon a i {
  font-size: 24px;
  line-height: 1px;
}

.hero-wrapper.hero-2 .tjaction_call span {
  color: var(--tj-common-white);
  font-size: 18px;
  font-family: var(--tj-ff-body);
  font-weight: 500;
  line-height: 26px;
}

.hero-wrapper.hero-2 .tjaction_call:hover .icon a {
  background-color: var(--tj-theme-primary);
  color: var(--tj-common-white);
}

.hero-wrapper.hero-2 .hero-content {
  position: relative;
  z-index: 2;
}

.hero-wrapper.hero-2 .hero-content .hero-title {
  font-weight: var(--tj-fw-bold);
  font-size: 70px;
  margin-bottom: 20px;
}

.hero-wrapper.hero-2 .hero-content .hero-sub {
  font-size: 24px;
}

.hero-wrapper.hero-2 .hero-content p {
  max-width: 600px;
  margin: 0 auto;
  font-weight: var(--tj-fw-medium);
  font-size: 18px;
  line-height: 28px;
  color: var(--tj-common-white);
}

.hero-wrapper.hero-2 .hero-content .tpslider__info {
  margin-top: 50px;
  display: flex;
  align-items: center;
  gap: 22px;
}

.hero-wrapper.hero-2 .hero-content .tpslider__info .tj-btn-primary.style-1:hover {
  color: var(--tj-heading-secondary);
}

.hero-wrapper.hero-2 .hero-content .tpslider__info .tj-btn-primary.style-1:hover::after {
  background-color: var(--tj-common-white);
}

.hero-wrapper.hero-2 .hero-shape {
  position: absolute;
  bottom: 0%;
  left: 0;
  z-index: 1;
}

.hero-wrapper.hero-3 {
  padding: 180px 0px 280px 0px;
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero-wrapper.hero-3 {
    padding: 100px 0 150px 0;
  }
}

.hero-wrapper.hero-3 .hero-content .hero-title {
  font-weight: var(--tj-fw-sbold);
  font-size: 85px;
  margin-bottom: 20px;
}

.hero-wrapper.hero-1 .hero-content p {
  max-width: 604px;
  font-weight: var(--tj-fw-regular);
  font-size: 18px;
  line-height: 28px;
  color: #fff;
}

.hero-wrapper.hero-3 .hero-content a {
  margin-top: 40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero-wrapper.hero-3 .hero-content .hero-title {
    font-size: 75px;
  }

  .hero-wrapper.hero-3 .hero-content a {
    margin-top: 20px;
  }
}

.hero-wrapper.hero-3 .house-element {
  position: absolute;
  left: 0%;
  bottom: 0%;
  z-index: -1;
}

.hero-wrapper.hero-3 .shape-element {
  position: absolute;
  left: 65px;
  top: 10%;
  z-index: -1;
}

.hero-wrapper.hero-3 .dots-element {
  position: absolute;
  left: 45%;
  bottom: 20%;
  z-index: -1;
  animation: bounce ease-in-out 8s infinite;
}

.hero-wrapper.hero-4 {
  overflow: hidden;
}

.hero-wrapper.hero-4 .single-hero-slider {
  z-index: 1;
  position: relative;
  padding: 300px 0px;
}

.hero-wrapper.hero-4 .single-hero-slider:before {
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero-wrapper.hero-4 .single-hero-slider {
    padding: 200px 0;
  }
}

.hero-wrapper.hero-4 .hero-content p {
  max-width: 600px;
  margin: 0 auto;
  font-weight: var(--tj-fw-medium);
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: var(--tj-common-white);
}

.hero-wrapper.hero-4 .hero-content .tpslider__info {
  margin-top: 50px;
  display: flex;
  align-items: center;
  gap: 22px;
  justify-content: center;
}

.hero-wrapper.hero-4 .hero-content .tpslider__info .tjaction_call {
  display: flex;
  align-items: center;
  gap: 10px;
}

.hero-wrapper.hero-4 .hero-content .tpslider__info .tjaction_call .icon i {
  font-size: 35px;
  line-height: 0;
  color: var(--tj-common-white);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.hero-wrapper.hero-4 .hero-content .tpslider__info .tjaction_call .tjcontent {
  text-align: left;
}

.hero-wrapper.hero-4 .hero-content .tpslider__info .tjaction_call .tjcontent h6 {
  font-family: var(--tj-ff-body);
  color: var(--tj-common-white);
  margin-bottom: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.hero-wrapper.hero-4 .hero-content .tpslider__info .tjaction_call .tjcontent p {
  font-family: var(--tj-ff-body);
  font-style: normal;
  font-size: 18px;
  color: var(--tj-common-white);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.hero-wrapper.hero-4 .hero-content .tpslider__info .tjaction_call:hover h6,
.hero-wrapper.hero-4 .hero-content .tpslider__info .tjaction_call:hover p {
  color: var(--tj-heading-secondary);
}

.hero-wrapper.hero-4 .hero-content .tpslider__info .tjaction_call:hover .icon i {
  color: var(--tj-heading-secondary);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero-wrapper.hero-4 .hero-content .hero-title {
    font-size: 65px;
    margin-bottom: 30px;
  }
}

.breadcrumb-wrapper {
  overflow: hidden;
  position: relative;
  padding: 90px 0px;
  background-image: url('../images/breadcrumb.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.breadcrumb-wrapper .breadcrumb-content {
  position: relative;
  z-index: 1;
}

.breadcrumb-wrapper .breadcrumb-content ul {
  background: var(--tj-heading-secondary);
  display: inline-block;
}

.breadcrumb-wrapper .breadcrumb-content ul>li {
  padding: 15px 20px;
  color: var(--tj-common-white);
  font-size: 18px;
  font-family: var(--tj-ff-body);
  font-weight: var(--tj-fw-medium);
  line-height: 28px;
}

.breadcrumb-wrapper .breadcrumb-content ul>li a {
  color: var(--tj-common-white);
  font-size: 18px;
  font-family: var(--tj-ff-body);
  font-weight: var(--tj-fw-medium);
  line-height: 28px;
  position: relative;
}

.breadcrumb-wrapper .breadcrumb-content ul>li a:hover {
  color: var(--tj-heading-primary);
}

.breadcrumb-wrapper .breadcrumb-content ul>li a::after {
  width: 1px;
  height: 100%;
  clear: both;
  content: "/";
  position: absolute;
  color: var(--tj-common-white);
  top: 0%;
  right: -20px;
}

.breadcrumb-wrapper .title {
  margin-top: 35px;
  color: var(--tj-common-white);
  font-size: 55px;
  font-weight: var(--tj-fw-bold);
  margin-bottom: 0;
}

.breadcrumb-wrapper .hero-page-element {
  position: absolute;
  top: 0%;
  z-index: 0;
}

.breadcrumb-wrapper .hero-right-element {
  position: absolute;
  top: 0%;
  right: 0;
  animation: pluse 5s ease-in-out infinite;
  z-index: 0;
}

.breadcrumb-wrapper::before {
  content: "";
  clear: both;
  display: flex;
  transform: skewX(40.596deg);
  width: 380px;
  height: 100%;
  flex-shrink: 0;
  position: absolute;
  top: 0%;
  left: 10%;
  background: rgba(255, 255, 255, 0.45);
  z-index: 1;
}

.client {
  position: relative;
  margin-right: -30px;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  padding-bottom: 0;
  left: 0;
}

.client img {
  width: 50px;
  height: 50px;
  border-radius: 500px;
  display: inline-block;
  margin-right: -30px;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.client p {
  margin-left: 40px;
  display: inline-block;
  font-weight: var(--tj-fw-medium);
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 0;
  padding-bottom: 0;
  color: var(--tj-common-white);
}

/* ----------------------------------
	11. About CSS
------------------------------------ */
.about-wrapper.about-1 {
  position: relative;
}

.about-wrapper.about-1 .about-shape {
  position: absolute;
  bottom: 0%;
  right: 0;
  z-index: -1;
  animation: pluse 8s ease-in-out infinite;
}

.about-wrapper.about-1 hr {
  height: 1px;
  width: 100%;
  background: var(--tj-grey-1);
}

.about-wrapper.about-2 .bg-img {
  position: relative;
}

.about-wrapper.about-2 .bg-img img {
  max-width: 100%;
  object-fit: cover;
  width: 100%;
}

.about-wrapper.about-2 .bg-img .about__funfact {
  position: absolute;
  bottom: 0%;
  left: 0%;
}

.about-wrapper.about-2 .bg-img .funfact {
  width: 350px;
  position: relative;
  padding: 10px;
  display: inline-block;
}

.about-wrapper.about-2 .bg-img .funfact::after {
  content: "";
  clear: both;
  position: absolute;
  width: 346px;
  height: 319px;
  z-index: -1;
  left: -20px;
  bottom: -20px;
  clip-path: polygon(0 0, 0% 100%, 100% 100%);
  background: var(--tj-heading-secondary);
}

.about-wrapper.about-2 .about-shape {
  position: absolute;
  bottom: 20%;
  right: 0;
  z-index: -1;
  animation: pluse 8s ease-in-out infinite;
}

.about-wrapper.about-2 .client p {
  color: var(--tj-text-body);
}

.about-wrapper.about-2 .content ul>li {
  margin-bottom: 15px;
}

.about-wrapper.about-2 .funfact {
  z-index: 2;
  position: absolute;
  left: 0%;
  bottom: 0px;
}

.about-wrapper.about-2 .funfact .funfact_count {
  font-family: var(--tj-ff-heading);
  font-weight: var(--tj-fw-bold);
  font-size: 55px;
  line-height: 45px;
  color: var(--tj-common-white);
  margin-bottom: 10px;
}

.about-wrapper.about-2 .funfact h2,
.about-wrapper.about-2 .funfact p {
  color: var(--tj-common-white);
  margin-bottom: 0;
}

.about-wrapper.about-2 .author-content {
  margin-top: 30px;
}

.about-wrapper.about-2 .section__heading {
  margin-bottom: 40px;
}

.about-wrapper.about-2 .section__heading .section_title {
  margin-bottom: 40px;
}

.about-wrapper.about-2 .about__right {
  margin-bottom: 30px;
}

.about-wrapper.about-2 .about-left {
  margin-bottom: 50px;
}

.about-wrapper.about-4 .about_heading {
  margin-bottom: 30px;
}

.about-wrapper.about-4 .about-left {
  position: relative;
}

.about-wrapper.about-4 .about-left .about-shape {
  position: absolute;
  top: 0%;
  left: calc(20% + 20px);
  z-index: -1;
  animation: pluse-icon 6s ease-in-out infinite;
}

.about-wrapper.about-4 .about-left .tj-bage {
  position: absolute;
  bottom: 0%;
  left: 0%;
  animation: circle 8s ease-in-out infinite;
}

.about-wrapper.about-4 .about-right .section_title {
  font-weight: 400;
  margin-bottom: 20px;
}

.about-wrapper.about-4 .about-right .quote__inner__text {
  margin-bottom: 15px;
}

.about-wrapper.about-4 .about-right .quote__inner__text .quote__text {
  color: var(--tj-heading-primary);
  font-size: 20px;
  font-family: var(--tj-ff-body);
  font-weight: var(--tj-fw-medium);
  line-height: 26px;
  margin-bottom: 15px;
  display: block;
}

.about-wrapper.about-4 .about-right p {
  display: block;
  font-size: 1.1rem;
}

.about-wrapper.about-4 .about-right .tj-single-thumb {
  margin-bottom: 20px;
  position: relative;
  display: flex;
  gap: 20px;
  align-items: center;
  background: var(--tj-common-white);
  box-shadow: 0px 0px 45px 0px rgba(0, 0, 0, 0.1);
  padding: 17px;
}

.about-wrapper.about-4 .about-right .tj-single-thumb::after {
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  position: absolute;
  bottom: 0%;
  left: 0;
  width: 0;
  height: 2px;
  content: "";
  clear: both;
  background-color: var(--tj-theme-primary);
}

.about-wrapper.about-4 .about-right .tj-single-thumb .icons i {
  font-size: 35px;
  line-height: 1;
  color: var(--tj-heading-primary);
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.about-wrapper.about-4 .about-right .tj-single-thumb .content .title {
  margin-bottom: 0;
}

.about-wrapper.about-4 .about-right .tj-single-thumb:hover::after {
  width: 100%;
}

.about-wrapper.about-4 .about-right .tj-single-thumb:hover .icons i {
  color: var(--tj-heading-secondary);
}

.block-contents {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 20px;
}

.block-contents .about__image {
  width: 190px;
  max-width: 100%;
}

.block-contents .about__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.block-contents .content ul>li {
  margin-bottom: 15px;
  display: block;
  font-family: var(--tj-ff-heading);
  font-weight: var(--tj-fw-sbold);
  font-size: 18px;
  line-height: 26px;
  color: var(--tj-heading-primary);
  position: relative;
  padding-left: 25px;
}

.block-contents .content ul>li::before {
  content: "\f10a";
  font-size: 18px;
  color: var(--tj-heading-secondary);
  top: 0%;
  left: 0;
  position: absolute;
  font-family: "flaticon_roofing_figma_file";
}

.block-contents .content ul>li:last-child {
  margin-bottom: 0;
}

.about-left {
  width: 100%;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}

.about-left .bg-img {
  height: 100%;
  width: 100%;
}

.about-left .bg-img img {
  max-width: 95%;
  width: 100%;
  object-fit: cover;
}

.about-left .shape-element {
  position: absolute;
  top: 5%;
  right: -20px;
  z-index: -1;
  animation: pluse 4s ease-in-out infinite;
}

.about-left .funfact-home {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 25px;
  padding: 40px 50px;
  background: var(--tj-heading-secondary);
  bottom: 0%;
  left: 0;
}

.about-left .icons i {
  font-size: 80px;
  color: var(--tj-common-white);
}

.about-left .funfact .funfact_count {
  font-family: var(--tj-ff-heading);
  color: var(--tj-common-white);
  margin-bottom: 5px;
}

.about-left .funfact .fun_dec {
  color: var(--tj-common-white);
  margin-bottom: 0;
}

.about-right {
  margin-bottom: 0px;
}

.about-right .about_heading {
  margin-bottom: 20px;
}

.about-right .single-element {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 35px;
}

.about-right .single-element .icons i {
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  font-size: 35px;
  color: var(--tj-heading-secondary);
}

.about-right .single-element h5 {
  margin-bottom: 0;
}

.author-content {
  margin-top: 25px;
  display: flex;
  gap: 50px;
}

.author-content .autoe {
  display: flex;
  gap: 20px;
  align-items: center;
}

.author-content .autoe .autor-img {
  width: 70px;
  height: 70px;
  overflow: hidden;
  border-radius: 500px;
}

.author-content .autoe .autor-img img {
  object-fit: cover;
}

.author-content .autoe .autor-name h6 {
  font-weight: var(--tj-fw-medium);
  margin-bottom: 0;
}

.author-content .autoe .autor-name span {
  font-family: var(--tj-ff-body);
  font-style: normal;
  font-weight: var(--tj-fw-regular);
  font-size: 16px;
  line-height: 26px;
  color: var(--tj-text-body);
}

.author-content .signature img {
  max-width: 120px;
}

.about__content {
  padding-bottom: 0px;
}

.about__content .section__heading {
  margin-bottom: 40px;
}

.about_right_widget {
  padding-left: 30px;
}

/* ----------------------------------
	VIDEO SECTION STYLEING
 ------------------------------------ */
.video-wrapper.video-1 {
  margin-top: 110px;
  /* background-image: url(../img/video/video-bg.png); */
  background-repeat: no-repeat;
  background-size: cover;
}

.video-wrapper.video-1 .video {
  margin-top: -109px;
}

.video-wrapper.video-1 .video-right {
  margin-top: -109px;
}

.video-wrapper.video-4 {
  overflow: hidden;
  position: relative;
}

.video-wrapper.video-4 .tj-video {
  margin-bottom: 30px;
}

.video-wrapper.video-4 .video-play-btn {
  position: relative;
  z-index: 2;
  width: 110px;
  height: 110px;
  border-radius: 500px;
  display: flex;
  justify-content: center;
  border: 2px dashed var(--tj-heading-secondary);
  align-items: center;
}

.video-wrapper.video-4 .video-play-btn i {
  font-size: 35px;
  color: var(--tj-heading-secondary);
}

.video-wrapper.video-4 .video-play-btn:hover .play-video {
  background-color: var(--tj-theme-primary);
}

.video-wrapper.video-4 .video-play-btn:hover .play-video i {
  color: var(--tj-common-white);
}

.video-wrapper.video-4 .video__title {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  font-weight: 700;
  color: var(--tj-common-white);
}

.video-wrapper.video-4 .play-video {
  background: var(--tj-common-white);
  width: 90px;
  height: 90px;
  border-radius: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-wrapper.video-4 .shape-element-1 {
  position: absolute;
  top: 0%;
  left: calc(40% + 20px);
}

.video-wrapper.video-4 .shape-element-2 {
  position: absolute;
  bottom: 0%;
  right: 2%;
}

.video-wrapper.video-4 .shape-element-3 {
  position: absolute;
  top: 0%;
  left: 2%;
}

.video-wrapper .video-content {
  padding: 100px 0px;
}

.video-wrapper .video-content .section_title {
  margin-bottom: 50px;
  color: var(--tj-common-white);
}

.video-wrapper .video-content .section__heading {
  margin-bottom: 0;
}

.video-wrapper .service-content {
  margin-right: 50px;
  display: flex;
  gap: 20px;
}

.video-wrapper .service-content:not(:last-child) {
  margin-bottom: 30px;
}

.video-wrapper .service-content .icons {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border-radius: 500px;
  background-color: var(--tj-common-white);
}

.video-wrapper .service-content .icons svg {
  width: 35px;
  height: auto;
  object-fit: cover;
}

.video-wrapper .service-content .icons i {
  font-size: 35px;
  color: var(--tj-heading-secondary);
}

.video-wrapper .service-content .content {
  max-width: 348px;
  width: 100%;
}

.video-wrapper .service-content .content .service__content__title {
  font-size: 22px;
  color: var(--tj-common-white);
  margin-bottom: 10px;
}

.video-wrapper .service-content .content p {
  font-size: 16px;
  color: var(--tj-text-4);
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 0;
}

.video-wrapper.video-5 {
  padding-bottom: 250px;
}

.single-video-content {
  max-width: 650px;
  margin-left: auto;
}

.video-popup {
  position: relative;
}

.video-popup span {
  top: 0%;
  left: 20%;
  font-family: var(--tj-ff-body);
  font-style: normal;
  font-weight: var(--tj-fw-medium);
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  position: absolute;
  color: var(--tj-heading-primary);
}

.video-popup .video {
  margin-left: auto;
  position: relative;
  right: 0px;
  display: flex;
  justify-content: end;
  align-items: center;
}

.video-popup .video a {
  overflow: hidden;
  position: relative;
  background: var(--tj-heading-secondary);
  padding: 35px;
  font-size: 35px;
  line-height: 35px;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  color: var(--tj-common-white);
}

.video-popup .video a i {
  line-height: 35px;
}

.video-popup .video a::after {
  content: "";
  position: absolute;
  height: 0%;
  left: 50%;
  top: 50%;
  width: 150%;
  transition: all 0.3s ease;
  z-index: -1;
  background: var(--tj-heading-primary);
  transform: translateX(-50%) translateY(-50%) rotate(-25deg);
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.video-popup .video a:hover {
  background: transparent;
}

.video-popup .video a:hover::after {
  height: 250%;
}

.video-popup .arrow {
  position: absolute;
  top: 10%;
  left: 53%;
  animation: pluse 6s ease-in-out infinite;
}

.video-right {
  width: 95%;
  height: 640px;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
}

.video-right::after {
  content: "";
  clear: both;
  clip-path: polygon(0 0, 0% 100%, 100% 100%);
  position: absolute;
  bottom: -1px;
  left: -1px;
  width: 131px;
  height: 141px;
  background-color: var(--tj-theme-secondary);
}

.video-right .play-video {
  width: 100px;
  height: 100px;
  line-height: 100px;
  font-size: 35px;
}

/* ----------------------------------
	12. Services CSS
 ------------------------------------ */
.service-wrapper {
  background-color: #000;
}

.service-wrapper.service-3 .single-service-2 {
  margin-bottom: 30px;
}

.service-wrapper.service-3 .single-service-2 .service-thumb {
  height: 300px;
  width: 100%;
  clip-path: polygon(100% 0, 100% 90%, 50% 100%, 0 90%, 0 1%);
  background-color: var(--tj-theme-secondary);
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.service-wrapper.service-3 .single-service-2 .service-thumb.service-thumb-2 {
  background: transparent;
}

.service-wrapper.service-3 .single-service-2 .service-thumb .service-img {
  height: 290px;
  width: 100%;
  clip-path: polygon(100% 0, 100% 90%, 50% 100%, 0 90%, 0 1%);
}

.service-wrapper.service-3 .single-service-2 .service-thumb .service-img img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.service-wrapper.service-3 .single-service-2:hover .service-thumb {
  background: var(--tj-theme-primary);
}

.service-wrapper.service-3 .single-service-2:hover .icons {
  border: 3px solid var(--tj-theme-primary);
}

.service-wrapper.service-3 .single-service-2:hover .icons.icons-2 {
  border: 3px solid var(--tj-common-white);
  background: var(--tj-theme-primary);
}

.service-wrapper.service-3 .single-service-2:hover .icons.icons-2 i {
  color: var(--tj-common-white);
}

.service-wrapper.service-3 .single-service-2:hover .icons i {
  font-size: 50px;
  color: var(--tj-theme-primary);
}

.service-wrapper.service-3 .service-content {
  padding: 0px 30px 40px 30px;
  background: var(--tj-common-white);
  box-shadow: 0px 0px 10px var(--tj-shadow-1);
}

.service-wrapper.service-3 .service-content .service-icon {
  position: relative;
  margin-top: -50px;
  z-index: 1;
}

.service-wrapper.service-3 .service-content .icons {
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  border-radius: 500px;
  background: var(--tj-common-white);
  border: 3px solid var(--tj-heading-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.service-wrapper.service-3 .service-content .icons.icons-2 {
  border: 3px solid var(--tj-heading-primary);
}

.service-wrapper.service-3 .service-content .icons.icons-2 i {
  line-height: 0;
  color: var(--tj-theme-primary);
}

.service-wrapper.service-3 .service-content .icons i {
  font-size: 50px;
  color: var(--tj-heading-primary);
  line-height: 0;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.service-wrapper.service-3 .service-content .tj_service-name {
  font-weight: var(--tj-fw-sbold);
  margin-bottom: 20px;
  font-size: 20px;
}

.service-wrapper.service-3 .service-content .tj_service-name:hover {
  color: var(--tj-theme-primary);
}

.service-wrapper.service-3 .service-content p {
  text-align: center;
}

.service-wrapper.service-3 .service-content p:last-child {
  margin-bottom: 0;
}

.service__tab {
  margin-bottom: 20px;
}

.service__tab .service-filter-btn {
  position: relative;
  max-width: 195px;
  width: 100%;
  margin: 30px 20px 0px 10px;
}

.service__tab .service-filter-btn .tj_btn__inner {
  height: auto;
  text-align: center;
  width: 195px;
  border-radius: 4px;
  cursor: pointer;
  padding: 30px 20px;
  background-color: var(--tj-common-white);
  margin-bottom: 5px;
}

.service__tab .service-filter-btn .tj_btn__inner .t-icons {
  margin-bottom: 15px;
}

.service__tab .service-filter-btn .tj_btn__inner .service__tab_text {
  font-weight: var(--tj-fw-bold);
  text-align: center;
}

.service__tab .service-filter-btn .tj_btn__inner::after {
  content: "";
  clear: both;
  display: block;
  width: 30px;
  height: 30px;
  background: var(--tj-theme-primary);
  position: absolute;
  top: -29px;
  left: 0px;
  visibility: hidden;
  opacity: 0;
  transition: 0.6s ease-in-out;
}

.service__tab .service-filter-btn .tj_btn__inner::before {
  content: "";
  clear: both;
  display: block;
  width: 30px;
  height: 30px;
  background: #93C94F;
  clip-path: polygon(0 0, 0% 100%, 100% 100%);
  position: absolute;
  top: -29px;
  left: 29px;
  visibility: hidden;
  opacity: 0;
  transition: 0.6s ease-in-out;
}

.service__tab .service-filter-btn .tj_btn__inner:hover {
  border-radius: 0px 4px 4px 4px;
  background-color: var(--tj-theme-primary);
}

.service__tab .service-filter-btn .tj_btn__inner:hover .t-icons,
.service__tab .service-filter-btn .tj_btn__inner:hover .service__tab_text {
  color: var(--tj-common-white);
}

.service__tab .service-filter-btn .tj_btn__inner:hover::after,
.service__tab .service-filter-btn .tj_btn__inner:hover::before {
  visibility: visible;
  opacity: 1;
}

.service__tab .service-filter-btn.active .tj_btn__inner {
  border-radius: 0px 4px 4px 4px;
  background-color: var(--tj-theme-primary);
}

.service__tab .service-filter-btn.active .tj_btn__inner .t-icons,
.service__tab .service-filter-btn.active .tj_btn__inner .service__tab_text {
  color: var(--tj-common-white);
}

.service__tab .service-filter-btn.active .tj_btn__inner::after,
.service__tab .service-filter-btn.active .tj_btn__inner::before {
  visibility: visible;
  opacity: 1;
}

.service__tab .service-filter-btn:first-child {
  padding-left: 0;
  margin-left: 0;
}

.service__tab .service-filter-btn:last-child {
  padding-right: 0;
  margin-right: 0;
}

.service__tab .t-icons {
  font-size: 55px;
  line-height: 40px;
  color: var(--tj-theme-primary);
}

.tab-item-widget {
  margin-bottom: 30px;
}

.single-service {
  background: var(--tj-common-white);
  border-radius: 4px;
  padding: 50px;
  visibility: hidden;
  display: none;
  opacity: 0;
  animation: fadeIn 1s ease-in-out 0s;
}

.single-service.active {
  visibility: visible;
  display: block;
  opacity: 1;
}

.service-items-content .service-item-title {
  font-weight: var(--tj-fw-sbold);
  font-size: 35px;
  margin-bottom: 20px;
}

.service-items-content .header__title {
  margin-bottom: 25px;
}

.service-items-content .roofing__service__point h5 {
  font-weight: var(--tj-fw-bold);
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 700;
  line-height: 26px;
  color: var(--tj-theme-primary);
}

.service-items-content .roofing__service__point ul {
  margin-bottom: 15px;
  columns: 1;
  max-width: 500px;
  width: 100%;
}

.service-item-right-img {
  margin-top: 0px !important;
}

.service-items-content .roofing__service__point ul li {
  display: block;
  margin-bottom: 15px;
  font-weight: var(--tj-fw-sbold);
  padding-left: 25px;
  font-size: 18px;
  line-height: 20px;
  font-family: var(--tj-ff-heading);
  color: var(--tj-heading-primary);
  position: relative;
}

.service-items-content .roofing__service__point ul li::after {
  content: "\f135";
  line-height: 1;
  color: var(--tj-theme-primary);
  position: absolute;
  top: 0;
  left: 0;
  font-size: 20px;
  font-family: "flaticon_roofing_figma_file";
}

.service-items-content .roofing__service__point ul li i {
  position: absolute;
  top: 3px;
  left: 0;
  font-size: 20px;
  margin-right: 6px;
  color: var(--tj-theme-primary);
}

.service-item-right-img {
  height: 400px;
  width: 100%;
  border-radius: 4px;
}

.project-item-card img {
  max-width: 100%;
}

/* ----------------------------------
	SERVICE DETAILS SECTION STYLEING
 ------------------------------------ */
.service__details-wrapper .service_details_left .service-details__benefits {
  margin-bottom: 40px;
}

.service__details-wrapper .service_details_left .service-details__benefits .tj-info-bg {
  width: 100%;
  height: 500px;
  overflow: hidden;
  margin-bottom: 40px;
}

.service__details-wrapper .service_details_left .service-details__benefits .tj-info-bg img {
  object-fit: cover;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  width: 100%;
  height: 100%;
}

.service__details-wrapper .service_details_left .service-details__benefits .tj-info-bg:hover img {
  transform: scale(1.2);
}

.service__details-wrapper .service_details_left .service-details__benefits .tj-info-bg.style-2:hover img {
  transform: none;
}

.service__details-wrapper .service_details_left .service-details__benefits .service-details__content-title {
  font-size: 35px;
  font-weight: var(--tj-fw-bold);
  margin-bottom: 20px;
}

.service__details-wrapper .service_details_left .service-details__benefits h4 {
  font-weight: var(--tj-fw-bold);
  margin-bottom: 20px;
}

.service__details-wrapper .service_details_left .service-details__benefits .tj-img {
  overflow: hidden;
  margin-bottom: 40px;
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
  align-items: center;
}

.service__details-wrapper .service_details_left .service-details__benefits .tj-img img {
  object-fit: cover;
  max-width: 48%;
  width: 100%;
  height: auto;
}

.service__details-wrapper .service_details_left .service_features {
  display: flex;
  align-items: center;
  gap: 35px;
}

.service__details-wrapper .service_details_left .tj_features-img {
  max-width: 330px;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.service__details-wrapper .service_details_left .tj_features-img img {
  object-fit: cover;
}

.service__details-wrapper .service_details_left .tj_features-content ul>li {
  display: block;
  color: var(--tj-heading-primary);
  font-size: 18px;
  font-family: var(--tj-ff-heading);
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 15px;
  position: relative;
  padding-left: 30px;
}

.service__details-wrapper .service_details_left .tj_features-content ul>li::after {
  content: "\f00c";
  font-weight: var(--tj-fw-bold);
  clear: both;
  font-family: var(--tj-ff-fontawesome);
  position: absolute;
  top: 0%;
  left: 0%;
  font-size: 22px;
  color: var(--tj-theme-primary);
}

.service__details-wrapper .service_details_left .tj_features-content ul>li:last-child {
  margin-bottom: 0;
}

.service__benefits__inner {
  margin-bottom: 40px;
}

.tj-main-service {
  position: relative;
  overflow: hidden;
  background-color: var(--tj-theme-9);
  padding: 40px 30px;
  margin-bottom: 30px;
}

.tj-main-service.tj-main-service-2 ul li {
  display: block;
  margin: 0px 0px;
}

.tj-main-service.tj-main-service-2 ul li a {
  padding: 15px 0px;
  background-color: transparent;
  border-bottom: 1px solid var(--tj-border-11);
}

.tj-main-service.tj-main-service-2 ul li a:hover,
.tj-main-service.tj-main-service-2 ul li a.active {
  background-color: transparent;
  color: var(--tj-theme-primary);
}

.tj-main-service.tj-main-service-2 ul li a .tj-add {
  width: 30px;
  height: 30px;
  border-radius: 500px;
  background-color: var(--tj-common-white);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--tj-theme-primary);
}

.tj-main-service.tj-main-service-2 ul li:last-child a {
  border-bottom: none;
  padding-bottom: 0;
}

.tj-main-service.tj-main-service-2 ul li:first-child a {
  border-bottom: none;
  padding-top: 0;
}

.tj-main-service.tj-downlode ul>li>a span small {
  display: block;
  color: var(--tj-text-body);
  font-family: var(--tj-ff-body);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0 !important;
}

.tj-main-service.tj-downlode ul>li>a:hover {
  background: var(--tj-common-white);
}

.tj-main-service.tj-downlode ul>li>a:hover span {
  color: var(--tj-heading-primary);
}

.tj-main-service.tj-downlode ul>li>a .tj_downlode {
  width: 42px;
  height: 45px;
  background: var(--tj-theme-primary);
  color: var(--tj-common-white);
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tj-main-service .sidebar__title {
  padding-bottom: 12px;
  position: relative;
  margin-bottom: 30px;
}

.tj-main-service .sidebar__title::after {
  position: absolute;
  content: "";
  bottom: 2px;
  width: 58px;
  height: 2px;
  left: 0%;
  background-color: var(--tj-theme-primary);
}

.tj-main-service .tj-tag {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.tj-main-service .tj-tag a {
  margin: 3px 0px;
  position: relative;
  display: inline-block;
  padding: 8px 18px;
  background: var(--tj-common-white);
  color: var(--tj-heading-primary);
  font-size: 15px;
  font-family: var(--tj-ff-heading);
  font-weight: 500;
  line-height: 26px;
  border-radius: 3px;
}

.tj-main-service .tj-tag a:active,
.tj-main-service .tj-tag a:hover {
  background: var(--tj-theme-primary);
  color: var(--tj-common-white);
}

.tj-main-service ul li {
  display: block;
  margin: 10px 0px;
}

.tj-main-service ul li a {
  background-color: var(--tj-common-white);
  color: var(--tj-heading-primary);
  font-size: 17px;
  font-family: var(--tj-ff-heading);
  font-weight: var(--tj-fw-sbold);
  line-height: 26px;
  padding: 15px 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.tj-main-service ul li a:hover,
.tj-main-service ul li a.active {
  background-color: var(--tj-theme-primary);
  color: var(--tj-common-white);
}

.tj-main-service .pattern-top {
  position: absolute;
  top: -1%;
  left: 0%;
}

.tj-main-service .pattern-bottom {
  position: absolute;
  bottom: -1%;
  left: 0%;
}

.service_details_right {
  margin-bottom: 30px;
}

.service_details_left {
  margin-bottom: 30px;
  margin-right: 30px;
}

.tj-dane-right {
  z-index: 1;
  position: relative;
  margin-bottom: 30px;
}

.tj-dane-right:before {
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.tj-dane-right .tj-roof-top {
  height: 155px;
  clip-path: polygon(0 0, 100% 0, 100% 71%, 50% 100%, 0 70%);
  background: var(--tj-common-white);
}

.tj-dane-right .tj-roof-top .tj-roof-content {
  padding: 0px 20px;
  height: 145px;
  background: var(--tj-theme-primary);
  clip-path: polygon(0 0, 100% 0, 100% 71%, 50% 100%, 0 70%);
}

.tj-dane-right .tj-roof-top .tj-roof-content h4 {
  text-align: center;
  padding-top: 30px;
  color: var(--tj-common-white);
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
}

.tj-dane-right .tj-success {
  position: relative;
  margin-top: -50px;
}

.tj-dane-right .tj-success .tj-full {
  width: 100px;
  border-radius: 500px;
  height: 100px;
  border: 5px solid var(--tj-common-white);
  background: var(--tj-theme-primary);
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tj-dane-right .tj-success .tj-full span {
  color: var(--tj-common-white);
  text-align: center;
  font-size: 16px;
  font-family: var(--tj-ff-body);
  font-weight: 600;
  line-height: 26px;
}

.tj-dane-right .content {
  padding: 40px 0px 60px 0px;
}

.tj-dane-right .content .roof__title {
  color: var(--tj-theme-primary);
  text-align: center;
  font-size: 22px;
  margin-bottom: 30px;
}

.tj-dane-right .content .roof__call {
  color: var(--tj-common-white);
  text-align: center;
  font-size: 26px;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 20px;
}

.tj-dane-right .content p {
  color: var(--tj-common-white);
  text-align: center;
  max-width: 324px;
  margin: auto;
}

.tj-dane-right .content .tj-btn-primary {
  margin-top: 35px;
}

/* ----------------------------------
	13. Choose Us CSS
 ------------------------------------ */
.chose-us-left {
  width: 30%;
  height: auto;
}

.chose-us-middle .chose_us-img {
  position: relative;
  left: -5%;
}

.chose-us-middle .chose_us-img img {
  max-width: 110%;
}

.chose-us-right {
  width: 35%;
  padding-left: 50px;
  height: auto;
}

.chose_us-content {
  padding: 40px 0px;
  border-bottom: 1px solid var(--tj-border-4);
}

.chose_us-content .icons {
  margin-bottom: 30px;
}

.chose_us-content .icons i {
  font-size: 60px;
  line-height: 1;
  color: var(--tj-heading-secondary);
}

.chose_us-content .chose_us_item_title {
  font-weight: var(--tj-fw-sbold);
  margin-bottom: 20px;
  font-size: 24px;
}

.chose_us-content:first-child {
  padding-top: 0;
}

.chose_us-content:last-child {
  border-bottom: 0px solid var(--tj-border-4);
  padding-bottom: 0px;
  margin-bottom: 30px;
}

/* ----------------------------------
	14. Feature CSS
 ------------------------------------ */
.feature-wrapper.feature-1 {
  margin-bottom: -30px;
}

.feature-wrapper.feature-1 .content p {
  margin-bottom: 0;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--tj-grey-4);
}

.feature-wrapper .single-feature {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: var(--tj-common-white);
  -webkit-box-shadow: 0px 0px 45px var(--tj-shadow-1);
  box-shadow: 0px 0px 45px var(--tj-shadow-1);
  z-index: 2;
  height: 100%;
}

.feature-wrapper .single-feature .icons {
  position: relative;
  width: 150px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--tj-theme-secondary);
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.feature-wrapper .single-feature .icons i {
  font-size: 50px;
  color: var(--tj-common-white);
}

.feature-wrapper .single-feature .icons .tj-feature-title {
  font-size: 22px;
  font-weight: var(--tj-fw-bold);
  text-align: center;
  color: var(--tj-common-white);
}

.feature-wrapper .single-feature .icons::after {
  z-index: 1;
  position: absolute;
  top: -20px;
  left: 0%;
  width: 100%;
  height: 20px;
  position: absolute;
  clear: both;
  display: block;
  content: "";
  background-color: var(--tj-theme-secondary);
}

.feature-wrapper .single-feature .icons::before {
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  z-index: 1;
  position: absolute;
  top: -20px;
  right: -12%;
  width: 20px;
  height: 20px;
  position: absolute;
  clear: both;
  display: block;
  content: "";
  background: var(--tj-heading-secondary_2);
  clip-path: polygon(0 0, 0% 100%, 100% 100%);
}

.feature-wrapper .single-feature .content {
  padding: 15px 25px;
}

.feature-wrapper .single-feature .content p {
  color: var(--tj-text-body);
}

.feature-wrapper .single-feature .content a {
  font-family: var(--tj-ff-heading);
  font-weight: var(--tj-fw-sbold);
  text-align: center;
  margin-top: 10px;
  color: var(--tj-heading-primary);
}

.feature-wrapper .single-feature .content a:hover {
  color: var(--tj-heading-secondary);
}

.feature-wrapper .single-feature:hover .icons {
  background: var(--tj-heading-secondary);
}

.feature-wrapper .single-feature:hover .icons::after,
.feature-wrapper .single-feature:hover .icons::before {
  background: var(--tj-heading-secondary);
}

.feature-wrapper.feature-4 {
  position: relative;
  margin-top: -150px;
  z-index: 1;
}

.feature-wrapper.feuture-3 .feature-item {
  width: 30.49%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px 10px;
  padding: 20px 10px;
  height: 160px;
  text-align: center;
  background: var(--tj-common-white);
  box-shadow: 0px 0px 45px var(--tj-shadow-1);
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.feature-wrapper.feuture-3 .feature-item h5 {
  margin-bottom: 0;
}

.feature-wrapper.feuture-3 .feature-item .t-icons {
  margin-bottom: 15px;
}

.feature-wrapper.feuture-3 .feature-item .t-icons i {
  line-height: 0;
}

.feature-wrapper.feuture-3 .feature-item:hover {
  background: var(--tj-heading-secondary);
}

.feature-wrapper.feuture-3 .feature-item:hover .t-icons i {
  color: var(--tj-common-white);
}

.feature-wrapper.feuture-3 .feature-item:hover h5 {
  color: var(--tj-common-white);
}

.feature-wrapper.feuture-3 .feature-widget {
  position: relative;
  z-index: 1;
  margin-top: -50px;
}

.feature-wrapper.feuture-3 .t-icons i {
  font-size: 60px;
  color: var(--tj-heading-secondary);
  line-height: 0;
  display: block;
}

.feature-wrapper.feuture-3 h5 {
  font-size: 20px;
}

.feature-wrapper.feature-2 {
  position: relative;
  margin-top: -100px;
}

.feature-wrapper.feature-2 .feature-thumb-2 {
  background: var(--tj-common-white);
  box-shadow: 0px 0px 45px 0px var(--tj-shadow-5);
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
}

.feature-wrapper.feature-2 .single-feature-item {
  position: relative;
  width: 100%;
  height: 510px;
  flex-shrink: 0;
}

.feature-wrapper.feature-2 .single-feature-item .tj-add {
  text-align: center;
  padding: 35px 18px;
  top: 0;
  right: 0%;
  flex-shrink: 0;
  position: absolute;
  width: 184px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--tj-heading-secondary);
}

.feature-wrapper.feature-2 .single-feature-item .tj-add h6 {
  color: var(--tj-common-white);
  font-weight: 600;
  line-height: 35px;
  margin-bottom: 0;
}

.feature-wrapper.feature-2 .tj-feature-right .tj-right-top-thumb {
  padding: 40px;
}

.feature-wrapper.feature-2 .tj-feature-right .tj-right-top-thumb h2 {
  max-width: 490px;
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 15px;
}

.feature-wrapper.feature-2 .tj-feature-right .tj-right-top-thumb h2 span {
  color: var(--tj-heading-secondary);
}

.feature-wrapper.feature-2 .tj-feature-right .tj-right-top-thumb p {
  max-width: 495px;
  margin-bottom: 20px;
}

.feature-wrapper.feature-2 .tj-feature-right .tj-right-top-thumb a {
  color: var(--tj-heading-secondary);
  font-weight: var(--tj-fw-sbold);
}

.feature-wrapper.feature-2 .tj-right-bottom-thumb {
  display: flex;
  width: 100%;
}

.feature-wrapper.feature-2 .tj-right-bottom-thumb .tj-count {
  width: 60%;
  background: var(--tj-heading-secondary);
}

.feature-wrapper.feature-2 .tj-right-bottom-thumb .funfact-feature {
  padding: 37px 36px 36px 36px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.feature-wrapper.feature-2 .tj-right-bottom-thumb .funfact-feature .icons i {
  font-size: 80px;
  color: var(--tj-common-white);
}

.feature-wrapper.feature-2 .tj-right-bottom-thumb .funfact-feature .content h2 {
  color: var(--tj-common-white);
  margin-bottom: 10px;
}

.feature-wrapper.feature-2 .tj-right-bottom-thumb .funfact-feature .content p {
  color: var(--tj-common-white);
  font-size: 22px;
  font-family: var(--tj-ff-heading);
  margin-bottom: 0;
}

.feature-wrapper.feature-2 .tj-right-bottom-thumb .thumb-tj {
  width: 40%;
}

.feature-wrapper.feature-2 .tj-single-feature {
  position: relative;
}

.feature-wrapper.feature-2 .tj-single-feature .feature_pagination {
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature-wrapper.feature-2 .tj-single-feature .feature_pagination .swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  background-color: var(--tj-common-white);
  border-radius: 50%;
  opacity: 1;
}

.feature-wrapper.feature-2 .tj-single-feature .feature_pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--tj-theme-primary);
  width: 25px;
  height: 25px;
}

.tj-feature-4 {
  background: var(--tj-common-white);
  position: relative;
  z-index: 1;
  box-shadow: 0px 0px 45px var(--tj-shadow-1);
  padding: 60px 60px 40px 60px;
}

.tj-feature-4::after {
  clip-path: polygon(0% 0, 100% 0, 75% 100%, 0% 100%);
  position: absolute;
  background: var(--tj-heading-primary);
  content: "";
  clear: both;
  height: 100%;
  width: 45%;
  top: 0%;
  z-index: -2;
  left: -1px;
}

.tj-feature-4::before {
  clip-path: polygon(0% 0, 100% 0, 75% 100%, 0% 100%);
  position: absolute;
  background: var(--tj-heading-secondary);
  content: "";
  clear: both;
  height: 100%;
  width: 45%;
  top: 0%;
  z-index: -3;
  left: 5px;
}

.tj-feature-4 .house-shape {
  position: absolute;
  left: 0%;
  bottom: 0%;
  max-width: 40%;
  z-index: -1;
}

.tj-feature-4 .house-shape img {
  max-width: 90%;
}

.tj-feature-4 .house-shape-2 {
  position: absolute;
  right: 0%;
  bottom: 0%;
  z-index: -1;
}

.tj-feature-4 .tj-feature-content {
  max-width: 365px;
  width: 100%;
}

.tj-feature-4 .tj-feature-content .tj__feature__title {
  color: var(--tj-common-white);
}

.tj-feature-4 .tj-feature-content p {
  color: var(--tj-common-white);
}

/* ----------------------------------
	15. Projects CSS
 ------------------------------------ */
.project-cat-filter {
  margin-bottom: 50px;
}

.project-cat-filter button {
  position: relative;
  background: transparent;
  padding: 12px 25px;
  border: 1px solid var(--tj-text-body);
  margin: 0px 10px;
  transition: all 0.4s ease-in-out;
}

.project-cat-filter button::before {
  content: "";
  clear: both;
  display: block;
  width: 10px;
  height: 13px;
  background: var(--tj-heading-secondary);
  clip-path: polygon(0 0, 0% 100%, 100% 100%);
  position: absolute;
  top: -12px;
  left: 16px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.project-cat-filter button::after {
  content: "";
  clear: both;
  display: block;
  width: 18px;
  height: 13px;
  background: var(--tj-heading-secondary);
  position: absolute;
  top: -12px;
  left: -1px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.project-cat-filter button:hover,
.project-cat-filter button:focus,
.project-cat-filter button:active,
.project-cat-filter button.active {
  background: var(--tj-heading-secondary);
  color: var(--tj-common-white);
  border: 1px solid var(--tj-heading-secondary);
}

.project-cat-filter button:hover::after,
.project-cat-filter button:hover::before,
.project-cat-filter button:focus::after,
.project-cat-filter button:focus::before,
.project-cat-filter button:active::after,
.project-cat-filter button:active::before,
.project-cat-filter button.active::after,
.project-cat-filter button.active::before {
  visibility: visible;
  opacity: 1;
}

/* ----------------------------------
	WORKING SECTION  STYLEING
 ------------------------------------ */
.col .grid {
  /* clear fix */
}

.col .grid::after {
  content: "";
  display: block;
  clear: both;
}

.col .grid .gutter-sizer {
  width: 2%;
}

.col .grid-item {
  width: 29%;
  height: 395px;
  background: var(--tj-grey-7);
  margin-bottom: 2%;
}

.col .grid-item--width2 {
  width: 38%;
}

.col .grid-item--width3 {
  width: 49%;
}

.col .projects .project-single-inner {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.col .projects .project-single-inner::after {
  position: absolute;
  content: "";
  top: 0;
  left: 50%;
  right: 50%;
  height: 100%;
  background-color: var(--tj-common-black);
  opacity: 0;
  transition: all 400ms linear;
}

.col .projects .project-single-inner .project-thumb {
  width: 100%;
  height: 100%;
}

.col .projects .project-single-inner .project-thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: all 2s ease;
}

.col .projects .project-single-inner .project-content {
  position: absolute;
  left: 15px;
  bottom: 15px;
  width: 80%;
  height: 80%;
  z-index: 2;
  display: flex;
  align-items: end;
  padding: 0 0 40px 40px;
}

.col .projects .project-single-inner .project-content::before {
  position: absolute;
  content: "";
  right: 0;
  bottom: 18px;
  width: 0;
  height: 2px;
  background: var(--tj-common-white);
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.col .projects .project-single-inner .project-content::after {
  position: absolute;
  content: "";
  left: 18px;
  top: 0;
  width: 2px;
  height: 0;
  background: var(--tj-common-white);
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.col .projects .project-single-inner .project-content .project-title {
  color: var(--tj-common-white);
  margin: 0;
  display: inline-block;
  opacity: 1;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
  -webkit-transform: translateX(-50px);
  -moz-transform: translateX(-50px);
  -ms-transform: translateX(-50px);
  -o-transform: translateX(-50px);
  transform: translateX(-50px);
  opacity: 0;
}

.col .projects .project-single-inner .project-content .project-title:hover a {
  color: var(--tj-heading-secondary);
}

.col .projects .project-single-inner .external-link {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0);
  -moz-transform: translate(-50%, -50%) scale(0);
  -ms-transform: translate(-50%, -50%) scale(0);
  -o-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
  width: 45px;
  height: 45px;
  background-color: var(--tj-theme-primary);
  z-index: 2;
  text-align: center;
  line-height: 50px;
  font-size: 20px;
  color: var(--tj-common-white);
  border-radius: 50%;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.col .projects .project-single-inner .external-link:hover {
  background-color: var(--tj-theme-secondary);
}

.col .projects .project-single:hover .project-single-inner::after {
  left: 0;
  right: 0;
  opacity: 0.55;
}

.col .projects .project-single:hover .project-single-inner .project-thumb img {
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  -ms-transform: scale(1.15);
  -o-transform: scale(1.15);
  transform: scale(1.15);
}

.col .projects .project-single:hover .project-single-inner .project-content::before {
  width: 100%;
}

.col .projects .project-single:hover .project-single-inner .project-content::after {
  height: 100%;
}

.col .projects .project-single:hover .project-single-inner .project-content .project-title {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
}

.col .projects .project-single:hover .project-single-inner .external-link {
  -webkit-transform: translate(-50%, -50%) scale(1);
  -moz-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  -o-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
}

/* ----------------------------------
	WORKING SECTION  STYLEING
 ------------------------------------ */
.working_process-wrapper .single-process {
  position: relative;
  margin-bottom: 30px;
}

.working_process-wrapper .single-process .tj-icon {
  margin: 0 auto;
  position: relative;
  border-radius: 235px;
  width: 235px;
  height: 235px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: 3px dashed var(--tj-heading-secondary);
}

.working_process-wrapper .single-process .tj-icon .icon {
  font-size: 80px;
  position: relative;
  color: var(--tj-heading-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 200px;
  height: 200px;
  flex-shrink: 0;
  border-radius: 200px;
  z-index: 1;
  transition: all 0.4s ease-in-out;
  background: var(--tj-common-white);
  box-shadow: 0px 0px 55px 0px var(--tj-shadow-5);
}

.working_process-wrapper .single-process .tj-icon .icon::after {
  z-index: -1;
  border-radius: 500px;
  content: "";
  clear: both;
  width: 0%;
  height: 0%;
  position: absolute;
  top: 50%;
  left: 50%;
  background: var(--tj-heading-secondary);
  transition: all 0.4s ease-in-out;
}

.working_process-wrapper .single-process .tj-icon .number {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  position: absolute;
  z-index: 1;
  top: 0%;
  left: 0%;
  border-radius: 60px;
  border: 1px solid var(--tj-heading-secondary);
  background: var(--tj-common-white);
}

.working_process-wrapper .single-process .tj-icon .number span {
  color: var(--tj-heading-secondary);
  text-align: center;
  font-size: 20px;
  font-family: var(--tj-tt-p);
  font-weight: var(--tj-fw-bold);
  line-height: 30px;
}

.working_process-wrapper .single-process .tj-content {
  margin-top: 30px;
}

.working_process-wrapper .single-process .tj-content .process__title {
  font-weight: var(--tj-fw-bold);
  margin-bottom: 20px;
  font-size: 24px;
}

.working_process-wrapper .single-process .tj-content p {
  max-width: 316px;
  text-align: center;
  margin: 0 auto;
}

.working_process-wrapper .single-process:hover .icon {
  background: transparent;
}

.working_process-wrapper .single-process:hover .icon i {
  color: var(--tj-common-white);
  transform: rotateY(180deg);
}

.working_process-wrapper .single-process:hover .icon::after {
  top: -5%;
  left: -5%;
  width: 110%;
  height: 110%;
}

.working_process-wrapper .col_working .single-process::before {
  content: "";
  clear: both;
  position: absolute;
  top: 100px;
  width: 150px;
  right: -15%;
  max-width: 100%;
  /* background-image: url(../img/svg/process-arrow.svg); */
  background-position: right top;
  background-repeat: no-repeat;
  height: 50px;
  line-height: 0;
}

.working_process-wrapper .col_working:last-child .single-process::before {
  display: none;
}

/* ----------------------------------
	PROJECT DETAILS SECTION  STYLEING
 ------------------------------------ */
.tj-project-details-left {
  margin-bottom: 30px;
}

.tj-project-details-right {
  margin-bottom: 30px;
}

.tj-project-details-right .section-title {
  margin-bottom: 30px;
}

.tj-project-details-right .section-title h3 {
  font-size: 35px;
  font-weight: var(--tj-fw-bold);
  line-height: 45px;
  margin-bottom: 20px;
}

.tj-project-content {
  margin-bottom: 50px;
}

.tj-project-content p {
  border-bottom: 1px solid var(--tj-border-12);
  display: flex;
  align-items: start;
}

.tj-project-content p:last-child {
  border-bottom: 0px solid var(--tj-border-12);
}

.tj-project-content p {
  color: var(--tj-text-body);
  font-size: 18px;
  font-weight: 500;
  padding: 15px 0px;
  margin-bottom: 0;
}

.tj-project-content p a {
  color: var(--tj-text-body);
}

.tj-project-content .tj-star i {
  color: var(--tj-theme-7);
}

.tj-project-content a {
  text-transform: lowercase;
}

.tj-project-content a:hover {
  text-decoration: underline;
  color: var(--tj-heading-secondary);
}

.tj-project-content span {
  display: inline-block;
  height: auto;
  width: 164px;
  color: var(--tj-heading-primary);
  font-size: 18px;
  font-family: var(--tj-ff-body);
  font-weight: 500;
  line-height: 28px;
}

.tj-project-content strong {
  color: var(--tj-heading-primary);
  font-size: 18px;
  font-family: var(--tj-ff-body);
  font-weight: 500;
  line-height: 28px;
  display: inline-block;
  margin-right: 10px;
}

.tj-project-content .tj-rating {
  display: inline-block;
}

.tj-project-content .tj-rating i {
  display: inline-block;
}

/* ----------------------------------
	WORKING PROJECT SECTION  STYLEING
 ------------------------------------ */
.tj-working-project {
  height: 400px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: auto;
  z-index: 1;
  margin-bottom: 30px;
}

.tj-working-project a {
  width: 50px;
  height: 50px;
  border-radius: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--tj-heading-secondary);
  color: var(--tj-common-white);
  font-size: 20px;
  transform: translateY(-200px);
  -webkit-transition: all 1s ease-out 0s;
  -moz-transition: all 1s ease-out 0s;
  -ms-transition: all 1s ease-out 0s;
  -o-transition: all 1s ease-out 0s;
  transition: all 1s ease-out 0s;
  visibility: hidden;
  opacity: 0;
}

.tj-working-project::after {
  position: absolute;
  content: "";
  clear: both;
  top: 0%;
  left: 0;
  width: 0%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: -1;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.tj-working-project:hover a {
  transform: translateY(0px);
  visibility: visible;
  opacity: 1;
}

.tj-working-project:hover::after {
  width: 100%;
  left: auto;
  right: 0;
}

/* ----------------------------------
	16. Testimonial CSS
 ------------------------------------ */
.testimonial-wrapper.testimonial-3 .slider-item {
  padding-top: 30px;
  padding-bottom: 50px;
}

.testimonial-wrapper.testimonial-3 .single-testimonial {
  background: var(--tj-common-white);
  position: relative;
  box-shadow: 0px 0px 30px var(--tj-shadow-1);
  padding: 35px;
}

.testimonial-wrapper.testimonial-3 .single-testimonial:hover .quite {
  background: var(--tj-heading-secondary);
}

.testimonial-wrapper.testimonial-3 .single-testimonial:hover .quite::before {
  background: var(--tj-heading-secondary);
}

.testimonial-wrapper.testimonial-3 .single-testimonial:hover .testimonial-author {
  border: 1px solid var(--tj-heading-secondary);
}

.testimonial-wrapper.testimonial-3 .single-testimonial:hover .testimonial-author span {
  color: var(--tj-heading-secondary);
}

.testimonial-wrapper.testimonial-3 .single-testimonial .quite {
  position: absolute;
  top: -20px;
  right: 4%;
  width: 75px;
  height: 75px;
  background: var(--tj-heading-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.testimonial-wrapper.testimonial-3 .single-testimonial .quite svg {
  height: 45px;
  width: 45px;
}

.testimonial-wrapper.testimonial-3 .single-testimonial .quite::before {
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  content: "";
  clear: both;
  display: block;
  width: 17px;
  height: 24px;
  background: var(--tj-theme-6);
  clip-path: polygon(0 0, 0% 100%, 100% 100%);
  position: absolute;
  top: 0px;
  right: -17px;
  transition: all 0.4s ease-in-out;
}

.testimonial-wrapper.testimonial-3 .testimonial-head {
  max-width: 270px;
  width: 100%;
}

.testimonial-wrapper.testimonial-3 .testimonial-head h5 {
  font-weight: var(--tj-fw-sbold);
  font-size: 24px;
  line-height: 28px;
  color: var(--tj-heading-primary);
  margin-bottom: 10px;
}

.testimonial-wrapper.testimonial-3 .testimonial-head ul {
  margin-bottom: 20px;
}

.testimonial-wrapper.testimonial-3 .testimonial-head ul>li i {
  font-size: 18px;
  color: var(--tj-theme-7);
}

.testimonial-wrapper.testimonial-3 p {
  margin-bottom: 20px;
}

.testimonial-wrapper.testimonial-3 .testimonial-author {
  margin-bottom: -80px;
  background: var(--tj-common-white);
  padding: 10px;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  border: 1px solid var(--tj-text-body);
  border-radius: 500px;
}

.testimonial-wrapper.testimonial-3 .testimonial-author .author-contents {
  display: flex;
  align-items: center;
  gap: 20px;
}

.testimonial-wrapper.testimonial-3 .testimonial-author .author-img {
  height: 65px;
  width: 65px;
  border-radius: 500px;
  overflow: hidden;
}

.testimonial-wrapper.testimonial-3 .testimonial-author .author-img .author {
  height: 65px;
  width: 65px;
  border-radius: 500px;
}

.testimonial-wrapper.testimonial-3 .testimonial-author .author-name h6 {
  font-size: 20px;
  margin-bottom: 0;
}

.testimonial-wrapper.testimonial-3 .testimonial-author .author-name span {
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  font-family: var(--tj-ff-body);
  font-style: normal;
  font-weight: var(--tj-fw-regular);
  font-size: 16px;
  line-height: 26px;
  color: var(--tj-text-body);
}

.testimonial-wrapper.testimonial-3 .slider-test {
  margin-bottom: 30px;
}

.testimonial-wrapper.testimonial-3 .slider-test .owl-dots {
  margin-top: 40px;
  text-align: center;
}

.testimonial-wrapper.testimonial-3 .slider-test .owl-dots .owl-dot {
  margin: 0 5px;
}

.testimonial-wrapper.testimonial-3 .slider-test .owl-dots .owl-dot span {
  display: inline-block;
  width: 25px;
  height: 25px;
  border: 1px solid var(--tj-theme-primary);
  border-radius: 50%;
  position: relative;
}

.testimonial-wrapper.testimonial-3 .slider-test .owl-dots .owl-dot span::after {
  position: absolute;
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: transparent;
}

.testimonial-wrapper.testimonial-3 .slider-test .owl-dots .owl-dot.active span::after {
  background-color: var(--tj-theme-primary);
}

.testimonial-wrapper.testimonial-4 .quote__icon {
  margin-bottom: 30px;
}

.testimonial-wrapper.testimonial-4 .quote__icon i {
  font-size: 40px;
  color: var(--tj-heading-secondary);
}

.testimonial-wrapper.testimonial-4 .quote {
  color: var(--tj-heading-primary);
  font-size: 32px;
  font-weight: var(--tj-fw-medium);
  line-height: 42px;
  margin-bottom: 20px;
}

.testimonial-wrapper.testimonial-4 .autor_name {
  color: var(--tj-text-2);
  font-size: 16px;
  font-family: var(--tj-ff-body);
  font-weight: var(--tj-fw-bold);
}

.testimonial-wrapper.testimonial-4 .autor_name .autor__reg {
  font-weight: var(--tj-fw-light);
}

.testimonial-wrapper.testimonial-4 .tj-slider {
  margin-bottom: 30px;
  padding-left: 150px;
  padding-right: 20px;
}

.testimonial-wrapper.testimonial-4 .tj-author {
  margin-bottom: 30px;
}

.single-client {
  padding: 0px 20px;
}

.client-say-widget {
  margin-bottom: 30px;
}

.client-say-content:hover .client-content {
  border: 1px solid var(--tj-heading-secondary);
}

.client-say-content:hover .client-thumb {
  background: var(--tj-heading-secondary);
}

.client-say-content:hover .client-name::after {
  background: var(--tj-heading-secondary);
}

.client-say {
  position: relative;
  margin-bottom: -30px;
  left: 35px;
}

.client-say .client-img {
  width: 80px;
  height: 80px;
  border-radius: 500px;
}

.client-say .quite {
  position: absolute;
  bottom: 20%;
  right: -20%;
  width: 34px;
  height: 34px;
  border-radius: 500px;
  background: var(--tj-heading-secondary);
  border: 3px solid var(--tj-common-white);
  display: flex;
  justify-content: center;
  align-items: center;
}

.client-say .quite i {
  color: var(--tj-common-white);
}

.client-content {
  border: 1px solid var(--tj-common-white);
  padding: 60px 35px 70px 35px;
  background: var(--tj-common-white);
  box-shadow: 0px 0px 20px var(--tj-shadow-1);
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.client-thumb {
  position: relative;
  width: 280px;
  padding: 10px 30px;
  height: auto;
  background-color: var(--tj-theme-secondary);
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  clip-path: polygon(0 0, 80% 0, 100% 100%, 0% 100%);
}

.client-thumb .author__name {
  font-size: 20px;
  color: var(--tj-common-white);
  margin-bottom: 5px;
}

.client-thumb p {
  color: var(--tj-common-white);
  margin-bottom: 0;
}

.client-name {
  margin-top: -60px;
}

.client-name::after {
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  content: "";
  clear: both;
  display: block;
  bottom: 1px;
  z-index: -1;
  left: 245px;
  width: 35px;
  height: 35px;
  background: var(--tj-theme-8);
  position: absolute;
}

/* ----------------------------------
	17. Teams CSS
 ------------------------------------ */
.team-wrapper .team__inner {
  border-bottom: 1px solid var(--tj-border-4);
  padding-bottom: 40px;
}

.team-wrapper.team-3 .single-team-member {
  overflow: hidden;
  position: relative;
  margin-bottom: 30px;
}

.team-wrapper.team-3 .single-team-member .team_image {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.team-wrapper.team-3 .single-team-member .team_image img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.team-wrapper.team-3 .single-team-member:hover .team-shape svg {
  fill: var(--tj-heading-secondary);
}

.team-wrapper.team-3 .single-team-member:hover .icon img {
  transform: rotateY(180deg);
}

.team-wrapper.team-3 .single-thumb {
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 100%;
  display: flex;
  z-index: 1;
  padding: 0px 30px;
  justify-content: space-between;
  align-items: center;
}

.team-wrapper.team-3 .single-thumb h5 {
  font-weight: var(--tj-fw-sbold);
  font-size: 24px;
  line-height: 32px;
  color: var(--tj-common-white);
  margin-bottom: 5px;
}

.team-wrapper.team-3 .single-thumb p {
  color: var(--tj-common-white);
  margin-bottom: 0;
  text-transform: uppercase;
}

.team-wrapper.team-3 .social-link-widget .share-linke {
  position: absolute;
  z-index: 1;
  bottom: 50px;
  right: 40px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.team-wrapper.team-3 .social-link-widget .share-linke a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 500px;
  background: var(--tj-common-white);
  color: var(--tj-heading-secondary);
  transform: scale(1, 1);
  font-size: 22px;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.team-wrapper.team-3 .social-link-widget:hover .share-linke i {
  transform: rotateY(-180deg);
}

.team-wrapper.team-3 .social-link-widget:hover .social-link {
  right: 40px;
}

.team-wrapper.team-3 .social-link-widget .social-link {
  -webkit-transition: all 0.6s ease-out 0s;
  -moz-transition: all 0.6s ease-out 0s;
  -ms-transition: all 0.6s ease-out 0s;
  -o-transition: all 0.6s ease-out 0s;
  transition: all 0.6s ease-out 0s;
  position: absolute;
  right: -45px;
  bottom: 80px;
  padding-bottom: 50px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.team-wrapper.team-3 .social-link-widget .social-link a {
  background-color: var(--tj-common-white);
  color: var(--tj-heading-secondary);
  border: 1px solid var(--tj-common-white);
  font-size: 20px;
  height: 40px;
  width: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.team-wrapper.team-3 .social-link-widget .social-link a:hover {
  background: var(--tj-heading-secondary);
  color: var(--tj-common-white);
}

.team-wrapper.team-3 .team-shape {
  position: absolute;
  bottom: 0px;
  left: 0;
}

.team-wrapper.team-3 .team-shape svg {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  fill: var(--tj-heading-primary);
}

.team-wrapper.team-2 {
  padding-bottom: 50px;
}

.team-wrapper.team-2 .single-team-2 {
  background: var(--tj-common-white);
  border-radius: 15px;
  box-shadow: 0px 0px 45px 0px var(--tj-shadow-5);
  overflow: hidden;
  position: relative;
  z-index: 1;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.team-wrapper.team-2 .single-team-2 .team-img {
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  height: 380px;
  width: 100%;
  overflow: hidden;
}

.team-wrapper.team-2 .single-team-2 .team-img img {
  object-fit: cover;
  height: 380px;
  width: 100%;
}

.team-wrapper.team-2 .single-team-2 .content {
  padding: 25px 20px 30px 20px;
  text-align: center;
}

.team-wrapper.team-2 .single-team-2 .content .team_man-name {
  margin-bottom: 5px;
  font-size: 24px;
}

.team-wrapper.team-2 .single-team-2 .content span {
  color: var(--tj-text-body);
  font-size: 16px;
  font-family: var(--tj-ff-body);
  font-weight: 500;
  line-height: 26px;
}

.team-wrapper.team-2 .team-link {
  -webkit-transition: all 0.6s ease-out 0s;
  -moz-transition: all 0.6s ease-out 0s;
  -ms-transition: all 0.6s ease-out 0s;
  -o-transition: all 0.6s ease-out 0s;
  transition: all 0.6s ease-out 0s;
  position: absolute;
  z-index: -1;
  top: 10px;
  right: 0;
  visibility: hidden;
  opacity: 1;
  transform: translateX(-150px);
  width: 55px;
  height: auto;
  border-radius: 0px 7px 7px 0px;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  background: var(--tj-heading-secondary);
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  padding: 10px 0px;
}

.team-wrapper.team-2 .team-link a {
  position: relative;
  z-index: 1;
  color: var(--tj-common-white);
  font-size: 18px;
  padding: 10px 0px;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.team-wrapper.team-2 .team-link a:hover {
  transform: scale(1.2);
}

.team-wrapper.team-2 .single-team-widget {
  margin-bottom: 30px;
  position: relative;
}

.team-wrapper.team-2 .single-team-widget:hover .single-team-2 {
  margin-right: 50px;
}

.team-wrapper.team-2 .single-team-widget:hover .team-link {
  z-index: 1;
  visibility: visible;
  opacity: 1;
  transform: translateX(5px);
}

.team-wrapper.team-2 .single-team-widget:hover .content h4 a {
  color: var(--tj-heading-secondary);
}

.team-wrapper.team-5 .tj_single_team {
  margin-top: 30px;
  background: var(--tj-common-white);
  box-shadow: 0px 0px 35px 0px var(--tj-shadow-5);
}

.team-wrapper.team-5 .tj_single_team .tj__team_head {
  height: 150px;
  flex-shrink: 0;
  clip-path: polygon(100% 0, 100% 70%, 50% 100%, 0 70%, 0 0);
  background-color: var(--tj-theme-secondary);
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.team-wrapper.team-5 .tj_single_team .tj-body {
  padding: 20px;
}

.team-wrapper.team-5 .tj_single_team .tj-body .team_thumb {
  position: relative;
  margin-top: -120px;
}

.team-wrapper.team-5 .tj_single_team .tj-body .man__team {
  width: 260px;
  height: 260px;
  border-radius: 500px;
  overflow: hidden;
  flex-shrink: 0;
  margin: 0 auto;
}

.team-wrapper.team-5 .tj_single_team .tj-body .man__team img {
  object-fit: cover;
}

.team-wrapper.team-5 .tj_single_team .tj-body .team__name {
  margin-top: 30px;
  text-align: center;
}

.team-wrapper.team-5 .tj_single_team .tj-body .team__name span {
  color: var(--tj-heading-secondary);
  text-align: center;
  font-family: var(--tj-ff-body);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.team-wrapper.team-5 .tj_single_team .tj-body .social-list {
  margin-top: 20px;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
}

.team-wrapper.team-5 .tj_single_team .tj-body .social-list a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  border-radius: 500px;
  height: 45px;
  border: 1px solid var(--tj-text-body);
  font-size: 17px;
}

.team-wrapper.team-5 .tj_single_team .tj-body .social-list a:hover {
  background: var(--tj-heading-secondary);
  border: 1px solid var(--tj-heading-secondary);
  color: var(--tj-common-white);
}

.team-wrapper.team-5 .tj_single_team:hover .tj__team_head {
  background-color: var(--tj-theme-primary);
}

.team-wrapper.team-5 .tj_single_team:hover .tj-body .team__name h4 a {
  color: var(--tj-heading-secondary);
}

.single-team {
  position: relative;
  overflow: hidden;
  padding: 0px 0px 20px 30px;
  margin-bottom: 30px;
}

.single-team .team-img {
  max-width: 326px;
  width: 100%;
}

.single-team img {
  object-fit: cover;
}

.single-team::after {
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  display: block;
  clear: both;
  content: "";
  position: absolute;
  left: 30%;
  top: -19%;
  width: 130px;
  z-index: -1;
  height: 139%;
  background: var(--tj-shadow-6);
  transform: rotate(-57.53deg);
}

.single-team .team-link {
  width: 55px;
  height: auto;
  margin-bottom: -220px;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  background: var(--tj-heading-secondary);
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  padding: 10px 0px;
}

.single-team .team-link a {
  color: var(--tj-common-white);
  font-size: 18px;
  padding: 10px 0px;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.single-team .team-link a:hover {
  transform: scale(1.2);
}

.single-team:hover .team-link {
  margin-bottom: 0px;
}

.single-team:hover::after {
  background: var(--tj-shadow-7);
}

.single-team .team-name {
  margin-top: 20px;
  font-weight: var(--tj-fw-sbold);
  margin-bottom: 5px;
  font-size: 24px;
}

.single-team .team-name:hover a {
  color: var(--tj-heading-secondary);
}

.single-team span {
  font-family: var(--tj-ff-body);
  font-style: normal;
  font-weight: var(--tj-fw-medium);
  font-size: 16px;
  line-height: 26px;
  color: var(--tj-text-body);
}

/* ----------------------------------
	TEAM DETAILS SECTION STYLEING
 ------------------------------------ */
.team__details__inner_top {
  margin-bottom: 30px;
}

.tj__high__level {
  padding-top: 60px;
  border-top: 1px solid var(--tj-grey-4);
}

.tj-team-details-left {
  max-width: 525px;
  margin-left: auto;
  margin-right: 40px;
  margin-bottom: 30px;
}

.tj-team-details-left img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.tj-team-details-right {
  margin-bottom: 30px;
}

.tj-team-details-right .section-title {
  margin-bottom: 20px;
}

.tj-team-details-right .section-title p {
  font-size: 18px;
}

.tj-team-details-right .shear-link {
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.tj-team-details-right .shear-link a {
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  border-radius: 500px;
  border: 1px solid var(--tj-heading-secondary);
  color: var(--tj-heading-secondary);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.tj-team-details-right .shear-link a i {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.tj-team-details-right .shear-link a:hover {
  background: var(--tj-heading-secondary);
  color: var(--tj-common-white);
}

.tj-team-details-right .team_service__dec {
  max-width: 510px;
  width: 100%;
  margin-bottom: 20px;
}

.tj-team-details-right .tj-team-content {
  margin-bottom: 30px;
}

.tj-team-details-right .tj-team-content .team__service__title {
  max-width: 370px;
  margin-bottom: 30px;
}

.tj-team-details-right .tj-team-content .tj-help ul {
  columns: 2;
  max-width: 500px;
}

.tj-team-details-right .tj-team-content .tj-help ul li {
  color: var(--tj-text-body);
  font-size: 17px;
  font-family: var(--tj-ff-body);
  font-weight: 500;
  position: relative;
  padding-left: 25px;
  line-height: 28px;
  margin-bottom: 15px;
}

.tj-team-details-right .tj-team-content .tj-help ul li::before {
  content: "\f10a";
  clear: both;
  font-weight: 900;
  font-family: "flaticon_roofing_figma_file";
  position: absolute;
  font-size: 17px;
  line-height: 28px;
  color: var(--tj-heading-secondary);
  left: 0;
  top: 0;
}

.tj-team-details-right .tj-team-content .tj-help ul li:last-child {
  margin-bottom: 0;
}

.popular-causes__progress {
  margin-bottom: 20px;
}

.popular-causes__progress .bar {
  position: relative;
  width: 100%;
  height: 7px;
  background: var(--tj-border-14);
  border-radius: 3px;
  margin-bottom: 15px;
}

.popular-causes__progress .bar-inner {
  position: relative;
  display: block;
  width: 0px;
  height: 7px;
  margin: 10px 0px;
  border-radius: 3px;
  background: var(--tj-heading-secondary);
}

.popular-causes__progress .popular-causes__goals p {
  color: var(--tj-heading-primary);
  font-family: var(--tj-ff-heading);
  font-weight: var(--tj-fw-sbold);
  margin-bottom: 0;
}

.tj-single-content {
  margin-bottom: 30px;
}

.tj-single-content .section-title h3 {
  font-size: 30px;
}

.tj-single-content .section-title p {
  max-width: 490px;
  margin-bottom: 0;
}

.tj-single-img {
  margin-bottom: 30px;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}

.tj-single-img img {
  display: inline-block;
  max-width: 150px;
  filter: grayscale(100%);
  transition: all 0.4s ease-in-out;
}

.tj-single-img img:hover {
  filter: grayscale(0%);
}

/* ----------------------------------
	18. Blog CSS
 ------------------------------------ */
.single-blog {
  background: var(--tj-common-white);
  box-shadow: 0px 0px 45px 0px var(--tj-shadow-5);
  margin-bottom: 30px;
}

.single-blog.single-blog-2 {
  background: var(--tj-common-white);
}

.single-blog.single-blog-2 .blog__meta {
  padding: 0px 0px 20px 0px;
  border-bottom: 0px solid var(--tj-common-white);
}

.single-blog.single-blog-2 .tj__blog_date {
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  clip-path: polygon(50% 0%, 100% 30%, 100% 100%, 0 98%, 0 30%);
  position: absolute;
  bottom: -1px;
  z-index: 1;
  right: 20px;
  background: var(--tj-theme-secondary);
  height: 75px;
  width: 80px;
}

.single-blog.single-blog-2 .tj__blog_date p {
  padding-top: 25px;
  color: var(--tj-common-white);
  text-align: center;
}

.single-blog.single-blog-2 .blog-content {
  padding: 30px 20px;
  display: block;
}

.single-blog.single-blog-2 .blog-content .blog__inner {
  position: relative;
  z-index: 1;
  width: 100%;
  border-right: none;
}

.single-blog.single-blog-2 .blog-content .blog-date {
  border-bottom: none;
  padding: 0px 0px 20px 0px;
}

.single-blog.single-blog-2 .blog-content .content {
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--tj-border-4);
}

.single-blog.single-blog-2 .blog-content .content h4 {
  margin-bottom: 25px;
}

.single-blog.single-blog-2 .blog-content .content h4:hover a {
  color: var(--tj-heading-secondary);
}

.single-blog.single-blog-2 .blog-content .blog-right-btn {
  position: relative;
  top: 0%;
  left: 0%;
  right: 0;
  bottom: 0;
}

.single-blog.single-blog-2 .blog-content .tj-blog-btn {
  margin-top: 20px;
}

.single-blog.single-blog-2 .blog-content .tj-blog-btn a {
  color: var(--tj-heading-primary);
}

.single-blog.single-blog-2 .blog-content .tj-blog-btn a i {
  margin-left: 5px;
  color: var(--tj-heading-secondary);
}

.single-blog.single-blog-2 .blog-content .tj-blog-btn a:hover {
  color: var(--tj-heading-secondary);
}

.single-blog.single-blog-2 .blog-content .tj-blog-btn a:hover i {
  color: var(--tj-heading-primary);
}

.single-blog.single-blog-2 .blog-content::after {
  display: none;
}

.single-blog.single-blog-2:hover .tj__blog_date {
  background: var(--tj-theme-primary);
}

.single-blog:hover .blog-img img {
  transform: scale(1.1);
}

.single-blog:hover .blog-img::after {
  left: 0;
  right: 0;
  opacity: 1;
}

.blog-img {
  height: 350px;
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.blog-img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transform: scale(1);
  transition: all 1s linear;
}

.blog-img::after {
  background: var(--tj-grey-2);
  bottom: 0;
  content: "";
  left: 50%;
  position: absolute;
  right: 50%;
  top: 0;
  opacity: 0;
  height: 100%;
  pointer-events: none;
  transition: all 0.5s linear;
}

.blog-one__content {
  position: relative;
  z-index: 1;
}

.blog-one__content:hover .blog-btn {
  color: var(--tj-common-white);
}

.blog-one__content:hover::after {
  height: 100%;
}

.blog-one__content::after {
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  content: "";
  clear: both;
  position: absolute;
  bottom: 0%;
  z-index: 0;
  left: 0%;
  width: 100%;
  height: 0%;
  background-color: var(--tj-theme-primary);
}

.single-blog__inner.single-blog__inner-2 {
  box-shadow: 0px 0px 45px 0px var(--tj-shadow-5);
  display: flex;
  margin-bottom: 30px;
}

.single-blog__inner .blog-img {
  width: 350px;
  height: 400px;
  overflow: hidden;
}

.single-blog__inner .blog-img img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.single-blog__inner .blog-img .blog-date {
  position: absolute;
  bottom: 0%;
  right: 0%;
}

.single-blog__inner .blog-img .blog-date p {
  font-size: 14px;
  font-weight: 500;
  padding: 8px 15px;
  display: inline-block;
  background: var(--tj-heading-primary);
  color: var(--tj-common-white);
  margin-bottom: 0;
}

.single-blog__inner .blog-img .blog-date p:first-child {
  position: relative;
  margin-right: -5px;
  background: var(--tj-heading-secondary);
}

.single-blog__inner .blog-img .blog-date p:last-child {
  padding: 8px 25px;
}

.single-blog__inner .blog-content-thumb {
  padding: 30px 70px 30px 30px;
}

.single-blog__inner .blog-content-thumb .blog__author {
  font-size: 16px;
  color: var(--tj-text-body);
  font-family: var(--tj-ff-body);
  font-weight: 500;
}

.single-blog__inner .blog-content-thumb .blog-user {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 15px;
  gap: 20px;
}

.single-blog__inner .blog-content-thumb .blog-user .admin {
  width: 45px;
  height: 45px;
  border-radius: 500px;
  overflow: hidden;
}

.single-blog__inner .blog-content-thumb .blog-user .admin img {
  object-fit: cover;
}

.single-blog__inner .blog-content-thumb .content .blog_title {
  font-weight: 700;
  line-height: 34px;
  font-size: 24px;
  font-family: var(--tj-ff-heading);
  margin-bottom: 25px;
}

.single-blog__inner .blog-content-thumb .content .blog_title:hover a {
  color: var(--tj-heading-secondary);
}

.single-blog__inner .blog-content-thumb .blog-comment {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--tj-theme-9);
}

.single-blog__inner .blog-content-thumb .blog-comment a {
  padding: 17px 25px 17px 50px;
  clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 20% 50%, 0% 0%);
}

.single-blog__inner .blog-content-thumb .blog-comment p {
  padding-left: 20px;
  color: var(--tj-text-body);
  padding-bottom: 0;
  margin-bottom: 0;
}

.single-blog__inner .blog-content-thumb .blog-comment a {
  color: var(--tj-common-white);
  line-height: 20px;
  background-color: var(--tj-theme-secondary);
}

.single-blog__inner .blog-content-thumb .blog-comment a:hover {
  background-color: var(--tj-theme-primary);
}

.blog__inner {
  background: var(--tj-common-white);
  width: 83%;
  border-right: 1px solid var(--tj-border-4);
  position: relative;
  z-index: 1;
}

.blog__inner .content {
  padding: 25px;
}

.blog__inner .content .blog_title {
  font-size: 24px;
}

.blog__inner .content .blog_title a:hover {
  color: var(--tj-heading-secondary);
}

.blog__inner .content .blog-title {
  font-weight: var(--tj-fw-bold);
  color: var(--tj-heading-primary);
  margin-bottom: 15px;
  font-size: 19px;
}

.blog__inner .content .blog-title a:hover {
  color: var(--tj-heading-secondary);
}

.blog__inner.blog__inner-2 .content {
  padding-bottom: 15px;
  padding: 0;
}

.blog__meta {
  padding: 25px;
  display: flex;
  gap: 30px;
  align-items: center;
  border-bottom: 1px solid var(--tj-border-4);
}

.blog__meta a,
.blog__meta p {
  display: flex;
  align-items: center;
  color: var(--tj-text-body);
  margin-bottom: 0 !important;
  font-weight: var(--tj-fw-medium);
}

.blog__meta a i,
.blog__meta p i {
  color: var(--tj-heading-secondary);
  font-size: 18px;
  margin-right: 5px;
}

.blog__meta a:hover {
  color: var(--tj-heading-secondary);
}

.blog-btn {
  position: absolute;
  width: 300px;
  padding: 20px 50px;
  bottom: 35%;
  left: 91%;
  z-index: 1;
  font-weight: var(--tj-fw-medium);
  transform: rotate(-90deg) translateX(-50%);
  transform-origin: left;
  font-size: 18px;
  line-height: 26px;
  color: var(--tj-heading-secondary);
}

.blog-btn i {
  margin: 7px;
  transform: rotate(90deg);
}

/* ----------------------------------
	BLOG DETAILS SECTION  STYLEING
 ------------------------------------ */
.blog__details__left {
  padding-right: 20px;
}

.blog__details__left .blog__meta {
  padding: 30px 0px 20px 0px;
}

.blog__thumb-padding {
  margin-bottom: 40px;
}

.blog__stander {
  margin-bottom: 30px;
}

.tj-blog-inner .tj-thumb_img {
  height: 470px;
  width: 100%;
}

.tj-blog-inner .tj-thumb_img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.tj-blog-inner .tj-thumb_img.tj__video {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tj-blog-inner .tj-thumb_img.tj__video .play-video:hover {
  background: var(--tj-common-white);
  color: var(--tj-theme-primary);
}

.tj-blog-inner .tj-thumb_img:hover img {
  transform: scale(1.15);
}

.tj-blog-inner span a {
  color: var(--tj-text-body);
  font-size: 17px;
  font-family: var(--tj-ff-body);
  font-weight: 400;
}

.tj-blog-inner span a:hover {
  color: var(--tj-heading-secondary);
}

.tj-blog-inner .blog__meta {
  border-bottom: 0px solid var(--tj-common-white);
  padding-left: 0;
}

.tj-blog-inner .blog-details__title {
  font-size: 35px;
  font-weight: var(--tj-fw-sbold);
  margin-bottom: 20px;
}

.tj-blog-inner .blog-details__title a:hover {
  color: var(--tj-heading-secondary);
}

.tj-blog-inner .blog-details__text-1:last-child {
  margin-bottom: 45px;
}

.tj-blog-inner .blog-stander__text {
  margin-bottom: 30px;
}

.tj-search-box .form-control {
  border: 1px solid var(--tj-common-white);
}

.tj-main-service .sidebar__title,
.tj-search-box .sidebar__title {
  font-size: 24px;
}

.tj-main-service form,
.tj-search-box form {
  position: relative;
}

.tj-main-service input,
.tj-search-box input {
  border: 0px solid var(--tj-heading-secondary);
  border: none;
  position: relative;
  height: 55px;
  border-radius: 4px;
}

.tj-main-service input::placeholder,
.tj-search-box input::placeholder {
  color: var(--tj-heading-primary);
  font-size: 16px;
  font-family: var(--tj-ff-heading);
  font-style: normal;
  font-weight: var(--tj-fw-sbold);
  line-height: 26px;
}

.tj-main-service .form-control:focus,
.tj-search-box .form-control:focus {
  outline: none;
  border-color: var(--tj-heading-secondary);
  box-shadow: 0 0 0;
}

.tj-main-service button,
.tj-search-box button {
  top: 0%;
  right: 0%;
  position: absolute;
  padding: 13px 20px 15px 20px;
  border-radius: 0px 4px 4px 0px;
  background: var(--tj-heading-secondary);
  font-size: 18px;
  color: var(--tj-common-white);
}

.tj-single-common {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tj-single-common {
    flex-wrap: wrap;
  }
}

.tj-single-common.tj-single-common-2 {
  padding: 35px 25px;
  margin-bottom: 0px;
  background-color: var(--tj-theme-9);
  flex: 0 0 48%;
}

.tj-single-common.tj-single-common-2 .tj-img {
  width: 85px;
  height: 85px;
  border-radius: 3px;
}

.tj-single-common.tj-single-common-2 .tj-content .post_pagination_nav {
  text-transform: uppercase;
  display: block;
  margin-bottom: 8px;
  color: var(--tj-theme-primary);
}

.tj-single-common.tj-single-common-2 .tj-content .post_pagination_nav i {
  margin-right: 10px;
}

.tj-single-common.tj-single-common-2 .tj-content .title {
  margin-bottom: 0;
}

.tj-single-common.tj-single-common-2.next_post .tj-content {
  text-align: right;
}

.tj-single-common.tj-single-common-2.next_post .tj-content .post_pagination_nav i {
  margin-right: 0px;
  margin-left: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px) {
  .tj-single-common.tj-single-common-2 {
    max-width: 400px;
  }
}

@media (max-width: 575px) {
  .tj-single-common.tj-single-common-2 {
    max-width: 350px;
    width: 100%;
  }
}

.tj-single-common .tj-img {
  width: 110px;
  height: 110px;
  border-radius: 500px;
  overflow: hidden;
}

.tj-single-common .tj-img img {
  height: 100%;
  object-fit: cover;
  transform: scale(1);
}

.tj-single-common .tj-content .sidebar__post-content-meta {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 15px;
}

.tj-single-common .tj-content .sidebar__post-content-meta .tj-date,
.tj-single-common .tj-content .sidebar__post-content-meta .tj-comment,
.tj-single-common .tj-content .sidebar__post-content-meta a {
  color: var(--tj-text-body);
}

.tj-single-common .tj-content .sidebar__post-content-meta .tj-date i,
.tj-single-common .tj-content .sidebar__post-content-meta .tj-comment i,
.tj-single-common .tj-content .sidebar__post-content-meta a i {
  color: var(--tj-theme-primary);
  margin-right: 5px;
}

.tj-single-common:hover .tj-img img {
  transform: scale(1.2);
}

.tj-single-common:hover h6 {
  color: var(--tj-heading-secondary);
}

.tj-single-common h6 {
  -webkit-transition: all 0.2s ease-out 0s;
  -moz-transition: all 0.2s ease-out 0s;
  -ms-transition: all 0.2s ease-out 0s;
  -o-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
  color: var(--tj-heading-primary);
  font-weight: var(--tj-fw-medium);
  margin-bottom: 0;
}

.tj-single-common:last-child {
  margin-bottom: 0;
}

.single-blog-tag,
.blog-details__pagenation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  border-top: 1px solid var(--tj-border-4);
  border-bottom: 1px solid var(--tj-border-4);
  padding-top: 30px;
}

.single-blog-tag .tj-tag,
.blog-details__pagenation .tj-tag {
  margin-bottom: 30px;
}

.single-blog-tag .tj-tag h4,
.blog-details__pagenation .tj-tag h4 {
  display: inline-block;
  font-size: 24px;
  font-weight: var(--tj-fw-sbold);
  padding-right: 30px;
  margin-bottom: 0px;
}

@media (max-width: 575px) {

  .single-blog-tag .tj-tag h4,
  .blog-details__pagenation .tj-tag h4 {
    padding-right: 10px;
  }
}

.single-blog-tag .tj-tag a,
.blog-details__pagenation .tj-tag a {
  display: inline-block;
  color: var(--tj-heading-primary);
  font-size: 15px;
  font-family: var(--tj-ff-heading);
  font-weight: 500;
  line-height: 26px;
  background: var(--tj-theme-9);
  padding: 10px 20px;
  margin: 5px 10px;
}

.single-blog-tag .tj-tag a:hover,
.single-blog-tag .tj-tag a:active,
.blog-details__pagenation .tj-tag a:hover,
.blog-details__pagenation .tj-tag a:active {
  background: var(--tj-heading-secondary);
  color: var(--tj-common-white);
}

.single-blog-tag .shear-link,
.blog-details__pagenation .shear-link {
  margin-bottom: 30px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.single-blog-tag .shear-link a,
.blog-details__pagenation .shear-link a {
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  border-radius: 500px;
  border: 1px solid var(--tj-heading-secondary);
  color: var(--tj-heading-secondary);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.single-blog-tag .shear-link a i,
.blog-details__pagenation .shear-link a i {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.single-blog-tag .shear-link a:hover,
.blog-details__pagenation .shear-link a:hover {
  background: var(--tj-heading-secondary);
  color: var(--tj-common-white);
}

.blog-details__pagenation {
  border-top: none;
  border-bottom: 1px solid var(--tj-border-4);
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.comment-one,
.comment-form {
  margin-bottom: 30px;
}

.comment-one h3,
.comment-form h3 {
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 30px;
}

.comment-one h3::after,
.comment-form h3::after {
  position: absolute;
  content: "";
  bottom: 4px;
  width: 70px;
  height: 2px;
  left: 0%;
  background-color: var(--tj-theme-primary);
}

.comment-one input,
.comment-form input {
  height: 55px;
}

.comment-one .tj-blog-comment,
.comment-form .tj-blog-comment {
  display: flex;
  position: relative;
  gap: 20px;
  padding-bottom: 45px;
  margin-bottom: 45px;
}

.comment-one .tj-blog-comment .tj-img,
.comment-form .tj-blog-comment .tj-img {
  width: 120px;
  height: 120px;
  overflow: hidden;
  border-radius: 4px;
}

.comment-one .tj-blog-comment .tj-img img,
.comment-form .tj-blog-comment .tj-img img {
  transform: scale(1.4);
}

.comment-one .tj-blog-comment .tj-content h5,
.comment-form .tj-blog-comment .tj-content h5 {
  font-size: 20px;
  margin-bottom: 15px;
}

.comment-one .tj-blog-comment .tj-content .tj-date a,
.comment-form .tj-blog-comment .tj-content .tj-date a {
  display: block;
  position: relative;
  color: var(--tj-text-body);
  margin-bottom: 10px;
}

.comment-one .tj-blog-comment .tj-content .login-info,
.comment-form .tj-blog-comment .tj-content .login-info {
  position: absolute;
  top: 0%;
  right: 0%;
  border-radius: 3px;
  background: var(--tj-theme-9);
  padding: 8px 20px;
  color: var(--tj-heading-secondary);
  font-size: 15px;
  font-family: var(--tj-ff-body);
  font-weight: 500;
  line-height: 26px;
}

.comment-one .tj-blog-comment .tj-content .login-info:hover,
.comment-form .tj-blog-comment .tj-content .login-info:hover {
  color: var(--tj-common-white);
  background: var(--tj-heading-secondary);
}

.comment-one .tj-blog-comment .tj-content p,
.comment-form .tj-blog-comment .tj-content p {
  margin-bottom: 0;
}

.comment-one .tj-blog-comment:last-child,
.comment-form .tj-blog-comment:last-child {
  padding-bottom: 30px;
  margin-bottom: 0px;
}

.tj_reply {
  margin-left: 50px;
}

.blog__bottom {
  margin-top: 20px;
  text-align: center;
}

.blog__bottom a {
  color: var(--tj-heading-secondary);
  font-weight: var(--tj-fw-medium);
}

.blog__bottom a:hover {
  color: var(--tj-heading-primary);
  text-decoration: underline;
}

.from-group {
  margin: 10px 0px 10px 0px;
}

.from-group .form-control {
  border-radius: 3px;
  border: 1px solid var(--tj-border-13);
  background: var(--tj-theme-9);
}

.from-group .form-control::placeholder {
  color: var(--tj-placeholder-1);
  font-size: 16px;
  font-family: var(--tj-ff-body);
  font-weight: 400;
  line-height: 26px;
}

.from-group .tj-btn-primary {
  margin-top: 20px;
}

.from-group textarea {
  height: 145px;
}

.from-group button {
  color: var(--tj-common-white);
  font-size: 18px;
  font-family: var(--tj-ff-heading);
  font-weight: 500;
  line-height: 26px;
  padding: 17px 30px;
  border-radius: 3px;
}

blockquote {
  font-size: 22px;
  font-weight: var(--tj-ff-heading);
  color: var(--tj-heading-primary);
  background-color: var(--tj-grey-2);
  font-style: italic;
  padding: 50px 65px;
  position: relative;
  margin: 35px 0;
}

blockquote .quote-author,
blockquote cite {
  font-size: 18px;
  font-weight: 500;
  color: var(--tj-heading-secondary);
  padding-left: 35px;
  font-style: normal;
  position: relative;
}

blockquote .quote-author::after,
blockquote cite::after {
  content: "";
  height: 2px;
  width: 23px;
  background-color: var(--tj-heading-secondary);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

/* ----------------------------------
	19. Blog CSS
 ------------------------------------ */
.book-appointment {
  padding-bottom: 80px;
}

.book-appointment .progress_bar {
  max-width: 510px;
  margin-top: 40px;
  margin-bottom: 25px;
}

.tj-inner {
  padding: 55px 55px 10px 55px;
  position: relative;
  margin-top: -150px;
  /* background-image: url(../img/bg/bg-shape.png); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
}

.heading-appointment {
  margin-bottom: 50px;
}

.heading-appointment h5 {
  color: var(--tj-heading-secondary);
  font-family: var(--tj-ff-body);
}

.heading-appointment h2 {
  margin-bottom: 20px;
}

.heading-appointment p {
  color: var(--tj-common-white);
  max-width: 500px;
  width: 100%;
}

.heading-appointment .action-call {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 10px;
}

.heading-appointment .action-call .icon {
  width: 45px;
  height: 45px;
  border-radius: 400px;
  background-color: var(--tj-common-white);
  display: flex;
  position: relative;
  overflow: hidden;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  color: var(--tj-heading-secondary);
  border: 1px solid var(--tj-common-white);
}

.heading-appointment .action-call .icon i {
  font-size: 18px;
  line-height: 0;
}

.heading-appointment .action-call .icon::after {
  z-index: -1;
  border-radius: 500px;
  content: "";
  clear: both;
  width: 0px;
  height: 0px;
  position: absolute;
  top: 50%;
  left: 50%;
  background: var(--tj-heading-secondary);
  transition: all 0.4s ease-in;
}

.heading-appointment .action-call .content h6 {
  font-family: var(--tj-ff-body);
  text-align: start;
  font-weight: var(--tj-fw-sbold);
  color: var(--tj-common-white);
  margin-bottom: 0;
}

.heading-appointment .action-call .content span {
  font-family: var(--tj-ff-body);
  font-style: normal;
  text-align: start;
  font-weight: var(--tj-fw-regular);
  font-size: 16px;
  line-height: 26px;
  color: var(--tj-common-white);
}

.heading-appointment .action-call:hover .icon {
  border: 1px solid var(--tj-heading-secondary);
  background: transparent;
  color: var(--tj-common-white);
}

.heading-appointment .action-call:hover .icon::after {
  top: -30%;
  left: -30%;
  width: 100px;
  height: 100px;
}

/* ----------------------------------
	20. Process CSS
 ------------------------------------ */
.superior_service-wrapper .single-quality {
  margin-bottom: 30px;
  background: var(--tj-common-white);
  box-shadow: 0px 0px 10px 0px var(--tj-shadow-5);
}

.superior_service-wrapper .single-quality.service-reverse {
  display: flex;
  flex-direction: column-reverse;
}

.superior_service-wrapper .single-quality.service-reverse .tj-service-img .shape {
  bottom: auto;
  top: -2px;
  background-position: top;
}

.superior_service-wrapper .single-quality .tj-service-img {
  overflow: hidden;
  position: relative;
}

.superior_service-wrapper .single-quality .tj-service-img::after {
  position: absolute;
  content: "";
  top: 0;
  left: -75%;
  z-index: 1;
  display: block;
  width: 50%;
  height: 100%;
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 100%);
  transform: skewX(-25deg);
}

.superior_service-wrapper .single-quality .tj-service-img .shape {
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  z-index: 1;
  height: 45px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
}

.superior_service-wrapper .single-quality .tj-service-img img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  transition: all 2s ease;
}

.superior_service-wrapper .single-quality.single-quality-2 {
  position: relative;
  z-index: 1;
}

.superior_service-wrapper .single-quality.single-quality-2 .tj-service-img {
  max-width: 265px;
}

.superior_service-wrapper .single-quality.single-quality-2 .tj-service-img .shape__element {
  position: absolute;
  height: 100%;
  width: 50px;
  right: -5px;
  top: 0;
  bottom: 0;
}

.superior_service-wrapper .single-quality.single-quality-2.flex-row-reverse .shape__element {
  right: auto;
  left: -5px;
}

.superior_service-wrapper .single-quality.single-quality-2 .tj-content {
  width: calc(100% - 265px);
  position: relative;
  z-index: 1;
  padding: 30px;
}

.superior_service-wrapper .single-quality.single-quality-2 .tj-content .icon {
  margin-bottom: 20px;
}

.superior_service-wrapper .single-quality.single-quality-2 .tj-content .icon i {
  display: block;
  line-height: 1;
  font-size: 55px;
  color: var(--tj-theme-primary);
}

.superior_service-wrapper .single-quality.single-quality-2 .tj-content .service__title {
  text-align: left;
  font-size: 24px;
}

.superior_service-wrapper .single-quality.single-quality-2 .tj-content p {
  text-align: left;
  padding-bottom: 0;
}

.superior_service-wrapper .single-quality.single-quality-2 .roof-element {
  position: absolute;
  top: 30px;
  right: 40px;
  z-index: -1;
  animation: pluse-icon 5s ease-in-out infinite;
}

.superior_service-wrapper .single-quality .tj-content {
  padding: 30px 20px 30px 20px;
}

.superior_service-wrapper .single-quality .tj-content .icon {
  margin-bottom: 10px;
}

.superior_service-wrapper .single-quality .tj-content .icon i {
  display: block;
  line-height: 1;
  font-size: 55px;
  color: var(--tj-theme-primary);
}

.superior_service-wrapper .single-quality .tj-content .service__title {
  text-align: center;
  font-weight: var(--tj-fw-bold);
  margin-bottom: 20px;
  font-size: 24px;
}

.superior_service-wrapper .single-quality .tj-content .service__title:hover {
  color: var(--tj-theme-primary);
}

.superior_service-wrapper .single-quality .tj-content p {
  margin: 0 auto;
  width: 330px;
  max-width: 100%;
  text-align: center;
  padding-bottom: 20px;
}

.superior_service-wrapper .single-quality .tj-content .tj-process-btn {
  margin-top: 15px;
}

.superior_service-wrapper .single-quality .tj-content .tj-process-btn a {
  color: var(--tj-heading-primary);
  font-weight: var(--tj-fw-medium);
}

.superior_service-wrapper .single-quality .tj-content .tj-process-btn a:hover {
  color: var(--tj-theme-primary);
}

.superior_service-wrapper .single-quality .tj__quality_content {
  border-bottom: 1px solid var(--tj-grey-4);
}

.superior_service-wrapper .single-quality:hover .tj-service-img::after {
  -webkit-animation: shine 800ms;
  animation: shine 800ms;
}

.superior_service-wrapper .single-quality:hover .tj-service-img img {
  transform: scale(1.2);
}

/* ----------------------------------
	WORKING SUCCESS SECTION STYLEING
 ------------------------------------ */
.working_success-wrapper .sec_dec {
  margin-right: auto;
  margin-left: 0;
}

.working_success-widget {
  margin-bottom: 30px;
}

.tj-single-working .tj-working {
  height: 450px;
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.tj-single-working .tj-working img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  transform: scale(1);
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.tj-single-working .tj-working .tj-working-thumb {
  -webkit-transition: all 0.6s ease-out 0s;
  -moz-transition: all 0.6s ease-out 0s;
  -ms-transition: all 0.6s ease-out 0s;
  -o-transition: all 0.6s ease-out 0s;
  transition: all 0.6s ease-out 0s;
  transform: translateY(100px);
  width: 90%;
  position: absolute;
  bottom: 30px;
  left: 35px;
  right: 0px;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tj-single-working .tj-working .tj-working-thumb .tj-content h5 {
  font-weight: 700;
  margin-bottom: 10px;
}

.tj-single-working .tj-working .tj-working-thumb .tj-content p {
  font-size: 16px;
  margin-bottom: 0;
}

.tj-single-working .tj-working .tj-working-thumb .icon {
  margin-right: 30px;
}

.tj-single-working .tj-working .tj-working-thumb .icon a {
  font-size: 40px;
  color: var(--tj-common-white);
}

.tj-single-working .tj-working .tj-working-thumb .icon a i {
  line-height: 0px;
}

.tj-single-working .tj-working::after {
  visibility: hidden;
  opacity: 0;
  content: "";
  clear: both;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  position: absolute;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  background: linear-gradient(0deg, #ea1818 0%, rgba(234, 24, 38, 0.09) 100%);
}

.tj-single-working .tj-working:hover::after {
  visibility: visible;
  opacity: 1;
}

.tj-single-working .tj-working:hover img {
  transform: scale(1.2);
}

.tj-single-working .tj-working:hover .tj-working-thumb {
  transform: translateY(0px);
}

/* ----------------------------------
	21. Facts CSS
 ------------------------------------ */
.fan-wrapper .fan-section-header h5 {
  font-weight: var(--tj-fw-medium);
}

.fan-left {
  margin-bottom: 30px;
  padding-right: 70px;
}

.fan-left .section_title {
  color: var(--tj-heading-primary) !important;
}

.fan-left .section_sub__title {
  font-weight: var(--tj-fw-medium);
}

.fan-left .fan-awards .award-item {
  flex: 0 0 33%;
  max-width: 33%;
}

.tj__frome__widget {
  background: var(--tj-common-white);
  box-shadow: 0px 0px 45px 0px var(--tj-shadow-5);
  margin-bottom: -110px;
}

.tj__frome__widget.tj__frome__widget_2 {
  margin-bottom: 30px;
  background: transparent;
  box-shadow: 0px 0px 0px 0px var(--tj-shadow-5);
}

.tj__frome__widget.tj__frome__widget_2 .tj__frome__head {
  clip-path: polygon(50% 0%, 100% 50%, 100% 100%, 0 100%, 0 50%);
  padding-top: 60px;
  width: 100%;
  padding-bottom: 35px;
}

.tj__frome__widget.tj__frome__widget_2 .tj__frome__head .tj__frome_sub-title {
  margin-bottom: 15px;
}

@media (max-width: 575px) {
  .tj__frome__widget.tj__frome__widget_2 .tj__frome__head {
    padding-top: 70px;
    padding-bottom: 20px;
  }
}

.tj__frome__widget.tj__frome__widget_2 .tj__frome__body {
  background: var(--tj-common-white);
  box-shadow: 0px 0px 45px 0px var(--tj-shadow-5);
}

.tj__frome__head {
  padding: 20px 20px 50px 20px;
  background-color: var(--tj-theme-primary);
  clip-path: polygon(200% 0, 100% 60%, 50% 100%, 0 60%, 0 0);
  text-align: center;
}

.tj__frome__head .tj__frome_sub-title {
  font-family: var(--tj-ff-body);
  font-size: 22px;
  color: var(--tj-common-white);
  margin-bottom: 0px;
}

.tj__frome__head .tj__frome_title {
  font-weight: var(--tj-fw-bold);
  font-size: 35px;
  margin-bottom: 0px;
  color: var(--tj-common-white);
}

.tj__frome__body {
  padding: 35px 0 0 0;
}

.tj__frome__body .form-group {
  margin-bottom: 20px;
}

.tj__frome__body .form-group.tj_form__submit {
  margin-top: 10px;
  margin-bottom: 0;
}

.tj__frome__body input {
  height: 55px;
}

.tj__frome__body input::placeholder {
  color: var(--tj-grey-9);
}

.tj__frome__body textarea::placeholder {
  color: var(--tj-grey-9);
}

.tj__frome__body input[name="name"],
.tj__frome__body input[name="phone"],
.tj__frome__body input[name="mail"],
.tj__frome__body input[name="location"],
.tj__frome__body input[name="time"] {
  padding-right: 50px;
}

.tj__frome__body .form-group {
  position: relative;
}

.tj__frome__body i {
  padding: 20px 20px;
  position: absolute;
  top: 0%;
  right: 0%;
  color: var(--tj-grey-9);
}

.tj__frome__body button,
.tj__frome__body input[type="submit"] {
  padding: 12px;
  border: none;
  background-color: var(--tj-theme-primary);
  color: var(--tj-common-white);
  font-family: var(--tj-ff-body);
  font-style: normal;
  font-weight: var(--tj-fw-medium);
  font-size: 18px;
  line-height: 28px;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.tj__frome__body button:hover,
.tj__frome__body input[type="submit"]:hover {
  background: var(--tj-heading-primary);
}

.tj__frome__body button.form-control:focus,
.tj__frome__body input[type="submit"].form-control:focus {
  border: 0px solid var(--tj-heading-secondary);
}

.tj__frome__body .form-control:focus {
  border: 1px solid var(--tj-heading-secondary);
}

.tj__frome__body textarea {
  width: 100%;
  height: 134px;
}

.tj__frome__body textarea:focus {
  border: 1px solid var(--tj-heading-secondary);
}

/* ----------------------------------
	FUNFACT SECTION STYLEING
 ------------------------------------ */
.funfact-wrapper .funfact-widget {
  padding-top: 55px;
  padding-bottom: 55px;
  border-top: 1px solid var(--tj-border-9);
  border-bottom: 1px solid var(--tj-border-9);
}

.funfact-wrapper .single-funfact {
  padding: 0px 20px;
  margin-bottom: 30px;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 40px;
  border-right: 1px solid var(--tj-border-9);
  position: relative;
}

.funfact-wrapper .icons .shape {
  position: absolute;
  top: 0%;
  left: 0%;
  z-index: -1;
}

.funfact-wrapper .fun-content .fun-count {
  font-size: 55px;
  font-weight: 500;
  line-height: 65px;
  margin-bottom: 10px;
}

.funfact-wrapper .fun-content .fun-dec {
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 0;
}

/* ----------------------------------
	HAPPY CLIENT SECTION STYLEING
 ------------------------------------ */
.happy__client-wrapper {
  padding-top: 90px;
  padding-bottom: 90px;
  position: relative;
  overflow: hidden;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.happy__client-wrapper .client__left {
  margin-bottom: 30px;
}

.happy__client-wrapper .section_title {
  font-weight: 700;
  margin-bottom: 20px;
}

.happy__client-wrapper .sec_dec {
  font-family: var(--tj-ff-bady);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.happy__client-wrapper .client__right {
  margin-bottom: 30px;
}

.happy__client-wrapper .client__right .single__client {
  padding: 20px 20px;
}

.happy__client-wrapper .client__right .client__widget {
  position: relative;
  padding: 40px 40px 40px 50px;
  max-width: 650px;
  margin-left: auto;
  border-radius: 140px;
  border: 1px solid var(--tj-heading-secondary);
  background: var(--tj-common-white);
  box-shadow: 0px 0px 35px 0px var(--tj-shadow-5);
  display: flex;
  align-items: center;
  gap: 30px;
}

.happy__client-wrapper .client__right .client__widget.client__widget-2 {
  margin-top: 40px;
  border: 1px solid var(--tj-heading-primary);
  background: transparent;
  box-shadow: none;
  margin-right: -40px;
}

.happy__client-wrapper .client__right .client__widget .client__dec {
  text-align: end;
}

.happy__client-wrapper .client__right .client__widget .client__dec .client__name {
  font-size: 35px;
  font-weight: 600;
  line-height: 42px;
  margin-bottom: 10px;
}

.happy__client-wrapper .client__right .client__widget .client__dec p {
  font-size: 16px;
}

.happy__client-wrapper .client__right .client__widget .client__element {
  position: relative;
}

.happy__client-wrapper .client__right .client__widget .client__img {
  overflow: hidden;
  width: 120px;
  height: 120px;
  border-radius: 500px;
  position: relative;
}

.happy__client-wrapper .client__right .client__widget .client__img img {
  object-fit: cover;
}

.happy__client-wrapper .client__right .client__widget .quote {
  position: absolute;
  top: -10px;
  left: 15%;
}

/* ----------------------------------
	22. CTA CSS
 ------------------------------------ */
.cta-wrapper.cta-1 {
  overflow: hidden;
  padding: 50px 0px;
  z-index: 1;
  position: relative;
}

.cta-wrapper.cta-1:before {
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  background: rgba(35, 49, 82, 0.68);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.cta-wrapper .single-text {
  position: relative;
  z-index: 1;
}

.cta-wrapper .single-text .section_title {
  font-weight: var(--tj-fw-sbold);
  font-size: 30px;
  color: var(--tj-common-white);
  margin-bottom: 0;
}

.cta-wrapper .cta-btn {
  position: relative;
}

.cta-wrapper .cta-btn::after {
  content: "";
  clear: both;
  position: absolute;
  width: 242.98px;
  height: 450px;
  right: -40px;
  top: -200px;
  background: var(--tj-heading-secondary);
  transform: rotate(27.16deg);
}

.cta-wrapper.cta-4 {
  overflow: hidden;
  position: relative;
  z-index: 1;
  padding: 95px 0px;
}

.cta-wrapper.cta-4 .tj-shape-element {
  position: absolute;
  top: 0%;
  left: 0;
  z-index: -1;
}

.cta-wrapper.cta-4::after {
  clip-path: polygon(0% 0, 100% 0, 88% 100%, 0% 100%);
  position: absolute;
  background: var(--tj-heading-primary);
  content: "";
  clear: both;
  height: 100%;
  width: 56%;
  top: 0%;
  z-index: -2;
  left: 0px;
}

.cta-wrapper.cta-4::before {
  clip-path: polygon(0% 0, 100% 0, 88% 100%, 0% 100%);
  position: absolute;
  background: var(--tj-common-white);
  content: "";
  clear: both;
  height: 100%;
  width: 56%;
  top: 0%;
  z-index: -2;
  left: 7px;
}

.cta-wrapper.cta-4 .tj-single-cta {
  position: relative;
  z-index: 1;
}

.cta-wrapper.cta-4 .tj-single-cta .tj-cta-content .cta__title {
  font-weight: var(--tj-fw-medium);
  color: var(--tj-common-white);
  margin-bottom: 0;
}

.cta-wrapper.cta-4 .video-play-btn {
  width: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95px;
  background: transparent;
  padding: 5px;
  border-radius: 500px;
  border: 2px dashed var(--tj-heading-secondary);
}

.cta-wrapper.cta-4 .video-play-btn .play-video {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 575px) {
  .cta-wrapper.cta-4::before {
    display: none;
  }

  .cta-wrapper.cta-4::after {
    width: 100%;
    clip-path: polygon(0% 0, 100% 0, 100% 100%, 0% 100%);
    opacity: 0.9;
  }
}

.tj-cta-widget {
  background: var(--tj-heading-secondary);
}

.tj-cta-widget .tj-cta {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.tj-cta-widget .action-cta {
  padding: 20px 60px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 15px;
}

.tj-cta-widget .action-cta .icon {
  width: 55px;
  height: 55px;
  display: flex;
  background: var(--tj-common-white);
  justify-content: center;
  align-items: center;
}

.tj-cta-widget .action-cta .icon i {
  transition: all 0.4s ease-in-out;
  font-size: 35px;
  line-height: 25px;
  color: var(--tj-heading-secondary);
}

.tj-cta-widget .action-cta:nth-child(1) {
  padding-left: 0px;
}

.tj-cta-widget .action-cta:last-child {
  padding-right: 0px;
}

.tj-cta-widget .action-cta:last-child::after {
  display: none;
}

.tj-cta-widget .action-cta::after {
  clear: both;
  content: "";
  width: 2px;
  height: 100%;
  position: absolute;
  background: var(--tj-border-15);
  top: 0%;
  right: 0;
}

.tj-cta-widget .action-cta h6 {
  color: var(--tj-common-white);
  margin-bottom: 0px !important;
}

.tj-cta-widget .action-cta p {
  color: var(--tj-common-white);
  font-size: 18px;
  margin-bottom: 0;
}

.tj-cta-widget .action-cta:hover .icon i {
  transform: rotateY(360deg);
}

/* ----------------------------------
	ARIA SECTION  STYLEING
 ------------------------------------ */
.office_area-wrapper {
  padding-top: 90px;
  padding-bottom: 120px;
}

.office_area-wrapper .section_title {
  margin-bottom: 0;
}

.office_area-wrapper .aria-widget {
  position: relative;
}

.office_area-wrapper .aria-widget .aria-widget-img img {
  min-height: 600px;
  object-fit: cover;
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .office_area-wrapper .aria-widget .aria-widget-img img {
    min-height: 400px;
  }
}

.office_area-wrapper .contact-aria {
  position: absolute;
  bottom: 0%;
  right: 0;
  background: var(--tj-heading-primary);
  padding: 40px;
  box-shadow: 0px 0px 30px var(--tj-shadow-1);
  max-width: 620px;
}

@media (max-width: 575px) {
  .office_area-wrapper .contact-aria .title {
    font-size: 30px;
  }
}

.office_area-wrapper .contact-aria p {
  color: var(--tj-common-white);
  padding-bottom: 15px;
}

.office_area-wrapper .contact-aria .contact-action {
  border-top: 1px solid var(--tj-grey-3);
  display: flex;
  align-items: center;
}

.office_area-wrapper .contact-aria .contact-action a {
  margin-top: 30px;
  position: relative;
  border-right: 1px solid var(--tj-grey-4);
  padding: 0 50px 0 50px;
  margin-right: 40px;
}

.office_area-wrapper .contact-aria .contact-action a:last-child {
  border: none;
  margin-right: 0px;
  padding-right: 0px;
}

.office_area-wrapper .contact-aria .contact-action a h6 {
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  color: var(--tj-common-white);
  margin-bottom: 0;
}

.office_area-wrapper .contact-aria .contact-action a span {
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  font-style: normal;
  font-weight: var(--tj-fw-medium);
  font-size: 16px;
  line-height: 26px;
  color: var(--tj-common-white);
  margin-bottom: 1px;
}

.office_area-wrapper .contact-aria .contact-action a i {
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  font-size: 35px;
  line-height: 35px;
  color: var(--tj-common-white);
  position: absolute;
  left: 0;
  top: 8px;
}

.office_area-wrapper .contact-aria .contact-action a:hover h6,
.office_area-wrapper .contact-aria .contact-action a:hover span,
.office_area-wrapper .contact-aria .contact-action a:hover i {
  color: var(--tj-heading-secondary);
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .office_area-wrapper .contact-aria {
    position: relative;
  }
}

/* ----------------------------------
	23. Portfolio CSS
 ------------------------------------ */
.single-portfolio {
  overflow: hidden;
  width: 100%;
  height: 415px;
  z-index: 1;
  position: relative;
  margin-bottom: 30px;
}

.single-portfolio:before {
  top: 0;
  left: 0;
  z-index: -1;
  width: 0%;
  height: 100%;
  content: "";
  position: absolute;
  background: var(--tj-shadow-4);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.single-portfolio.single-portfolio-1 {
  padding: 80px 80px;
  height: 415px;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  background: var(--tj-heading-primary);
  position: relative;
  z-index: 1;
  background-repeat: no-repeat;
}

.single-portfolio.single-portfolio-1:before {
  display: none;
}

.single-portfolio.single-portfolio-1 .portfolio-title {
  font-weight: var(--tj-fw-bold);
  margin-bottom: 25px;
}

.single-portfolio.single-portfolio-1 p {
  margin-bottom: 25px;
  color: var(--tj-common-white);
}

.single-portfolio.single-portfolio-1 .portfolio-element {
  position: absolute;
  bottom: 0%;
  left: 0;
  z-index: -1;
}

.single-portfolio .pot-shape {
  position: absolute;
  bottom: -100%;
  left: -100%;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.single-portfolio .portfolio-link {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transition: all 0.1s ease;
  bottom: 35px;
  left: 35px;
  z-index: 3;
}

.single-portfolio .portfolio-link .icons {
  width: 45px;
  height: 45px;
  background: var(--tj-common-white);
  border-radius: 500px;
  color: var(--tj-heading-primary);
  margin-left: auto;
  margin-right: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.single-portfolio .portfolio-link .icons i {
  line-height: 0;
}

.single-portfolio .portfolio-link p {
  margin-top: 20px;
  font-family: var(--tj-ff-heading);
  font-weight: var(--tj-fw-bold);
  font-size: 22px;
  color: var(--tj-common-white);
  margin-bottom: 0;
}

.single-portfolio:hover:before {
  left: auto;
  right: 0;
  width: 100%;
}

.single-portfolio:hover .portfolio-link {
  visibility: visible;
  opacity: 1;
  transition: all 0.7s ease-in;
}

.single-portfolio:hover .pot-shape {
  bottom: 0%;
  left: 0%;
}

.crn-portfolio-thumb.crn-portfolio-thumb-2 {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.crn-portfolio-thumb.crn-portfolio-thumb-2 img {
  object-fit: cover;
}

.crn-portfolio-thumb.crn-portfolio-thumb-2 .icon a {
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 2;
  width: 50px;
  height: 50px;
  border-radius: 500px;
  background-color: var(--tj-theme-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
}

.crn-portfolio-thumb.crn-portfolio-thumb-2 .icon a i {
  font-size: 23px;
  color: var(--tj-common-white);
  line-height: 0;
}

.crn-portfolio-thumb.crn-portfolio-thumb-2 .icon a:hover {
  visibility: visible;
  opacity: 1;
  background-color: var(--tj-common-white);
}

.crn-portfolio-thumb.crn-portfolio-thumb-2 .icon a:hover i {
  color: var(--tj-heading-secondary);
}

.crn-portfolio-thumb.crn-portfolio-thumb-2 .thumb-content .portfolio__title {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: 2;
  bottom: 30px;
  left: 40px;
  color: var(--tj-common-white);
  font-weight: var(--tj-fw-bold);
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.crn-portfolio-thumb.crn-portfolio-thumb-2 .thumb-content::after {
  position: absolute;
  left: 18px;
  top: 0;
  width: 3px;
  height: 0;
  z-index: 1;
  content: "";
  clear: both;
  background: var(--tj-common-white);
  transition: all 0.6s ease;
  border-radius: 416px;
}

.crn-portfolio-thumb.crn-portfolio-thumb-2 .thumb-content::before {
  position: absolute;
  right: 0;
  bottom: 18px;
  width: 0;
  height: 3px;
  border-radius: 416px;
  content: "";
  clear: both;
  z-index: 1;
  background: var(--tj-common-white);
  transition: all 0.7s ease;
}

.crn-portfolio-thumb.crn-portfolio-thumb-2::after {
  position: absolute;
  left: 0%;
  bottom: 0;
  z-index: 0;
  width: 0%;
  height: 100%;
  background: var(--tj-shadow-4);
  content: "";
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.crn-portfolio-thumb.crn-portfolio-thumb-2:hover img {
  transform: scale(1.1);
}

.crn-portfolio-thumb.crn-portfolio-thumb-2:hover::after {
  width: 100%;
}

.crn-portfolio-thumb.crn-portfolio-thumb-2:hover a {
  visibility: visible;
  opacity: 1;
}

.crn-portfolio-thumb.crn-portfolio-thumb-2:hover .thumb-content .portfolio__title {
  visibility: visible;
  opacity: 1;
}

.crn-portfolio-thumb.crn-portfolio-thumb-2:hover .thumb-content::after {
  height: 100%;
}

.crn-portfolio-thumb.crn-portfolio-thumb-2:hover .thumb-content::before {
  width: 100%;
}

.crn-portfolio-thumb {
  position: relative;
  overflow: hidden;
  margin: 0 0 25px;
}

.crn-portfolio-thumb img {
  width: 100%;
  height: auto;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transform: scale(1.05);
}

.crn-portfolio-thumb::after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  background: var(--tj-shadow-4);
  content: "";
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.crn-portfolio-thumb .crn-portfolio-thumb:hover::after {
  height: 100%;
}

.crn-portfolio-thumb .tj-more {
  width: 45px;
  height: 45px;
  background: var(--primay-color);
  color: var(--tj-common-white);
  line-height: 52px;
  font-size: 20px;
  text-align: center;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
  transform: scale(0);
  z-index: 3;
}

.crn-portfolio-thumb .crn-portfolio-thumb .tj-more:hover {
  color: var(--tj-heading-primary);
  background: var(--tj-common-white);
}

.crn-portfolio-thumb .crn-portfolio-thumb:hover .tj-more {
  transform: scale(1);
}

.crn-portfolio-thumb .tj-hover {
  position: absolute;
  left: 15px;
  bottom: 15px;
  width: 81%;
  height: 81%;
  display: flex;
  align-items: flex-end;
  z-index: 2;
  padding: 0 0 40px 50px;
}

.crn-portfolio-thumb .tj-hover::after {
  position: absolute;
  left: 18px;
  top: 0;
  width: 2px;
  height: 0;
  content: "";
  background: var(--tj-common-white);
  transition: all 0.6s ease;
}

.crn-portfolio-thumb .tj-hover::before {
  position: absolute;
  right: 0;
  bottom: 18px;
  width: 0;
  height: 2px;
  content: "";
  background: var(--tj-common-white);
  transition: all 0.7s ease;
}

.crn-portfolio-thumb .tj-hover h5 {
  color: var(--tj-common-white);
  margin: 0;
  transition: all 0.8s ease;
  display: inline-block;
  opacity: 0;
}

.crn-portfolio-thumb:hover .tj-hover::after {
  height: 100%;
}

.crn-portfolio-thumb:hover .tj-hover::before {
  width: 100%;
}

.crn-portfolio-thumb:hover .tj-hover h5 {
  opacity: 1;
  animation: fadeInLeft 0.8s linear alternate;
  animation-duration: 0.4s;
}

/* ----------------------------------
	24. FAQ CSS
 ------------------------------------ */
.faq-wrapper .section-title p {
  width: 530px;
  margin: 0 auto;
}

.faq-wrapper .single-faq-left {
  margin-bottom: 30px;
}

.faq-wrapper .single-faq-left .tj-faq-top-img {
  width: 100%;
  height: 220px;
  margin-bottom: 30px;
}

.faq-wrapper .single-faq-left .tj-item {
  display: flex;
  align-items: center;
  gap: 20px;
}

.faq-wrapper .single-faq-left .tj-item .icon {
  width: 50px;
  max-width: 100%;
  height: 50px;
  max-height: 100%;
  border-radius: 500px;
  background-color: var(--tj-theme-secondary);
  color: var(--tj-common-white);
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.faq-wrapper .single-faq-left .tj-item .icon i {
  font-size: 30px;
}

.faq-wrapper .single-faq-left .tj-item h5 {
  color: var(--tj-common-black);
  font-size: 20px;
  font-weight: var(--tj-fw-sbold);
  line-height: 28px;
  margin-bottom: 5px;
}

.faq-wrapper .single-faq-left .tj-item p {
  display: flex;
  max-width: 365px;
}

.faq-wrapper .single-faq-left .tj-item p:last-child {
  margin-bottom: 0 !important;
}

.faq-wrapper .single-faq-left .tj-item:hover .icon {
  background-color: var(--tj-theme-primary);
}

.faq-wrapper .single-faq-left .tj-item:hover .icon i {
  color: var(--tj-common-white);
}

.faq-wrapper .single-faq-left .tj-item:not(:last-child) {
  padding-bottom: 20px;
}

.faq-wrapper .single-faq-right {
  margin-bottom: 30px;
}

.accordion-item {
  margin: 10px 0px;
  border: 1px solid var(--tj-border-8);
  background-color: var(--tj-common-white);
}

.accordion-item:first-of-type {
  margin-top: 0;
}

.accordion-item:last-of-type {
  margin-bottom: 0;
}

.accordion-item.active {
  border: 1px solid var(--tj-common-white);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background: var(--tj-common-white);
  position: relative;
  box-shadow: 0px 0px 40px 0px var(--tj-shadow-5);
}

.accordion-item.active::after {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  position: absolute;
  width: 5px;
  content: "";
  top: 0;
  left: 0px;
  height: 100%;
  background: var(--tj-theme-primary);
}

.accordion-item:first-of-type {
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: 0rem;
  border-bottom-left-radius: 0rem;
}

.accordion-item .accordion-button {
  padding: 0px 0px 0px 20px;
  border: none;
  box-shadow: none;
  color: var(--tj-heading-primary);
  font-size: 18px;
  font-family: var(--tj-ff-heading);
  font-weight: 600;
  line-height: 26px;
}

.accordion-item .accordion-button:focus {
  outline: none;
  box-shadow: none;
}

.accordion-item .accordion-button::after {
  flex-shrink: 0;
  width: auto;
  height: auto;
  padding: 20px;
  background-color: var(--tj-text-body);
  color: var(--tj-common-white);
  margin-left: auto;
  content: "\f103";
  font-family: var(--tj-ff-fontawesome);
  font-weight: var(--tj-fw-blacks);
  background-image: none;
  background-repeat: no-repeat;
  transition: transform 0.2s ease-in-out;
}

.accordion-item .accordion-button:not(.collapsed) {
  color: var(--tj-heading-secondary);
  background-color: transparent;
  box-shadow: none;
}

.accordion-item .accordion-button:not(.collapsed)::after {
  background-color: var(--tj-theme-primary);
  transform: rotateX(-180deg);
}

.accordion-item .accordion-body {
  padding: 5px 20px 10px 20px;
}

.accordion-item .accordion-body p {
  padding: 0px 70px 20px 0px;
}

.accordion-item .accordion-body p:last-child {
  margin-bottom: 0;
}

.pronation_widget {
  margin-top: 20px;
  margin-bottom: 20px;
}

.pronation_widget ul {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.pronation_widget ul a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  border: 1px solid var(--tj-border-10);
  color: var(--tj-border-10);
  font-size: 20px;
  font-weight: var(--tj-fw-regular);
}

.pronation_widget ul li:hover a,
.pronation_widget ul li.active a {
  background-color: var(--tj-theme-primary);
  color: var(--tj-common-white);
  border: 1px solid var(--tj-heading-secondary);
}

/* ----------------------------------
	25. Contact CSS
 ------------------------------------ */
.contact-wrapper .contact-map {
  width: 100%;
  height: 600px;
}

.contact-wrapper .contact-map iframe {
  width: 100%;
  height: 600px;
}

.contact-wrapper .contact-widget {
  position: relative;
  margin-top: -200px;
  background: var(--tj-common-white);
  box-shadow: 0px 0px 35px 0px var(--tj-shadow-5);
  padding: 50px 50px 0 50px;
  margin-bottom: 30px;
}

.contact-wrapper .contact-widget .from-group input {
  height: 55px;
}

.contact-wrapper .contact-widget .from-group input:focus {
  border: 1px solid var(--tj-heading-secondary);
}

.contact-wrapper .contact-widget .from-group textarea:focus {
  border-color: var(--tj-heading-secondary);
}

.tj-single-info {
  background: var(--tj-common-white);
  box-shadow: 0px 0px 35px 0px var(--tj-shadow-5);
  margin-bottom: 30px;
}

.tj-single-info .tj-info-head {
  height: 120px;
  clip-path: polygon(100% 0, 100% 65%, 50% 100%, 0 65%, 0 0);
  background-color: var(--tj-theme-secondary);
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.tj-single-info .tj-body {
  padding: 0px 30px 35px 30px;
}

.tj-single-info .tj-body .icon-thumb {
  position: relative;
  margin-top: -50px;
  margin-bottom: 25px;
}

.tj-single-info .tj-body .icons {
  width: 120px;
  height: 120px;
  margin: 0 auto;
  border-radius: 120px;
  border: 3px solid var(--tj-heading-primary);
  background: var(--tj-common-white);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 45px;
  line-height: 45px;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.tj-single-info .tj-body .content .title {
  font-size: 25px;
}

.tj-single-info .tj-body .content h6 {
  font-size: 22px;
  margin-bottom: 5px;
}

.tj-single-info .tj-body .content a {
  display: block;
  color: var(--tj-text-body);
  font-size: 18px;
  font-family: var(--tj-ff-body);
  margin-bottom: 5px;
}

.tj-single-info .tj-body .content a:hover {
  text-decoration: underline;
  color: var(--tj-heading-secondary);
}

.tj-single-info .tj-body .content p {
  width: 100%;
  margin: auto;
  margin-bottom: 15px;
}

.tj-single-info:hover .tj-info-head {
  background-color: var(--tj-theme-primary);
}

.tj-single-info:hover .icons {
  border: 3px solid var(--tj-heading-secondary);
  color: var(--tj-heading-secondary);
}

/* ----------------------------------
	26. Footer CSS
 ------------------------------------ */
/* ----------------------------------
	footer top Styles
 ------------------------------------ */
.footer-top-map .footer-map iframe {
  height: 390px;
  width: 100%;
}

.footer-top-map .footer-top-widget {
  position: relative;
  z-index: 1;
  margin-top: -398px;
  padding: 90px 25px;
  background-color: var(--tj-theme-primary);
  width: 337px;
  height: auto;
  max-width: 100%;
}

.footer-top-map .footer-top-widget .footer__info__title {
  font-family: var(--tj-ff-body);
  color: var(--tj-common-white) !important;
  font-size: 24px;
  margin-bottom: 30px;
  padding-bottom: 15px;
  position: relative;
}

.footer-top-map .footer-top-widget .footer__info__title::after {
  content: "";
  clear: both;
  display: block;
  left: 0%;
  position: absolute;
  width: 50px;
  height: 2px;
  bottom: 0%;
  background: var(--tj-grey-5);
}

.footer-top-map .footer-top-widget p,
.footer-top-map .footer-top-widget a {
  color: var(--tj-common-white);
  margin-bottom: 50px;
  font-weight: var(--tj-fw-medium);
}

.footer-top-map .footer-top-widget span {
  color: var(--tj-common-white);
  font-size: 16px;
  font-family: var(--tj-ff-body);
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.footer-top-map .footer-top-widget span a {
  margin-bottom: 0;
}

.footer-top-map .footer-top-widget span a:hover {
  text-decoration: underline;
}

/* ----------------------------------
	Main footer Styles
 ------------------------------------ */
.footer-1 {
  background: var(--tj-heading-primary);
  position: relative;
  z-index: 1;
}

.footer-1 .footer__bottom {
  border-top: 1px solid var(--tj-border-5);
}

.footer-1 .footer__middle {
  position: relative;
}

.footer-1 .footer__middle .footer-home-left {
  position: absolute;
  bottom: 0%;
  left: 0%;
  z-index: -1;
}

.footer-1 .footer1_col_2 {
  padding-left: 50px;
  padding-right: 50px;
}

.footer-1 .footer1_padding {
  padding-top: 67px;
  padding-bottom: 60px;
}

.footer__top form {
  width: 100%;
}

.footer__top .footer__top-border {
  padding: 40px 0px;
  border-bottom: 1px solid var(--tj-grey-4);
}

.footer__top .tj_subscrib .subscribe__footer__title {
  font-size: 30px;
  font-weight: 700;
  line-height: 39px;
  margin-bottom: 0;
  font-weight: var(--tj-fw-bold);
}

.footer__top .input-group-prepend button {
  background-color: var(--tj-theme-primary);
  padding: 15px 30px;
  border: 0px solid var(--tj-common-white);
  color: var(--tj-common-white);
  border-radius: 0px;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.footer__top .input-group-prepend button i {
  margin-left: 5px;
}

.footer__top .input-group {
  gap: 25px;
  width: 100%;
  flex-wrap: nowrap;
}

.footer__top .input-group input[type="email"] {
  color: var(--tj-text-body);
  border-radius: 0px;
  max-width: 460px;
  width: 100%;
  padding: 10px;
  background-color: var(--tj-common-white);
  border-color: var(--tj-common-white);
}

.footer__top .input-group::placeholder {
  color: var(--tj-grey-5);
}

.footer__widget .widget__title {
  color: var(--tj-common-white);
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 47px;
}

.footer__widget .footer_info {
  margin-bottom: 25px;
}

.footer__widget .footer_info .footer__logo_1 {
  display: block;
  margin-bottom: 40px;
  max-width: 200px;
  width: 100%;
}

.footer__widget .footer_info p {
  color: var(--tj-common-white);
  font-family: var(--tj-ff-body);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  max-width: 289px;
  width: 100%;
}

.footer__widget .time__title {
  color: var(--tj-grey-6);
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 15px;
}

.footer__widget .footer__time .time_show>li {
  color: var(--tj-common-white);
  font-family: var(--tj-ff-body);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 15px;
  display: block;
}

.footer__contact-list .info-contact {
  margin-bottom: 20px;
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.footer__contact-list .info-contact h6 {
  color: var(--tj-grey-6);
  margin-bottom: 0;
}

.footer__contact-list .info-contact p {
  color: var(--tj-grey-6);
  margin-bottom: 0;
}

.footer__contact-list .info-contact i {
  position: absolute;
  top: 0%;
  left: 0%;
  color: var(--tj-heading-secondary);
  font-size: 30px;
}

.footer__contact-list .info-contact:hover h6,
.footer__contact-list .info-contact:hover p {
  color: var(--tj-heading-secondary);
}

.footer__form .footer__form_dec {
  margin-bottom: 20px;
}

.footer__form .footer__form_dec p {
  color: var(--tj-common-white);
  max-width: 302px;
  width: 100%;
}

.footer-widget__explore-list>li {
  display: block;
  margin-bottom: 8px;
}

.footer-widget__explore-list>li:last-child {
  margin-bottom: 0px;
}

.footer-widget__explore-list>li a {
  font-weight: var(--tj-fw-medium);
  color: var(--tj-common-white);
  position: relative;
}

.footer-widget__explore-list>li a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 1px;
  background-color: #93C94F;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.footer-widget__explore-list>li a:hover {
  color: var(--tj-heading-secondary);
}

.footer-widget__explore-list>li a:hover::after {
  width: 100%;
}

.grid-content {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 10px;
}

.grid-content .popup-gallery {
  float: left;
  margin-left: 4%;
  background-color: var(--tj-grey-8);
  height: 80px;
  width: 100%;
  overflow: hidden;
}

.single-recent-post {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 20px 0px;
  max-width: 300px;
  border-bottom: 1px solid var(--tj-border-7);
}

.single-recent-post .thumb {
  width: 85px;
  max-width: 100%;
  height: 85px;
  max-height: 100%;
  border-radius: 500px;
  overflow: hidden;
}

.single-recent-post .thumb img {
  object-fit: cover;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.single-recent-post .post-content span {
  font-family: var(--tj-ff-body);
  font-weight: var(--tj-fw-regular);
  font-size: 14px;
  display: block;
  line-height: 20px;
  margin-bottom: 10px;
  color: var(--tj-common-white);
}

.single-recent-post .post-content span i {
  margin-right: 10px;
  color: var(--tj-theme-primary);
}

.single-recent-post .post-content .content {
  font-size: 18px;
  color: var(--tj-common-white);
  max-width: 210px;
  width: 100%;
  margin-bottom: 0;
}

.single-recent-post .post-content .content:hover {
  color: var(--tj-theme-primary);
}

.single-recent-post:last-child {
  border-bottom: 0px solid var(--tj-border-6) !important;
  padding-bottom: 0px;
}

.single-recent-post:first-child {
  padding-top: 0;
}

.single-recent-post:hover .thumb img {
  transform: scale(1.2);
}

.footer__copy_write {
  padding: 20px 0px 10px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer__copy_write .copy__right {
  margin-bottom: 10px;
}

.footer__copy_write .link {
  margin-bottom: 10px;
  color: #fff;
}

.footer__copy_write p {
  color: var(--tj-common-white);
  margin-bottom: 0px;
}

.footer__copy_write p a:hover {
  color: var(--tj-heading-secondary);
  text-decoration: underline;
}

.footer__copy_write a {
  font-weight: var(--tj-fw-regular);
  color: var(--tj-grey-7);
  padding: 0px 5px;
}

.footer__copy_write a:hover {
  color: var(--tj-heading-secondary);
}

.footer-2 {
  background: var(--tj-theme-10);
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.footer-2 .footer__top {
  padding: 25px 0px;
  border-bottom: 1px solid var(--tj-border-7);
}

.footer-2 .footer__middle {
  border-bottom: 1px solid var(--tj-border-7);
}

.footer-2 .footer-top-link {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 30px;
}

.footer-2 .footer__logo2 {
  display: block;
  max-width: 150px;
  width: 100%;
}

.footer-2 .footer-link ul>li {
  margin-bottom: 0;
}

.footer-2 .footer-link ul>li>a {
  color: var(--tj-common-white);
  font-size: 17px;
  font-family: var(--tj-ff-body);
  font-weight: 400;
  margin-right: 20px;
}

.footer-2 .footer-link ul>li>a:hover {
  color: var(--tj-heading-secondary);
}

.footer-2 .shear-link-footer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.footer-2 .shear-link-footer a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 500px;
  background-color: var(--tj-common-black);
  color: var(--tj-common-white);
}

.footer-2 .shear-link-footer a:hover {
  background: var(--tj-heading-secondary);
}

.footer-2 form .form-control {
  height: 55px;
  border: 1px solid var(--tj-border-16);
  background: var(--tj-theme-10);
  color: var(--tj-common-white);
  margin-bottom: 20px;
}

.footer-2 form .form-control:focus {
  border: 1px solid var(--tj-border-16);
  background: var(--tj-theme-10);
}

.footer-2 button {
  background-color: var(--tj-theme-primary);
  padding: 19px 30px;
  border: 0px solid var(--tj-common-white);
  color: var(--tj-common-white);
  border-radius: 0px;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.footer-2 button i {
  margin-left: 5px;
}

.footer-2 .footer__copy_write {
  padding: 20px 0px 20px 0px;
}

.footer-2 .footer__middle {
  position: relative;
  padding-bottom: 40px;
}

.footer-2 .footer__middle .footer__tow_col_1 {
  padding: 70px 20px 0px 20px;
}

.footer-2 .footer__middle .footer__tow_col_2 {
  padding: 70px 20px 0px 10px;
}

.footer-2 .footer__middle .footer__tow_col_3 {
  padding: 70px 20px 0px 20px;
}

.footer-2 .footer__middle .footer__tow_col_4 {
  padding: 70px 10px 0px 0px;
}

.footer-2 .footer__middle .footer__tow_col_4 p {
  padding-bottom: 20px;
}

.footer-2 .footer2_padding {
  padding-top: 70px;
  padding-bottom: 100px;
}

footer {
  position: relative;
}

footer .footer-shape-left {
  position: absolute;
  left: 0%;
  top: 0%;
  z-index: -1;
  animation: left-to-right 6s ease-in-out infinite;
}

footer .footer-shape-right {
  position: absolute;
  right: 0%;
  top: 0%;
  z-index: -1;
  animation: right-to-left 6s ease-in-out infinite;
}

footer .footer-bottom-shape {
  z-index: -1;
  position: absolute;
  bottom: 0%;
  left: 0%;
}

footer .footer-shape {
  position: absolute;
  bottom: 10%;
  right: 0%;
  z-index: -1;
  animation: pluse 8s ease-in-out infinite;
}

footer .footer-right-shape {
  position: absolute;
  top: 0%;
  right: 0%;
  z-index: -1;
  animation: bounce 6s ease-in-out infinite;
}

footer .footer-home-bottom {
  position: absolute;
  left: 0%;
  bottom: 0%;
  z-index: -1;
  animation: pluse 8s ease-in-out infinite;
}

.footer-3 {
  background: #090807;
  position: relative;
  z-index: 1;
}

.footer-3 .footer__top .form__widget .subscribe__footer__title {
  font-size: 24px;
}

.footer-3 .footer__top .tj_subscrib {
  max-width: 300px;
  width: 100%;
}

.footer-3 .footer__top .input-group {
  gap: 25px;
}

.footer-3 .footer__top .input-group input[type="email"] {
  max-width: 380px;
  width: 100%;
  padding: 10px;
}

.footer-3 .footer__top form {
  width: 100%;
}

.footer-3 .form__widget {
  position: relative;
}

.footer-3 .form__widget::after {
  position: absolute;
  content: "";
  clear: both;
  width: 170%;
  height: 1px;
  background: var(--tj-grey-4);
  left: 0;
  bottom: 0;
}

.footer-3 .input-group-prepend button {
  padding: 15px 30px;
}

.footer-3 .footer_top_col_1 {
  padding: 40px 0px 40px 60px;
  display: flex;
  align-items: center;
  gap: 25px;
}

.footer-3 .footer3_col_1 {
  padding-top: 70px;
}

.footer-3 .footer3_col_2 {
  padding-top: 70px;
  padding-left: 55px;
  border-left: 1px solid var(--tj-grey-4);
  height: 100%;
}

.footer-3 .footer3_col_3 {
  padding-top: 70px;
  padding-bottom: 80px;
}

.footer-3 .footer3_col_4 {
  padding-top: 70px;
}

.footer-3 .footer-bottom {
  border-top: 1px solid var(--tj-grey-4);
}

.border-right {
  border-right: 1px solid var(--tj-border-7);
}

.border-left {
  border-left: 1px solid var(--tj-grey-4);
}

.footer-4 {
  overflow: hidden;
  padding-top: 50px;
  background: var(--tj-heading-primary);
  position: relative;
  z-index: 1;
}

.footer-4 .footer__logo_1 {
  margin-left: auto;
  margin-right: auto;
}

.footer-4 .footer4_col_1,
.footer-4 .footer4_col_2 {
  padding: 66px 50px 80px 50px;
}

.footer-4 .footer4_col_3 {
  padding-top: 66px;
  padding-left: 50px;
  padding-bottom: 60px;
}

.footer-4 .footer-bottom {
  border-top: 1px solid var(--tj-border-7);
}

.footer-4 .footer_info p {
  margin: auto;
}

.tj-link {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.tj-link a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: 42px;
  border: 1px solid var(--tj-border-5);
  font-size: 20px;
  color: var(--tj-common-white);
}

.tj-link a:hover {
  background: var(--tj-heading-secondary);
  border: 1px solid var(--tj-heading-secondary);
}

/* ----------------------------------
	27. Error CSS
------------------------------------ */
.error__wrapper {
  padding-bottom: 120px;
}

.error__wrapper .error__content span {
  font-size: 180px;
  line-height: 1;
  color: var(--tj-heading-primary);
  font-weight: 700;
}

.error__wrapper .error__content a {
  margin-top: 50px;
}

.area .nav-pills .nav-link {
  color: #fff;
  background-color: #12547D;
}

.area .nav-pills .nav-link.active {
  color: #fff;
  background-color: #93C94F;
}

.area .tab-content>.tab-pane img {
  border-radius: 5px;
  border: 1px solid #93C94F;
  padding: 3px;
}


.video-bg {
  right: 0;
  padding: 0;
  width: 50%;
  opacity: 0.8;
  height: 100%;
  position: absolute;
}

.video-bg video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.hero-wrapper .hero-content {
  z-index: 9;
  height: 100%;
  display: flex;
  padding: 100px 0;
  position: relative;
  flex-direction: column;
  justify-content: center;
}

.news-flash {
  background-color: #0A324F;
  padding: 20px 0;
  text-align: center;
  color: #fff;
  font-size: 20px;
}

.news-flash a {
  color: #93C94F;
  padding: 0 15px;
  text-decoration: underline;
}

.relative {
  position: relative;
}

.apps {
  border: 1px solid #93C94F;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
}

.apps a img {
  border-radius: 5px 5px 0 0;
  margin-bottom: 20px;
}

.apps h5 {
  color: #000;
}

.apps h5:hover {
  color: #93C94F;
}

.mission img {
  border: 1px solid #93C94F;
  padding: 5px;
}

.careers ul {
  margin-bottom: 20px;
}

.careers ul li {
  margin-left: 30px;
  list-style: disc;
  display: list-item;
  margin-bottom: 10px;
}

.agari ul {
  margin-bottom: 20px;
}

.agari ul li {
  margin-left: 30px;
  list-style: disc;
  display: list-item;
  margin-bottom: 10px;
  width: 33%;
}

.school-icon {
  background-image: url('../images/school.png');
  background-size: cover;
  width: 60px;
  height: 60px;
  border-radius: 100%;
}

.fire-icon {
  background-image: url('../images/fire.png');
  background-size: cover;
  width: 60px;
  height: 60px;
  border-radius: 100%;
}

.legend.leaflet-control small {
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
}

.legend.leaflet-control small i {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 0.25rem;
  display: inline-block;
}

.map-title {
  margin: 0;
  z-index: 9;
  display: flex;
  font-size: 2rem;
  margin: 1rem 5rem;
  position: absolute;
  align-items: center;
  box-sizing: border-box;
}

#map {
  z-index: 8;
}